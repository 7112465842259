export const ViewBills = (builder) => ({
  ViewBills: builder.mutation({
    query: ({ user_id, campaignId, startDate, endDate }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/bill',
        method: 'GET',
        params: { user_id, campaignId, startDate, endDate },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        responseHandler: (response) => response.text(), // Explicitly treat the response as text
      };
    },
  }),
});
