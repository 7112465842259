export const successRate = (builder) => ({
    successRate: builder.mutation({
        query: ({ mode }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/get_success_rate_of_summaries',
                method: 'GET',
                params: { mode },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            };
        },
    }),
});