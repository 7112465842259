export const getWhatsappTemplatesMutation = (builder) => ({
  getWhatsappTemplates: builder.mutation({
    query: ({ page = 1, limit = '', templateName = '', category = '', from = '', to ='', status='' }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/get_whatsapp_template',
        method: 'GET',
        params: { page, limit, templateName, category, from, to, status },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});