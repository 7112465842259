//by shreya saraswat
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Nav, Tab, Form, ListGroup, Button, Modal, ProgressBar, Breadcrumb } from 'react-bootstrap';
import smsPic from "../../assets/img/mobileScreens/SMS.png"
import { WhatsappTemplateComponent } from "./WhatsappComponent";
import { RCSTemplateComponent } from "./RCSComponent";
import { useCreateSMSTemplateMutation } from "Services/services";
import moment from "moment-timezone";
import { CheckCircleIcon, ClockIcon, HomeIcon, ShieldExclamationIcon } from "@heroicons/react/solid";
import { IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import SmsCalculator from "pages/SmsCalculator";
import EmojiPicker from "emoji-picker-react";
import { FaSmile } from 'react-icons/fa';

export default () => {
    // comment
    const [showModal, setShowModal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [reason, setReason] = useState('');
    const [variant, setVariant] = useState('primary');
    const history = useHistory();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiPickerRef = useRef(null);
    const [smsDetails, setSmsDetails] = useState({
        textLength: 0,
        remaining: 0,
        numberOfSMS: 1,
        encoding: 'GSM 7'
    });


    // This will return the form inputs
    const formRef = useRef(null);
    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            setProgress(0);
        }, 100);
        setReason('');
        setVariant('primary');
    };
    // This will reset the form inputs
    const handleRefresh = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
        setValidated(false);
        setShowModal(false);
        setTimeout(() => {
            setProgress(0);
        }, 100);
        history.push(Routes.Template.path);
        setReason('');
        setVariant('primary');
    };
    useEffect(() => {
        if (showModal) {
            const interval = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return Math.min(prevProgress + 5, 95);
                });
            }, 100);
        }
    }, [showModal]);

    // Toggle the emoji picker visibility
    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };

    // Append emoji to the message field in form state
    const handleEmojiClick = (emojiData) => {
        const emoji = emojiData.emoji; // Get the emoji character
        setFormValues((prevValues) => ({
            ...prevValues,
            template_content: (prevValues.template_content || '') + emoji // Append emoji to the message field
        }));
    };

    // Effect to handle clicks outside the emoji picker
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false); // Close emoji picker if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCalculation = ({ textLength, remaining, numberOfSMS, encoding }) => {
        setSmsDetails({ textLength, remaining, numberOfSMS, encoding });
    };

    const [formValues, setFormValues] = useState({});
    const [createTemp] = useCreateSMSTemplateMutation();
    const handleChange = (e) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
            language: "En"
        }));
    };
    //add variables
    const textareaRef = useRef(null);
    const [count, setCount] = useState(1);
    const AddAuthVars = () => {
        const textarea = textareaRef.current;

        const varToAdd = `{{${count}}}`;

        // Get the cursor position
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        // Get current textarea content (which could be empty)
        const currentContent = formValues.template_content || ""; // Handle empty string case

        // Insert the variable at the cursor position
        const updatedValue =
            currentContent.substring(0, startPos) +  // Everything before the cursor
            varToAdd +                              // The variable to insert
            currentContent.substring(endPos);        // Everything after the cursor

        // Update the state with the new value
        setFormValues({ ...formValues, template_content: updatedValue });

        // Maintain the cursor position after insertion
        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = startPos + varToAdd.length;
            textarea.focus();
        }, 0);

        setCount(prevCount => prevCount + 1);
    };

    //post
    const [validated, setValidated] = useState(false);
    const submitSMS = (e) => {
        // Prevent the default form submission
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setShowModal(true);
        createTemp(formValues)
            .then((success) => {
                if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
                    setReason(success?.data?.message);
                    setVariant('tertiary');
                    setProgress(100);
                } else if (success?.data?.statusCode > 300) {
                    setReason(success?.data?.message || 'Upload failed!');
                    setVariant('warning');
                    setProgress(100);
                }
            })
            .catch((error) => {
                setReason(error?.message || 'Upload failed');
                setVariant('warning');
                setShowModal(true);
                setProgress(100);
            });
    }

    //preview
    const date = moment();
    const currentTime = date.format("[Today], h:mm A");
    const highlightTemplateContent = (content) => {
        // Regex to match any {{digit}} pattern
        const parts = content.split(/(\{\{\d+\}\})/);

        return parts.map((part, index) =>
            // Check if part matches the pattern {{digit}}
            /\{\{\d+\}\}/.test(part) ? (
                <span key={index} style={{ color: 'blue' }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };




    return (
        <>
            <div className="d-flex align-items-center my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item>Templates</Breadcrumb.Item>
                        <Breadcrumb.Item active>Create Templates</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Create Templates</h4>
                </div>
            </div>

            <Tab.Container defaultActiveKey="SMS">
                <Row>
                    <Col lg={2}>
                        <Nav fill variant="pills" className="flex-lg-column vertical-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="SMS" className="mb-md-3">
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Whatsapp" className="mb-md-3">
                                    WhatsApp
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="RCS" className="mb-md-3">
                                    RCS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="SMS">
                                <Row>
                                    <Col sm={6} md={8}>
                                        <Form noValidate validated={validated} ref={formRef} onSubmit={submitSMS}>
                                            <div className="card p-3">
                                                <Row>
                                                    <Form.Group as={Col} lg={6} className="withChars mb-3">
                                                        <Form.Label>Template Name</Form.Label>
                                                        <Form.Control type="text" required placeholder="abc" maxLength={100} name="template_name" onChange={handleChange} />
                                                        <p className="chars_count">{formValues.template_name === undefined ? "0" : formValues.template_name.length}/100</p>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6} className="mb-3">
                                                        <Form.Label>Category</Form.Label>
                                                        <Form.Select name="category" onChange={handleChange}>
                                                            <option defaultValue>PROMOTIONAL</option>
                                                            <option>TRANSACTIONAL</option>
                                                            <option>OTP</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} lg={6} className="mb-3">
                                                        <Form.Label>Language</Form.Label>
                                                        <Form.Select disabled>
                                                            <option defaultValue>English</option>
                                                            <option>english</option>
                                                            <option>hindi</option>
                                                            <option>punjabi</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6} className="withChars mb-3">
                                                        <Form.Label>Sender ID</Form.Label>
                                                        <Form.Control type="text" required placeholder="abc" maxLength={11} name="header" onChange={handleChange} />
                                                        <p className="chars_count">{formValues.header === undefined ? "0" : formValues.header.length}/11</p>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} lg={6} className="withChars mb-3">
                                                        <Form.Label>PE ID</Form.Label>
                                                        <Form.Control type="text" required placeholder="abc" maxLength={19} name="pe_id" value={formValues.pe_id} onChange={handleChange} />
                                                        <p className="chars_count">{formValues.pe_id === undefined ? "0" : formValues.pe_id.length}/19</p>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6} className="withChars mb-3">
                                                        <Form.Label>Template ID</Form.Label>
                                                        <Form.Control type="text" required placeholder="abc" maxLength={19} name="template_id" onChange={handleChange} />
                                                        <p className="chars_count">{formValues.template_id === undefined ? "0" : formValues.template_id.length}/19</p>
                                                    </Form.Group>
                                                </Row>
                                                <Form.Group className="mb-3 withChars">
                                                    <div className="d-flex align-items-center">
                                                        <Form.Label>Content Body</Form.Label>
                                                        <button
                                                            type="button"
                                                            className="ms-auto emojiPicker me-2"
                                                            onClick={toggleEmojiPicker}
                                                        >
                                                            <FaSmile />
                                                        </button>

                                                        {showEmojiPicker && (
                                                            <div
                                                                ref={emojiPickerRef}
                                                                className="emojiPickerBox"
                                                            >
                                                                <EmojiPicker onEmojiClick={handleEmojiClick} />
                                                            </div>
                                                        )}
                                                        <Button onClick={AddAuthVars} bsPrefix="text" style={{ border: "none", background: "none", padding: 0, color: "inherit" }} className="d-flex align-items-center text-info fw-bold">
                                                            <IoMdAddCircle className="icon icon-xs me-2" />Add Variable
                                                        </Button>
                                                    </div>
                                                    {/* <Form.Control as="textarea" rows="15" required placeholder="Enter Message Content" name="template_content" value={formValues.template_content} onChange={handleChange} ref={textareaRef} /> */}
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="15"
                                                        required
                                                        placeholder="Enter Message Content"
                                                        name="template_content" // Ensure this matches the state field you are updating
                                                        value={formValues.template_content} // Bind textarea value to message state
                                                        onChange={handleChange} // Handle input changes
                                                        ref={textareaRef}
                                                    />
                                                    <p className="chars_count ta">{formValues.template_content === undefined ? "0" : formValues.template_content.length}</p>

                                                </Form.Group>
                                                <SmsCalculator
                                                    text={formValues.template_content || ""}
                                                    onCalculation={handleCalculation} // Pass the callback to SmsCalculator
                                                />
                                                <ListGroup horizontal className="border-0">
                                                    <ListGroup.Item variant="info">Encoding:{smsDetails.encoding}</ListGroup.Item>
                                                    <ListGroup.Item variant="info">Character Count: {smsDetails.textLength}/{smsDetails.remaining}</ListGroup.Item>
                                                    <ListGroup.Item variant="info">SMS Segments: {smsDetails.numberOfSMS}</ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <div className="d-flex mt-3">
                                                {/* <Button variant="gray-800" type="button" className="animate-up-2 me-2" >
                                                    Verify
                                                </Button> */}
                                                <Button variant="gray-800" type="submit" className="animate-up-2">
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col sm={6} md={4} className="mt-5 mt-sm-0">
                                        <div className="img-cont">
                                            <img src={smsPic} alt="sms preview" />
                                            <div className="preview-name">
                                                <span className="sender">{formValues.pe_id}</span>
                                            </div>
                                            <div className="preview-cont">
                                                <div className="time_box">
                                                    <span>SMS</span>
                                                    <span>{currentTime}</span>
                                                </div>
                                                {formValues.template_content && <div id="smsBody">
                                                    <span>{highlightTemplateContent(formValues.template_content)}</span>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>

                                    <Modal
                                        centered
                                        show={showModal}
                                        onHide={handleCloseModal}
                                        className={`modal-${variant}`}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header className="mx-auto">
                                            <p className="lead mb-0 text-white">Creating SMS Template</p>
                                        </Modal.Header>
                                        <Modal.Body className="pt-0">
                                            <div className="py-3 px-5 text-center">
                                                {progress !== 100 ?
                                                    (<span className="modal-icon display-1">
                                                        <ClockIcon className="icon icon-xl text-white" />
                                                    </span>) :
                                                    variant === 'tertiary' ?
                                                        (<span className="modal-icon display-1">
                                                            <CheckCircleIcon className="icon icon-xl text-white" />
                                                        </span>) :
                                                        <span className="modal-icon display-1">
                                                            <ShieldExclamationIcon className="icon icon-xl text-white" />
                                                        </span>
                                                }

                                                <Modal.Title className="text-white mb-3">
                                                    {progress !== 100 ? <h3>Sending Request</h3> : variant === 'tertiary' ? <h3>Template Created Successfully</h3> : <h3>Something Went Wrong</h3>}
                                                </Modal.Title>
                                                <ProgressBar variant="primary" now={progress} min={0} max={100} animated />
                                                {variant !== 'tertiary' ?
                                                    <p className="mb-4 text-danger">
                                                        {reason}
                                                    </p> : ''}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className="d-flex justify-content-center pt-0 pb-3">
                                            <Button variant="white" size="sm" className="text-primary fw-bold" onClick={handleRefresh}>
                                                OK
                                            </Button>
                                            {variant !== 'tertiary' && <Button variant="white" size="sm" className="text-primary fw-bold" onClick={handleCloseModal}>
                                                Retry
                                            </Button>}
                                        </Modal.Footer>
                                    </Modal>
                                </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Whatsapp">
                                <WhatsappTemplateComponent />
                            </Tab.Pane>

                            <Tab.Pane eventKey="RCS">
                                <RCSTemplateComponent />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};
