export const verifyListNameMutation = (builder) => ({
  verifyListName: builder.mutation({
    query: ({ list_name }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/get_list_name',
        method: 'GET',
        params: { list_name },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});