
import React, { useCallback, useEffect, useState } from "react";
import { CloudUploadIcon, CollectionIcon, FireIcon, PlusIcon, ShieldExclamationIcon, UserAddIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { v4 as uuidv4 } from "uuid";

import { CustomersWidget, RevenueWidget, UsersWidget, WeeklyReportWidget, TopAuthorsWidget, TeamMembersWidget, ProgressTrackWidget, EventsWidget, RankingWidget, VisitsMapWidget, SalesValueWidget, AcquisitionWidget, TimelineWidget, CampaignCount, CurrentBalance, BarChartSAWidget, TotalEnterpriseWidget, TotalCounts, PieChartWidget, DognutChartWidget } from "components/Widgets";
import { PageVisitsTable } from "components/Tables";
import { BarChartHorizontalWidget, LineGraphChartWidget } from "components/Widgets";
// import { trafficVolumes } from "data/charts";
import { BarChartWidget, RatingsWidget, CardWidget, ListChartWidget } from "components/Widgets";
import ApexChart from "react-apexcharts";
import { appRanking, trafficVolumes } from "data/charts";
import { useGetTemplateCountMutation, useGetAllUserSAMutation, useChannelOverviewMutation, useSuccessRateMutation } from "Services/services";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { RiExportLine } from "react-icons/ri";
import moment from "moment-timezone";
import { CalendarIcon } from "@heroicons/react/outline";
import Datetime from "react-datetime";


export default () => {

  const [trafficShares, setTrafficShares] = useState([]);
  const [getTemplateCounts] = useGetTemplateCountMutation();

  const [successRate, setSuccessRate] = useState([]);
  const [getSuccessRate] = useSuccessRateMutation();
  const [getAllUserSA] = useGetAllUserSAMutation();

  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;

  // api calls
  useEffect(() => {
    const userRole = localStorage.getItem("userType")
    const getTemplateCount = async () => {
      try {
        const response = await getTemplateCounts({ userId: userRole === 'enterprise' || userRole === 'customer relationship manager' ? userID : '' });
        if (response?.data?.statusCode === 200) {
          const data = response?.data?.data?.total || {};
          const updatedTrafficShares = [
            { id: uuidv4(), label: "SMS Templates", value: Number(data.smsTemplateData) },
            { id: uuidv4(), label: "WhatsApp Templates", value: Number(data.whatsappTemplateData) },
            { id: uuidv4(), label: "RCS Templates", value: Number(data.RCSTemplateData) },
          ];

          // Only update if data has changed
          setTrafficShares((prev) =>
            JSON.stringify(prev) !== JSON.stringify(updatedTrafficShares) ? updatedTrafficShares : prev
          );
        }
      } catch (error) {
        console.error("Failed to fetch template count:", error);
      }
    };
    getTemplateCount();
  }, []);

  // success rate graph
  useEffect(() => {
    const SuccessRate = async () => {
      try {
        const response = await getSuccessRate({ mode: '' });
        if (response?.data?.statusCode === 200) {

          const data = response?.data?.data || {};
          const updatedData = [
            { id: uuidv4(), label: "SMS", color: "#4D4AE8", values: data.smsPercentage || [] },
            { id: uuidv4(), label: "WhatsApp", color: "#06A77D", values: data.whatsappPercentage || [] },
            { id: uuidv4(), label: "RCS", color: "#FD8E7A", values: data.rcsTemplateData || [] },
          ];

          // Only update if data has changed
          setSuccessRate((prev) =>
            JSON.stringify(prev) !== JSON.stringify(updatedData) ? updatedData : prev
          );
          
          console.log('successsssssssssssss', updatedData);
        }
      } catch (error) {
        console.error("Failed to fetch template count:", error);
      }
    };
    SuccessRate();
  }, []);

  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6);

  const [start, setStart] = useState(sevenDaysAgo);
  const [end, setEnd] = useState(today);
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [waData, setWaData] = useState({});
  const [smsData, setSmsData] = useState({});

  const [pieData] = useChannelOverviewMutation();

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await pieData({
          user_id: userID,
          startDate: startDate,
          endDate: endDate,
        });
        if (response?.data?.statusCode === 200) {
          setWaData(response?.data?.data?.whatsappPercentage);
          setSmsData(response?.data?.data?.smsPercentage);
        }

        else {
          setWaData({});
          setSmsData({});
        }
      } catch (error) {
        setWaData({});
        setSmsData({});
      }
    };
    fetchTableData();
  }, [startDate, endDate]);

  const labels = ['Submitted', 'Success', 'Processing', 'Failure', 'Read'];
  // Ensure labels map to the correct data
  const seriesSMS = labels.map((label) => {
    const key = label.toLowerCase().replace(/\s+/g, ''); // Map 'Success' -> 'success'
    return parseFloat(smsData[key] || 0); // Default to 0 if key is missing
  });

  const seriesWhatsApp = labels.map((label) => {
    const key = label.toLowerCase().replace(/\s+/g, ''); // Map 'Failed' -> 'failure'
    return parseFloat(waData[key] || 0); // Default to 0 if key is missing
  });
  const seriesPieChart = [44, 55, 13, 43, 22];
  const optionsPieChart = {
    labels: labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    tooltip: {
      fillSeriesColor: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      theme: 'light',
      style: {
        fontSize: '12px',
        fontFamily: 'Inter',
      },
      y: {
        formatter: function (val) {
          return val + "%"
        }
      }
    },
  };


  return (
    <>
      <Row>
        <Col className="text-end my-1">
          <Button
            variant="gray-800" type="submit" className="mt-2 animate-up-2"
          >
            <RiExportLine style={{ fontSize: 'larger' }} className="icon icon-xs me-2" /> Export Data
          </Button>
        </Col>
      </Row>


      <Row className="justify-content-lg-center py-4">
        <Col xs={12}>
          <SalesValueWidget
            title="Sales Value"
          // value="10,567"
          // percentage={10.57}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Form.Group id="DateRange">
            <InputGroup className="d-flex flex-nowrap">
              <InputGroup.Text>
                <CalendarIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Datetime
                timeFormat={false}
                onChange={setStart}
                renderInput={(props, openCalendar) => (
                  <Form.Control
                    required
                    type="search"
                    placeholder="Start Date"
                    value={startDate}
                    onFocus={openCalendar}
                    style={{ width: "150px", borderRadius: "0" }}
                    onChange={(e) => setStart(e.target.value)} />
                )} />
              <Datetime
                timeFormat={false}
                onChange={setEnd}
                isValidDate={currDate => currDate.isAfter(start)}
                initialViewDate={end}
                renderInput={(props, openCalendar) => (
                  <Form.Control
                    required
                    type="search"
                    placeholder="End Date"
                    value={endDate}
                    onFocus={openCalendar}
                    style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                    onChange={(e) => setEnd(e.target.value)} />
                )} />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={4} className="py-4">
          {seriesSMS.every((val) => val === 0) ? (
            <p>No SMS data available</p>
          ) : (
            <ApexChart
              type="pie"
              height={360}
              title="SMS"
              series={seriesSMS}
              options={optionsPieChart}
            />
          )}
          <h3 className="fw-extrabold text-info text-end">
            SMS
          </h3>
        </Col>
        <Col xs={12} md={6} lg={4} className="py-4">
          {seriesWhatsApp.every((val) => val === 0) ? (
            <p>No Whatsapp data available</p>
          ) : (
            <ApexChart
              type="pie"
              height={360}
              title="SMS"
              series={seriesWhatsApp}
              options={optionsPieChart}
            />
          )}
          <h3 className="fw-extrabold text-success text-end">
            WhatsApp
          </h3>
        </Col>
        <Col xs={12} md={6} lg={4} className="py-4">
          <ApexChart
            type="pie"
            height={360}
            title="RCS"
            series={seriesPieChart}
            options={optionsPieChart}
          />
          <h3 className="fw-extrabold text-warning text-end">
            RCS
          </h3>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} xl={8} className="mb-4">

          {(localStorage.getItem("userType") === "super_admin" || localStorage.getItem("userType") === 'admin') &&
            <BarChartSAWidget
              title="User Counts"
              // worldRank={11}
              data={appRanking}
            />
          }

          <LineGraphChartWidget
            title="Success Rate"
            data={successRate}
          // data={trafficVolumes}
          />

          <Row>
            <Col xs={12} lg={6} className="mb-4">
              <CardWidget title="Total Contacts" />
            </Col>
            {userType === "customer relationship manager" &&
              <Col xs={12} lg={6} className="mb-4">
                <TotalEnterpriseWidget title="Total Enterprise" />
              </Col>
            }
            <Col xs={12} lg={12} xl={6} className="mb-3">
              <CampaignCount title="Campaigns Status" />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} xl={4} className="mb-4">
          <Col xs={12} md={12} className="mb-4">
            <CurrentBalance title="Current Balance" />
          </Col>
          {(userType === "super_admin" || userType === "enterprise") &&
            <Col xs={12} className="mb-4">
              <TotalCounts />
            </Col>
          }
          <BarChartHorizontalWidget
            title="Template Count Category Wise"
            data={trafficShares}
          />

        </Col>
      </Row>

    </>
  );
};
