import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { CalendarIcon, CheckIcon, KeyIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Button, InputGroup, Modal, Table, FormCheck, ToastContainer } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import CopyToClipboard from "react-copy-to-clipboard";
import { XCircleIcon } from "@heroicons/react/outline";
import { useUserOnboardingMutation, useUserTypeMutation, useCheckUserDetailsMutation, useUserUpdateFieldMutation, useUserDropDownMutation } from "Services/services";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { CiEdit } from "react-icons/ci";
import { Toast } from 'react-bootstrap';

import { UserOnboardingListTable } from "./Tables";
import { MdOutlineAutoMode } from "react-icons/md";

export const GeneralInfoForm = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [enterpriseName, setEnterpriseName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [coordinatedCrm, setCoordinatedCrm] = useState(null);
  const [coordinatedEditCrm, setCoordinatedEditCrm] = useState(null);

  const [userType, setUserType] = useState();
  const [crmValue, setCrmValue] = useState();
  const [selectUserTypeOptions, setSelectUserTypeOptions] = useState({ value: '', label: '--Select User Type--' });
  const [editDetails, setEditDetails] = useState([])
  const [selectedCrmOptions, setSelectedCrmOptions] = useState({ value: '', label: '--Select CRM--' });
  const [selectedCrmEditOptions, setSelectedCrmEditOptions] = useState([]);

  const [showEdit, setShowEdit] = useState(false)

  const [currency, setCurrency] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);

  const [copied, setCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userOnboard] = useUserOnboardingMutation();
  const [usertype] = useUserTypeMutation();
  const [crmData] = useUserDropDownMutation();
  const [userDetailUpdates] = useUserUpdateFieldMutation();
  const [verify] = useCheckUserDetailsMutation();
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);


  // Get the usertype from localStorage
  const loggedUserData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(loggedUserData);
  const loggedUserType = userObject.user_type;


  const [currencyOptions, setCurrencyOptions] = useState([]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(
          'https://openexchangerates.org/api/currencies.json',
          {
            params: {
              app_id: 'YOUR_API_KEY', // Replace with your actual API key
            },
          }
        );
        const currencies = Object.entries(response.data).map(([key, value]) => ({
          value: key,
          label: `${key} - ${value}`,
        }));
        setCurrencyOptions(currencies);
        setCurrency(currencies[66])
      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };

    const fetchUserTypeData = async () => {
      try {
        const response = await usertype();
        if (response?.data?.statusCode === 200) {
          const allOptions = response.data.data.user_type_details.map(user => ({
            value: user.id,
            label: user.user_type
          }));

          // Filter options if logged user is a customer relationship manager
          const filteredOptions = loggedUserType === 'customer relationship manager'
            ? allOptions.filter(option => option.label === 'enterprise')
            : allOptions;

          // Prepend '--Select User Type--' at the 0th index
          const optionsWithPlaceholder = [
            { value: '', label: '--Select User Type--' },
            ...filteredOptions
          ];

          setSelectUserTypeOptions(optionsWithPlaceholder);

          // Set the default userType to the placeholder
          setUserType(optionsWithPlaceholder[0]);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };



    const fetchCrmData = async () => {
      try {
        const response = await crmData({ user_type: 3 });
        if (response?.data?.statusCode === 200) {
          const options = response.data.data.user_details.map(user => ({
            value: user.id,
            label: `${user.user_name} (${user.email})`
          }));
          // Add a default "Select CRM" option at the beginning of the options array
          setSelectedCrmOptions([{ value: '', label: '--Select CRM--' }, ...options]);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };



    fetchCurrencies();
    // fetchTableData();
    fetchUserTypeData();
    fetchCrmData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const generatePassword = () => {
    const generatedPassword = Math.random().toString(36).slice(-8); // Simple password generation
    setPassword(generatedPassword);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const handleClose = () => {
    setShowDefault(false);
    setShowEditDefault(false);
    setShowEdit(false);
  }

  const handleCloseBack = () => {
    setShowEditDefault(false);
    setShowEdit(true);

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate userType selection
    if (!userType || userType.value === '') {
      setValidationError(true);
      return; // Stop form submission if validation fails
    }

    setShowDefault(true);
    // Continue with form submission logic
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    setShowEdit(false);
    setShowEditDefault(true);

  }

  const userData = {
    user_name: enterpriseName,
    name: name,
    phone: phone,
    email: email,
    password: password,
    expiry_date: birthday,
    customer_relation_manager: coordinatedCrm?.value,
    user_type: userType?.value,
    user_currency: currency?.value
  }

  const submitForm = (e) => {
    e.preventDefault();
    userOnboard(userData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setShowDefault(false);
          setToastMessage(success?.data?.message);
          setToastVariant('success');
          setRefreshTable(!refreshTable);
          setEnterpriseName('')
          setName('')
          setPhone('+91')
          setEmail('')
          setPassword('')
          setBirthday('')
          setCoordinatedCrm(null)
          setisVerifiedUsername('')
          setisVerifiedPhone('')
          setisVerifiedEmail('')
        } else if (success?.data?.statusCode > 300) {
          console.error('Login failed passed');
          setShowDefault(false);
          setToastMessage('Upload failed!');
          setToastVariant('danger');
          setRefreshTable(!refreshTable);
        }
        setShowToast(true);
      })
      .catch((error) => {
        setShowDefault(false);
        setRefreshTable(!refreshTable);
        console.error('Login failed', error);
        setToastMessage('Upload failed!');
        setToastVariant('danger');
        setShowToast(true);
      });
  }
  const [isPasswordEdited, setIsPasswordEdited] = useState(false);

  // Update this when the password input is edited
  const handlePasswordChange = (e) => {
    setEditDetails({ ...editDetails, password: e.target.value });
    setIsPasswordEdited(true); // Set to true when user starts editing the password
  };

  const userEditData = {
    name: editDetails.name,
    phone: editDetails.phone,
    email: editDetails.email,
    expiry_date: editDetails.expiry_date,
    customer_relation_manager: coordinatedEditCrm === null ? selectedCrmEditOptions[0]?.value : coordinatedEditCrm?.value,
    user_type: editDetails.user_type,
    user_currency: editDetails.user_currency,
    id: editDetails.id,
    ...(isPasswordEdited ? { password: editDetails.password } : {}), // Only include password if it was edited
  };


  const [passwordError, setPasswordError] = useState("");

  const submitEditForm = (e) => {


    e.preventDefault();

    // Password validation: Add your custom validation logic here
    if (editDetails.password === "" || editDetails?.password?.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return;  // Prevent form submission if the validation fails
    }

    // Clear any previous error if password is valid
    setPasswordError("");

    // Continue with API call if password validation passes
    userDetailUpdates(userEditData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setShowDefault(false);
          setShowEdit(false);
          setShowEditDefault(false);
          setEditDetails([])
          setRefreshTable(!refreshTable);
          setPhone('');
        } else if (success?.data?.statusCode > 300) {
          setShowDefault(false);
          setShowEdit(false);
          setShowEditDefault(false);
          setEditDetails([])
          setRefreshTable(!refreshTable);
          setPhone('');
        }
      })
      .catch((error) => {
        setShowDefault(false);
        setShowEdit(false);
        setShowEditDefault(false);
        setEditDetails([])
        setRefreshTable(!refreshTable);
        console.error('Error updating user details', error);
      });
  };


  const selectUserType = (selectedOption) => {
    setUserType(selectedOption);
    setCrmValue(selectedOption.value)
  };

  const [isVerifiedUsername, setisVerifiedUsername] = useState();
  const [isVerifiedPhone, setisVerifiedPhone] = useState();
  const [isVerifiedEmail, setisVerifiedEmail] = useState();

  const verifyUsername = (e) => {
    const username = e.target.value;
    setEnterpriseName(username);

    const regex = /^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9_]+$/;

    if (username.length >= 3 && username.length <= 12 && regex.test(username)) {
      const postData = { data: username };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedUsername(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedUsername(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedUsername(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedUsername();
    }
  };
  const verifyEmail = (e) => {
    const email = e.target.value;
    setEmail(email);

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    if (regex.test(email)) {
      const postData = { data: email };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedEmail(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedEmail(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedEmail(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedEmail();
    }
  };

  const verifyPhone = (phone) => {
    setPhone(phone)
    const regex = /^(\+|00)?\d{1,3}[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (phone.length >= 6 && phone.length <= 13 && regex.test(phone)) {
      const postData = { data: phone };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedPhone(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedPhone(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedPhone(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedPhone();
    }
  }

  const handleEdit = async (userDetails) => {


    try {
      const response = await crmData({ user_type: 3 });
      if (response?.data?.statusCode === 200) {
        const options = response.data.data.user_details.map(user => ({
          value: user.id,
          label: `${user.user_name} (${user.email})`
        }));

        setSelectedCrmEditOptions([
          { value: userDetails.customer_relation_manager, label: userDetails.customer_relation_manager_name },
          ...options
        ]);
      } else {
        console.error('Failed to fetch user data', response.data);
      }
    } catch (error) {
      console.error('Error fetching user data', error);
    } finally {
    }

    setEditDetails(userDetails);
    setShowEdit(true);
  };

  const [validationError, setValidationError] = useState(false);

  const handleUserTypeChange = (selectedOption) => {
    setUserType(selectedOption);
    setValidationError(false); // Clear error on valid selection
    selectUserType(selectedOption);
  };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <h5 className="mb-4">General information</h5>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <input type="text" name="preventAutoFill" style={{ display: 'none' }} />
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="userType">
                  <Form.Label>User Type</Form.Label>
                  <Select
                    options={selectUserTypeOptions}
                    placeholder="--Select User Type--"
                    className={`mb-0 ${validationError ? 'is-invalid' : ''}`} // Add a red border on error
                    value={userType || selectUserTypeOptions[0]}
                    onChange={handleUserTypeChange}
                  />
                  {validationError && (
                    <div className="invalid-feedback">
                      Please select a valid user type.
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="new-name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="username">
                  <Form.Label>Username</Form.Label>
                  <InputGroup>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your username"
                      value={enterpriseName}
                      onChange={verifyUsername}
                      pattern="^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9_]+$"
                      title="Username must be alphanumeric with at least 2 letters, special character '_' only, and no spaces."
                      maxLength={12}
                      minLength={3}
                      autoComplete="new-username"
                    />
                    {isVerifiedUsername && (
                      <InputGroup.Text>
                        <CheckIcon width={20} className="text-success" />
                      </InputGroup.Text>
                    )}
                    {isVerifiedUsername === false && (
                      <InputGroup.Text>
                        <XCircleIcon width={20} className="text-danger" />
                      </InputGroup.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid username. It must contain at least 2 letters, only alphanumeric characters or '_', and no spaces.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Phone</Form.Label>
                  <InputGroup className="phone-input-group">
                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={verifyPhone}
                      placeholder="+91 99999-99999"
                      className="phone-input"
                      autoComplete="new-phone"
                    />
                    {isVerifiedPhone && (
                      <InputGroup.Text className="phone-input-icon">
                        <CheckIcon width={20} className="text-success" />
                      </InputGroup.Text>
                    )}
                    {isVerifiedPhone === false && (
                      <InputGroup.Text className="phone-input-icon">
                        <XCircleIcon width={20} className="text-danger" />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number in the format '+91 XXXXX-XXXXX'.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col md={4} className="mb-3">
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <MailIcon width={20} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@company.com"
                      value={email}
                      onChange={verifyEmail}
                      autoComplete="new-email"
                    />
                    {isVerifiedEmail && (
                      <InputGroup.Text>
                        <CheckIcon width={20} className="text-success" />
                      </InputGroup.Text>
                    )}
                    {isVerifiedEmail === false && (
                      <InputGroup.Text>
                        <XCircleIcon width={20} className="text-danger" />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <KeyIcon width={20} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      maxLength={8}
                      autoComplete="new-password"
                    />
                    <InputGroup.Text>
                      <MdOutlineAutoMode
                        size={20}
                        onClick={generatePassword}
                        style={{ cursor: 'pointer' }}
                        title="Auto-generate password"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group id="birthday">
                  <Form.Label>Expiry Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setBirthday}
                    isValidDate={currentDate => currentDate.isAfter(moment(), 'day')}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <CalendarIcon className="icon icon-xs" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={birthday ? moment(birthday).format("DD/MM/YYYY") : ""}
                          placeholder="dd/mm/yyyy"
                          onFocus={openCalendar}
                          onChange={() => { }}
                          autoComplete="new-birthday"
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {(loggedUserType !== "customer relationship manager" && crmValue === "4") && (
                <Col md={6} className="mb-3">
                  <Form.Group id="gender">
                    <Form.Label>Coordinated CRM</Form.Label>
                    <Select
                      options={selectedCrmOptions}
                      placeholder="--Select CRM--"
                      className="mb-0"
                      isSearchable={true}
                      value={coordinatedCrm || selectedCrmOptions[0]}
                      onChange={setCoordinatedCrm}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col md={6} className="mb-3">
                <Form.Group id="currency">
                  <Form.Label>Currency</Form.Label>
                  <Select
                    options={currencyOptions}
                    placeholder="--Select Currency--"
                    className="mb-0"
                    isSearchable={true}
                    value={currency || currencyOptions[66]}
                    onChange={(currencyOptions) => setCurrency(currencyOptions)}
                    autoComplete="new-currency"
                  />
                </Form.Group>
              </Col>
            </Row>



            <div className="mt-3">
              <Button variant="primary" className="my-3" type="submit">Preview</Button>
              <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title className="h6">User Detail Preview</Modal.Title>
                  <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                  <Card className="mb-2 mt-2">
                    <Row>
                      <Col md={10}>
                        <Table responsive className="table-centered table-nowrap rounded mb-0">
                          <tbody>
                            <tr>
                              <td className="border-0">Name</td>
                              <td className="border-0">{name}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Username</td>
                              <td className="border-0">{enterpriseName}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Phone Number</td>
                              <td className="border-0">{phone}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Email</td>
                              <td className="border-0">{email}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Password</td>
                              <td className="border-0">{password}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Expiry Date</td>
                              <td className="border-0">{birthday ? moment(birthday).format("DD/MM/YYYY") : ""}</td>
                            </tr>

                            {(loggedUserType !== "customer relationship manager" && crmValue === "4") && <tr>
                              <td className="border-0">Coordinated CRM</td>
                              <td className="border-0">{coordinatedCrm ? coordinatedCrm.label : ""}</td>
                            </tr>}

                            <tr>
                              <td className="border-0">User Type</td>
                              <td className="border-0">{userType?.label}</td>
                            </tr>
                            <tr>
                              <td className="border-0">User Currency</td>
                              <td className="border-0">{currency ? currency.label : ""}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col md={2} className="d-flex align-items-start">
                        <Card.Body className="d-flex flex-column">
                          <div>
                            {copied ? (
                              <Button size="sm" variant="success" className="copy-code-button">
                                Copied
                              </Button>
                            ) : (
                              <CopyToClipboard
                                text={`Name: ${name}\nUsername: ${enterpriseName}\nPhone Number: ${phone}\nEmail: ${email}\nPassword: ${password}\nExpiry Date: ${birthday ? moment(birthday).format("DD/MM/YYYY") : ""}\n${crmValue === '2' ? `Coordinated CRM: ${coordinatedCrm ? coordinatedCrm.label : ""}\n` : ""}User Type: ${userType ? userType.label : ""}\nUser Currency: ${currency ? currency.label : ""}`}
                                onCopy={handleCopy}
                              >
                                <Button size="sm" variant="primary" className="copy-code-button">
                                  Copy
                                </Button>
                              </CopyToClipboard>
                            )}
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>

                  <Card className="mb-2 mt-3">
                    <Row>
                      <Col md={12}>
                        <Card.Body>
                          <b>Share Details:</b>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="emailSwitch"
                              checked={selectedOption === 'email'}
                              onChange={() => setSelectedOption('email')}
                            />
                            <FormCheck.Label htmlFor="emailSwitch" style={{ marginLeft: '8px' }}>
                              Email ID
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="phoneSwitch"
                              checked={selectedOption === 'phone'}
                              onChange={() => setSelectedOption('phone')}
                            />
                            <FormCheck.Label htmlFor="phoneSwitch" style={{ marginLeft: '8px' }}>
                              Phone Number
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="bothSwitch"
                              checked={selectedOption === 'both'}
                              onChange={() => setSelectedOption('both')}
                            />
                            <FormCheck.Label htmlFor="bothSwitch" style={{ marginLeft: '8px' }}>
                              Both
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="noneSwitch"
                              checked={selectedOption === ''}
                              onChange={() => setSelectedOption('')}
                            />
                            <FormCheck.Label htmlFor="noneSwitch" style={{ marginLeft: '8px' }}>
                              None
                            </FormCheck.Label>
                          </FormCheck>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={submitForm}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>


              <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showEdit} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title className="h6">Edit User Details</Modal.Title>
                  <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                  <Card className="mb-2 mt-2">
                    <Row>
                      <Col md={12}>
                        <Table responsive className="table-centered table-nowrap rounded mb-0 ">
                          <tbody>
                            <tr>
                              <td className="border-0">Name</td>
                              <td className="border-0">
                                <input
                                  type="text"
                                  value={editDetails.name || ""}
                                  onChange={(e) => setEditDetails({ ...editDetails, name: e.target.value })}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Phone Number</td>
                              <td className="border-0">
                                <div className="input-group phone-input-group">
                                  <PhoneInput
                                    country="IN"
                                    value={editDetails.phone || ""}
                                    onChange={(value) => {
                                      if (!value) {
                                        // If the input is cleared, reset the phone number
                                        setEditDetails({ ...editDetails, phone: "" });
                                        return;
                                      }

                                      // Ensure the phone number starts with +91
                                      const formattedPhone = value.startsWith("+91")
                                        ? value
                                        : `${value.replace(/^\+91\s*/, "")}`;

                                      verifyPhone(formattedPhone);
                                      setEditDetails({ ...editDetails, phone: formattedPhone });
                                    }}
                                    placeholder="+91 99999-99999"
                                    style={{ width: "100%" }}
                                  />

                                  {isVerifiedPhone === true &&
                                    <InputGroup.Text className="phone-input-icon">
                                      <CheckIcon width={20} className="text-success" />
                                    </InputGroup.Text>
                                  }
                                  {isVerifiedPhone === false &&
                                    <InputGroup.Text className="phone-input-icon">
                                      <XCircleIcon width={20} className="text-danger " />
                                    </InputGroup.Text>
                                  }
                                </div>
                                <div className="invalid-feedback">
                                  Please enter a valid phone number in the format '+91 XXXXX-XXXXX'.
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="border-0">Email</td>
                              <td className="border-0">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <MailIcon width={20} />
                                  </span>
                                  <input
                                    type="email"
                                    value={editDetails.email || ""}
                                    onChange={(e) => {
                                      verifyEmail(e);
                                      setEditDetails({ ...editDetails, email: e.target.value });
                                    }}
                                    placeholder="name@company.com"
                                    className="form-control"
                                    required
                                  />
                                  {isVerifiedEmail &&
                                    <span className="input-group-text">
                                      <CheckIcon width={20} className="text-success" />
                                    </span>
                                  }
                                  {isVerifiedEmail === false &&
                                    <span className="input-group-text">
                                      <XCircleIcon width={20} className="text-danger" />
                                    </span>
                                  }
                                </div>
                                <div className="invalid-feedback">
                                  Please enter a valid email address.
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Password</td>
                              <td className="border-0">
                                <div className="input-group">
                                  {isEditPassword ? (
                                    <>
                                      <span className="input-group-text">
                                        <KeyIcon width={20} />
                                      </span>
                                      <input
                                        required
                                        type="password"
                                        maxLength={8}
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        value={editDetails.password || password}
                                        onChange={handlePasswordChange} // Use the new handler here
                                        className={`form-control ${passwordError ? "is-invalid" : ""}`}
                                      />
                                      <span
                                        className="input-group-text"
                                        onClick={generatePassword}
                                        style={{ cursor: 'pointer' }}
                                        title="Auto-generate password"
                                      >
                                        <MdOutlineAutoMode size={20} />
                                      </span>
                                      {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                    </>
                                  ) : (
                                    <Button variant="light" onClick={() => setIsEditingPassword(!isEditingPassword)}>
                                      <CiEdit size={25} />
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>



                            <tr>
                              <td className="border-0">Expiry Date</td>
                              <td className="border-0">
                                <input
                                  type="date"
                                  value={editDetails.expiry_date ? moment(editDetails.expiry_date, "YYYY/MM/DD").format("YYYY-MM-DD") : ""}
                                  onChange={(e) => {
                                    const newDate = e.target.value;
                                    // Convert from YYYY-MM-DD to YYYY/MM/DD if that's your preferred format
                                    const formattedDate = moment(newDate).format("YYYY/MM/DD");
                                    setEditDetails({ ...editDetails, expiry_date: formattedDate });
                                  }}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">User Currency</td>
                              <td className="border-0">
                                <div className="form-group mb-0">
                                  <Select
                                    options={currencyOptions} // Provide your options here
                                    placeholder="--Select Currency--"
                                    className="mb-0"
                                    isSearchable={true}
                                    value={currencyOptions.find(option => option.value === editDetails.user_currency)}
                                    onChange={(selectedOption) =>
                                      setEditDetails({
                                        ...editDetails,
                                        user_currency: selectedOption ? selectedOption.value : "",
                                      })
                                    }
                                    // Ensure the dropdown appears over the card
                                    menuPortalTarget={document.body} // Attach the dropdown to the body
                                    styles={{
                                      menuPortal: base => ({ ...base, zIndex: 9999 }) // High z-index
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>

                            {
                              editDetails.user_type_id === 4 && (
                                <tr>
                                  <td className="border-0">CRM Manager</td>
                                  <td className="border-0">
                                    <div className="form-group mb-0">
                                      <Select
                                        options={selectedCrmEditOptions}
                                        placeholder="--Select CRM Manager--"
                                        className="mb-0"
                                        isSearchable={true}
                                        value={coordinatedEditCrm || selectedCrmEditOptions[0]}
                                        onChange={setCoordinatedEditCrm}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: base => ({ ...base, zIndex: 9999 })
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )
                            }

                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" /* onClick={submitEditForm} */ onClick={handleEditSubmit}>
                    Preview
                  </Button>
                </Modal.Footer>
              </Modal>


              <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={isEditingPassword && !isEditPassword} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title className="h6">Edit Password Confirmation</Modal.Title>
                  <Button variant="close" aria-label="Close" onClick={() => setIsEditingPassword(!isEditingPassword)} />
                </Modal.Header>
                <Modal.Body>
                  <p>Once confirmed, you will be required to update your password.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => {
                    setIsEditPassword(!isEditPassword);
                    setEditDetails((prevDetails) => ({
                      ...prevDetails,
                      password: ""
                    }));
                  }}>
                    Confirm
                  </Button>
                  <Button variant="link" className="text-gray ms-auto" onClick={() => setIsEditingPassword(!isEditingPassword)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showEditDefault} onHide={handleCloseBack}>
                <Modal.Header>
                  <Modal.Title className="h6">Editted User Detail Preview</Modal.Title>
                  <Button variant="close" aria-label="Close" onClick={handleCloseBack} />
                </Modal.Header>
                <Modal.Body>
                  <Card className="mb-2 mt-2">
                    <Row>
                      <Col md={10}>
                        <Table responsive className="table-centered table-nowrap rounded mb-0">
                          <tbody>
                            <tr>
                              <td className="border-0">Name</td>
                              <td className="border-0">{editDetails.name}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Phone Number</td>
                              <td className="border-0">{editDetails.phone}</td>
                            </tr>
                            <tr>
                              <td className="border-0">Email</td>
                              <td className="border-0">{editDetails.email}</td>
                            </tr>
                            {(editDetails.password !== undefined) && (
                              <tr>
                                <td className="border-0">Password</td>
                                <td className="border-0">{editDetails.password}</td>
                              </tr>
                            )

                            }
                            <tr>
                              <td className="border-0">Expiry Date</td>
                              <td className="border-0">{editDetails.expiry_date ? moment(editDetails.expiry_date).format("DD/MM/YYYY") : ""}</td>
                            </tr>
                            {editDetails.user_type_id === 4 &&
                              (<tr>
                                <td className="border-0">Coordinated CRM</td>
                                <td className="border-0">{coordinatedEditCrm?.label ? coordinatedEditCrm?.label : selectedCrmEditOptions[0].label}</td>
                              </tr>
                              )
                            }

                            <tr>
                              <td className="border-0">User Currency</td>
                              <td className="border-0">{editDetails?.user_currency?.label || editDetails.user_currency}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col md={2} className="d-flex align-items-start">
                        <Card.Body className="d-flex flex-column">
                          <div>
                            {copied ? (
                              <Button size="sm" variant="success" className="copy-code-button">
                                Copied
                              </Button>
                            ) : (
                              <CopyToClipboard
                                text={`Name: ${name}\nUsername: ${enterpriseName}\nPhone Number: ${phone}\nEmail: ${email}\nPassword: ${password}\nExpiry Date: ${birthday ? moment(birthday).format("DD/MM/YYYY") : ""}\n${crmValue === '2' ? `Coordinated CRM: ${coordinatedCrm ? coordinatedCrm.label : ""}\n` : ""}User Type: ${userType ? userType.label : ""}\nUser Currency: ${currency ? currency.label : ""}`}
                                onCopy={handleCopy}
                              >
                                <Button size="sm" variant="primary" className="copy-code-button">
                                  Copy
                                </Button>
                              </CopyToClipboard>
                            )}
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>

                  <Card className="mb-2 mt-3">
                    <Row>
                      <Col md={12}>
                        <Card.Body>
                          <b>Share Details:</b>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="emailSwitch"
                              checked={selectedOption === 'email'}
                              onChange={() => setSelectedOption('email')}
                            />
                            <FormCheck.Label htmlFor="emailSwitch" style={{ marginLeft: '8px' }}>
                              Email ID
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="phoneSwitch"
                              checked={selectedOption === 'phone'}
                              onChange={() => setSelectedOption('phone')}
                            />
                            <FormCheck.Label htmlFor="phoneSwitch" style={{ marginLeft: '8px' }}>
                              Phone Number
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="bothSwitch"
                              checked={selectedOption === 'both'}
                              onChange={() => setSelectedOption('both')}
                            />
                            <FormCheck.Label htmlFor="bothSwitch" style={{ marginLeft: '8px' }}>
                              Both
                            </FormCheck.Label>
                          </FormCheck>
                          <FormCheck type="switch" className="mt-2">
                            <FormCheck.Input
                              type="checkbox"
                              id="noneSwitch"
                              checked={selectedOption === ''}
                              onChange={() => setSelectedOption('')}
                            />
                            <FormCheck.Label htmlFor="noneSwitch" style={{ marginLeft: '8px' }}>
                              None
                            </FormCheck.Label>
                          </FormCheck>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={(e) => submitEditForm(e)}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>


            </div>
          </Form>
        </Card.Body>
      </Card>
      <UserOnboardingListTable key={refreshTable} handleEdit={handleEdit} />
    </>
  );
};
