//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Tab, Breadcrumb, Card, Form, Button } from 'react-bootstrap';
import { HomeIcon } from "@heroicons/react/solid";
// import { Link } from 'react-router-dom';
// import { IoMdBackspace } from "react-icons/io";
// import { Routes } from "routes";
import { BulkComponent } from "./createBulkCamp";

export default () => {
    return (
        <>
            <div className="d-flex">
                <div className="d-block mt-3">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item>Campaigns</Breadcrumb.Item>
                        <Breadcrumb.Item active>Create Campaign</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Create Campaign</h4>
                    {/* <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p> */}
                </div>
                {/* <Card.Link as={Link} to={Routes.Campaign.path} className="fw-bold ms-auto text-dark hover:underline d-inline-flex align-items-center mb-2">
                    <IoMdBackspace className="icon icon-xs me-2" /> Back to Campaigns
                </Card.Link> */}
            </div>

            <Row className="mt-3">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey="api-push">
                        <BulkComponent />
                    </Tab.Container>
                </Col>
            </Row>
        </>
    )
}