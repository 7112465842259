//by shreya saraswat
import React, { useRef, useState } from "react";
import { Col, Row, Button, Nav, Tab, Form, InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import { DropFilesForm } from "components/Forms";
import { ContactListTable } from "components/Tables";
import { useContactUploadListMutation, useVerifyListNameMutation } from "Services/services";
import { APIComponent } from "./APItab";
import { CheckIcon, XCircleIcon } from "@heroicons/react/solid";


export default () => {
    //usertype
    const userData = localStorage.getItem('TOKEN');
    const userObject = JSON.parse(userData);
    const userID = userObject.id;
    const userName = userObject.user_name;


    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const [refreshTable, setRefreshTable] = useState(false);
    const [refreshFileField, setRefreshFileField] = useState(false);
    const [isVerifiedListName, setisVerifiedListName] = useState();
    const [verify] = useVerifyListNameMutation();
    const [contactForm, setContactForm] = useState({
        list_name: '',
        remark: '',
        file: null,
    })
    const handleContactForm = (e) => {
        const { name, value, files } = e.target;
        console.log('e.target', e.target);
        if (name === 'file') {
            setContactForm({
                ...contactForm,
                file: files[0],
            });
        } else {
            setContactForm({
                ...contactForm,
                [name]: value,
            });

            const regex = /^[a-zA-Z0-9_-]+$/;
            if (regex.test(e.target.value) && e.target.value !== '') {
                verify({ list_name: e.target.value })
                    .then((success) => {
                        if (success?.data?.statusCode === 200) {
                            setisVerifiedListName(true);
                        } else if (success?.data?.statusCode > 300) {
                            setisVerifiedListName(false);
                            console.error("Verification failed");
                        }
                    })
                    .catch((error) => {
                        setisVerifiedListName(false);
                        console.error("Verification failed", error);
                    });
            } else if (!e.target.value || e.target.value === '') {
                setisVerifiedListName(false);
            } else {
                setisVerifiedListName(false);

            }
        }
    }


    const [uploadList] = useContactUploadListMutation();
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('list_name', contactForm.list_name);
        formData.append('remark', contactForm.remark);
        formData.append('file', contactForm.file);

        formData.append('user_id', userID);
        formData.append('user_name', userName);

        uploadList(formData)
            .then((success) => {
                if (success?.data?.statusCode === 200) {
                    setToastMessage(success?.data?.message);
                    setToastVariant('success');
                    setRefreshTable(!refreshTable);
                    setRefreshFileField(!refreshFileField);
                    setContactForm({
                        list_name: '',
                        remark: '',
                        file: null,
                    });
                } else if (success?.data?.statusCode > 300) {
                    setToastMessage(success?.data?.message || 'Upload failed!');
                    setToastVariant('danger');
                }
                setShowToast(true);
            })
            .catch((error) => {
                setToastMessage(error?.data?.message || 'Upload failed');
                setToastVariant('danger');
                setShowToast(true);
            });
    }


    return (
        <>
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Contact List Upload</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Row className="mt-5">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey="upload">
                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                            <Nav.Item>
                                <Nav.Link eventKey="upload">
                                    Upload Contacts
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="api-sync">
                                    API Sync
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="db-sync">
                                    Database Sync
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="mt-0 mt-lg-3">
                            <Tab.Pane eventKey="upload" className="card border-0 p-4">
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>List Name</Form.Label>
                                                <InputGroup>
                                                    <Form.Control required type="text" placeholder="Name" maxLength={20} name="list_name" onChange={handleContactForm} value={contactForm.list_name} autoComplete="list-name" />
                                                    {isVerifiedListName && (
                                                        <InputGroup.Text>
                                                            <CheckIcon width={20} className="text-success" />
                                                        </InputGroup.Text>
                                                    )}
                                                    {isVerifiedListName === false && (
                                                        <InputGroup.Text>
                                                            <XCircleIcon width={20} className="text-danger" />
                                                        </InputGroup.Text>
                                                    )}
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Remarks</Form.Label>
                                                <InputGroup>
                                                    <Form.Control type="text" placeholder="for example users" maxLength={50} name="remark" onChange={handleContactForm} value={contactForm.remark} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
                                                Submit
                                            </Button>
                                        </Col>
                                        <Col xs={12} md={6} className="p-0 px-sm-5">
                                            <a href={`${process.env.REACT_APP_API_PORT}/download_contact_list_sample`}><u>&#42;Click here to <b>download</b> Sample File.</u></a>
                                            <DropFilesForm key={refreshFileField} onFilesUploaded={handleContactForm} />
                                            <p className="m-0"><b>Note:</b> Include only headers which are required in your template.</p>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey="upload" className="card border-0 mt-3">
                                <ContactListTable key={refreshTable} listType="addressbook" />
                            </Tab.Pane>

                            <Tab.Pane eventKey="api-sync" className="card border-0 p-4">
                                <APIComponent />
                            </Tab.Pane>
                            <Tab.Pane eventKey="api-sync" className="card border-0 mt-3">
                                <ContactListTable listType="api" />
                            </Tab.Pane>

                            <Tab.Pane eventKey="db-sync" className="card border-0 p-4">
                                <p> COMING SOOON !  </p>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </>
    );
};
