import React, { useState } from "react";
import { HomeIcon, PlusIcon } from "@heroicons/react/solid";
import { Breadcrumb, Card, Modal, Form, Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaSms, FaWhatsapp } from "react-icons/fa";
import { MdRMobiledata } from "react-icons/md";
import { TbEdit, TbTrash } from "react-icons/tb";
import { IoPauseCircleOutline, IoPlay } from "react-icons/io5";
import { HiOutlineStatusOnline } from "react-icons/hi";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [channelOptions] = useState([
    { value: "SMS", label: "SMS", icon: <FaSms /> },
    { value: "WhatsApp", label: "WhatsApp", icon: <FaWhatsapp /> },
    { value: "RCS", label: "RCS", icon: <MdRMobiledata /> },
  ]);
  const [channel, setChannel] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cards, setCards] = useState([]);
  const [errors, setErrors] = useState({});
  const [editingCardId, setEditingCardId] = useState(null); // Track card being edited
  const [cardStates, setCardStates] = useState({});
  const [isOnline, setIsOnline] = useState(true);

  const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  }));

  const handleAddChatbot = () => {
    setShowModal(true);
    setEditingCardId(null); // Reset editing card state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    let formErrors = {};

    if (!channel) {
      formErrors.channel = "Channel is required";
    }

    if (!name) {
      formErrors.name = "Name is required";
    }

    if (!description) {
      formErrors.description = "Description is required";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Prevent form submission if validation fails
    }

    const apiCallSuccess = true; // Simulated response

    if (apiCallSuccess) {
      if (editingCardId !== null) {
        // If editing an existing card, update it
        const updatedCards = cards.map((card) =>
          card.id === editingCardId ? { ...card, channel: channel?.label, name, description } : card
        );
        setCards(updatedCards);
      } else {
        // If adding a new card
        const newCard = {
          id: cards.length + 1,
          channel: channel?.label,
          name,
          description,
        };
        setCards([...cards, newCard]);
      }
      setShowModal(false);
      setChannel(null);
      setName("");
      setDescription("");
      setErrors({});
      setEditingCardId(null); // Reset editing state

      SwalWithBootstrapButtons.fire(
        "Success",
        editingCardId ? "Chatbot updated successfully!" : "Chatbot added successfully!",
        "success"
      );
    } else {
      SwalWithBootstrapButtons.fire(
        "Error",
        "Failed to save chatbot. Please try again.",
        "error"
      );
    }
  };

  const handleDelete = (id) => {
    setCards(cards.filter((card) => card.id !== id));
    SwalWithBootstrapButtons.fire(
      "Deleted",
      "Chatbot deleted successfully!",
      "success"
    );
  };

  const handlePlay = (id) => {
    setCardStates((prev) => ({ ...prev, [id]: "playing" }));
  };

  const handlePause = (id) => {
    setCardStates((prev) => ({ ...prev, [id]: "paused" }));
  };

  const handleEdit = (id) => {
    const cardToEdit = cards.find((card) => card.id === id);
    setChannel(channelOptions.find((option) => option.label === cardToEdit.channel));
    setName(cardToEdit.name);
    setDescription(cardToEdit.description);
    setEditingCardId(id); // Set card id being edited
    setShowModal(true);
  };

  // Clear error when user starts typing or selects an option
  const handleInputChange = (e, field) => {
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
    if (field === "name") {
      setName(e.target.value);
    } else if (field === "description") {
      setDescription(e.target.value);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setErrors((prevErrors) => ({ ...prevErrors, channel: "" }));
      setChannel(selectedOption);
    }
  };

  const getChannelIcon = (channelName) => {
    switch (channelName) {
      case "SMS":
        return <FaSms style={{ color: "blue" }} />;
      case "WhatsApp":
        return <FaWhatsapp style={{ color: "#25D366" }} />;
      case "RCS":
        return (
          <div style={{ color: "orange", fontWeight: "bold", fontSize: "20px" }}>
            RCS
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* Breadcrumb and Heading */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>MAT</Breadcrumb.Item>
            <Breadcrumb.Item active>ChatBot</Breadcrumb.Item>
          </Breadcrumb>
          <h4>ChatBot</h4>
        </div>
      </div>

      {/* Create Chatbot Button and Cards */}
      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
        {/* Add Chatbot Button */}
        <button
          className="d-flex align-items-center justify-content-center text-white rounded-lg shadow"
          style={{
            width: "150px",
            height: "150px",
            backgroundColor: "#09090B",
            fontSize: "14px",
          }}
          onClick={handleAddChatbot}
        >
          <PlusIcon className="me-2" style={{ width: "20px", height: "20px" }} />
          Add Chatbot
        </button>

        {/* Chatbot Cards */}
        <div className="d-flex flex-wrap gap-3">
          {cards.map((card) => (
            <Card
              key={card.id}
              className="shadow"
              style={{
                width: "200px",
                height: "150px",
                position: "relative",
                padding: "10px",
                textAlign: "left",
              }}
            >
              {/* Channel Logo and Name */}
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "30px"
                }}
              >
                {getChannelIcon(card.channel)}
              </div>

              {/* Card Content */}
              <Card.Body
                className="d-flex flex-column justify-content-between h-100"
                style={{ padding: 0 }}
              >
                {/* Name and Line */}
                <div>
                  <Card.Title style={{ fontSize: "12px", fontWeight: "700", marginTop:"6px" }}>
                    {card.name.toUpperCase()}
                  </Card.Title>
                  <div
                    style={{
                      borderBottom: "1px solid #000", // Line below the Name
                      marginBottom: "3px",
                      marginTop: "10px"
                    }}
                  ></div>
                  {/* Description Label */}
                  <div>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#333",
                        marginBottom: "0px",
                      }}
                    >
                      Description:
                    </label>
                    <Card.Text style={{ fontSize: "12px", marginTop: "2px", marginBottom: "0px" }}>
                      {card.description}
                    </Card.Text>
                  </div>
                </div>



                {/* Edit, Delete, Play, and Pause Buttons */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Edit Button */}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Edit</Tooltip>}
                  >
                    <Card.Link onClick={() => handleEdit(card.id)}>
                      <TbEdit className="icon icon-wide text-info" />
                    </Card.Link>
                  </OverlayTrigger>

                  {/* Delete Button */}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Delete</Tooltip>}
                  >
                    <Card.Link onClick={() => handleDelete(card.id)}>
                      <TbTrash className="icon icon-wide text-danger" />
                    </Card.Link>
                  </OverlayTrigger>

                  {/* Play or Pause Button */}
                  {cardStates[card.id] === "paused" ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="m-0">Play</Tooltip>}
                    >
                      <Card.Link onClick={() => handlePlay(card.id)}>
                        <IoPlay className="icon icon-wide text-success" />
                      </Card.Link>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="m-0">Pause</Tooltip>}
                    >
                      <Card.Link onClick={() => handlePause(card.id)}>
                        <IoPauseCircleOutline className="icon icon-wide text-warning" />
                      </Card.Link>
                    </OverlayTrigger>
                  )}

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Online</Tooltip>}
                  >
                    <Card.Link onClick={() => handleEdit(card.id)}>
                      <HiOutlineStatusOnline className="icon icon-wide text-success" />
                    </Card.Link>
                  </OverlayTrigger>
                </div>

              </Card.Body>
            </Card>
          ))}

        </div>

      </div>

      {/* Modal for Add/Edit Chatbot */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{editingCardId ? "Edit Chatbot" : "Add Chatbot"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Channel Select with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Select Channel</Form.Label>
              <Select
                options={channelOptions}
                value={channel}
                onChange={handleSelectChange}
                placeholder="Choose channel"
              />
              {errors.channel && <div className="text-danger">{errors.channel}</div>}
            </Form.Group>

            {/* Name Input with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </InputGroup>
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </Form.Group>

            {/* Description Textarea with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={description}
                onChange={(e) => handleInputChange(e, "description")}
              />
              {errors.description && <div className="text-danger">{errors.description}</div>}
            </Form.Group>

            <Button type="submit" className="w-100">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
