
import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row, Tooltip, OverlayTrigger, Breadcrumb } from 'react-bootstrap';
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, HomeIcon, SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { useCampaignSummaryMutation } from "Services/services";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {

  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;

  const [tableData, setTableData] = useState([]);

  // const [bulkOption, setBulkOption] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');



  const [viewCDR] = useCampaignSummaryMutation();


  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    // const fetchTableData = async () => {
    //   try {
    //     const response = await viewCDR({
    //       user_id: userID,
    //       // page: currentPage,
    //       // limit: pagelimit,
    //       // search: searchValue,
    //       // startDate: startDate,
    //       // endDate: endDate,
    //     });

    //     if (response?.data?.statusCode === 200) {
    //       console.log('kjaaaaaaaaaaaaaaaaaaaaaaaaaaaa', response);
    //       // setTableData(response?.data?.data?.result?.data || []);
    //       // setTotalPages(response?.data?.data?.result?.totalPages);
    //       // setTotalPosts(response?.data?.data?.result?.totalPosts);
    //     } else {
    //       setTableData([]);
    //     }
    //   } catch (error) {
    //     alert(error.message);
    //     setTableData([]);
    //   }
    // };

    // fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);


  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids;
    const textMessage = "Are you sure you want to delete this List?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "success",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

      // deleteListApi({ id: ids })
      //   .then((response) => {
      //     if (response?.data?.statusCode === 200) {
      //       setMessage('The List has been deleted.')
      //     } else {
      //       setError('Failed to Delete.');
      //     }
      //   })
      //   .catch((error) => {
      //     setError('Error deleting this list.' || error);
      //   });

      // { error === '' ? await SwalWithBootstrapButtons.fire('Deleted', message, 'success') : await SwalWithBootstrapButtons.fire('Failed', error, 'error') }

      // setTableData(newUsers);
    }
  }

  const TableRow = (props) => {
    return (
      <tr className="border-bottom">
        <td></td>
        <td></td>
        <td></td>
        <td><span className="fw-normal">{props.schedule}</span></td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Fragment>

      <div className="d-flex align-items-center my-3">
        <div className="d-block">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item linkAs={Link}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item >Configurations</Breadcrumb.Item>
            <Breadcrumb.Item active>DLR Webhook</Breadcrumb.Item>
          </Breadcrumb>
          <h4>DLR Webhook</h4>

          {/* <p className="mb-0">Your CDR analytics dashboard.</p> */}
        </div>
      </div>

      <Row className="mt-3">
        <Col xs={12} xl={12}>
          <div className="table-settings">
            <Row className="justify-content-between align-items-center">
              <div className="d-flex flex-wrap">
                <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                  <option value="" defaultValue>All Methods</option>
                  <option value="sms">SMS</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="rcs">RCS</option>
                </Form.Select>
                <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                  <InputGroup.Text>
                    <SearchIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    placeholder="API"
                    value={searchValue}
                    onChange={changeSearchValue}
                  />
                </InputGroup>
                <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                  <InputGroup.Text>
                    <SearchIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    placeholder="Body"
                    value={searchValue}
                    onChange={changeSearchValue}
                  />
                </InputGroup>
                {/* <Form.Group id="DateRange">
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text>
                      <CalendarIcon className="icon icon-xs" />
                    </InputGroup.Text>
                    <Datetime
                      timeFormat={false}
                      onChange={setStart}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="Start Date"
                          value={startDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderRadius: "0" }}
                          onChange={(e) => setStart(e.target.value)} />
                      )} />
                    <Datetime
                      timeFormat={false}
                      onChange={setEnd}
                      isValidDate={currDate => currDate.isAfter(start)}
                      initialViewDate={end}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="End Date"
                          value={endDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                          onChange={(e) => setEnd(e.target.value)} />
                      )} />
                  </InputGroup>
                </Form.Group> */}

                <Dropdown className="ms-auto mb-3">
                  <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                    <CogIcon className="icon icon-sm" />
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                    <span className="small ps-3 fw-bold text-dark">Show</span>
                    {[10, 20, 30].map((limit) => (
                      <Dropdown.Item
                        key={limit}
                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                         onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                      >
                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>
          </div>

          <Card border="0" className="table-wrapper table-responsive shadow">

            <Card.Body>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th className="border-bottom">Method</th>
                    <th className="border-bottom">API</th>
                    <th className="border-bottom">Body</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {tableData.length > 0 ? tableData.map((u, index) => (
                    <TableRow key={index + 1} index={index + 1} {...u} />
                  )) :
                    <tr>
                      <td colSpan="6" className="text-center text-danger">No Data Found</td>
                    </tr>}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                <Nav>
                  <Pagination className="mb-0">
                    <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                      Previous
                    </Pagination.Prev>
                    {currentPage >= 3 && (
                      <>
                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                        <Pagination.Ellipsis />
                      </>
                    )}
                    {currentPage > 1 && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                        {currentPage - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    {currentPage < totalPages && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                        {currentPage + 1}
                      </Pagination.Item>
                    )}
                    {currentPage < totalPages - 1 && (
                      <>
                        <Pagination.Ellipsis />
                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                          {totalPages}
                        </Pagination.Item>
                      </>
                    )}
                    <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                      Next
                    </Pagination.Next>
                  </Pagination>

                </Nav>
                <small className="fw-normal small mt-2 mt-md-0">
                  Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                </small>
              </Card.Footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};