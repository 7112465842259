//by shreya saraswat
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Nav, Breadcrumb, Card, Form, ToastContainer, Toast, Button, InputGroup, FormCheck, Modal, Table, Image } from 'react-bootstrap';
import { CheckIcon, HomeIcon, CalendarIcon, KeyIcon, ClockIcon } from "@heroicons/react/solid";
import { Link } from 'react-router-dom';
import { IoMdBackspace } from "react-icons/io";
import { Routes } from "routes";
import { PlusIcon } from "@heroicons/react/outline";
import { DropFilesForm } from "components/Forms";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { AiFillFilePdf, AiFillMessage } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { XCircleIcon } from "@heroicons/react/outline";
import { RiDeleteBin6Line, RiFileWord2Fill } from "react-icons/ri";
import { useVerifyCampaignNameMutation, useContactGetUploadListMutation, useGetSMSTemplatesMutation, useGetWhatsappTemplatesMutation, useCreateCampaignMutation, useCampaignFileMutation } from "Services/services";
import Select from 'react-select';
import moment from "moment-timezone";
import Datetime from "react-datetime";
import '../../components/ProgressSteps.css'; // Import your CSS here
import ProgressSteps from "components/progressStep";
import smsPic from "../../assets/img/mobileScreens/SMS.png"
import whatsappPic from "../../assets/img/mobileScreens/Whatsapp.png"
import CopyToClipboard from "react-copy-to-clipboard";
import SmsCalculator from "pages/SmsCalculator";
// import EmojiPicker from "emoji-picker-react";
// import { FaSmile } from 'react-icons/fa';
import { RiInformationLine } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import { GrDocumentText } from "react-icons/gr";
import { PiArrowSquareUpRight, PiPhone } from "react-icons/pi";
import { MdOutlineContentCopy } from "react-icons/md";


// const userID = JSON.parse(localStorage.getItem("user").id)

export const BulkComponent = () => {
    const [dataType, setDataType] = useState('');
    const [campaignName, setCampaignName] = useState("");
    const [conditions, setConditions] = useState([{ id: 1, type: '', message: '', template: '' }]);
    const [nextId, setNextId] = useState(2);
    const [verify] = useVerifyCampaignNameMutation();
    const [getContactList] = useContactGetUploadListMutation();
    const [getSMSTemplateList] = useGetSMSTemplatesMutation();
    const [getWhatsappTemplateList] = useGetWhatsappTemplatesMutation();
    const [isVerifiedCampaign, setisVerifiedCampaign] = useState();
    const [contactList, setContactList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [templateLists, setTemplateLists] = useState([]);
    const [whatsappContent, setWhatsappContent] = useState([])
    const [contact, setContact] = useState();
    const [template, setTemplate] = useState();
    const [search, setSearch] = useState(""); // Store the search string
    const [api, setApi] = useState(""); // Store the search string
    const [listType, setListType] = useState("addressbook")
    const [selected, setSelected] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startDate, setStartDate] = useState(moment()); // Initialize with current date/time using moment
    const [endDate, setEndDate] = useState(null); // Set as null initially
    const [activePeriod, setActivePeriod] = useState(''); // Default selection
    const [scheduling, setScheduling] = useState('OneTime'); // Default selection
    const [selectedDays, setSelectedDays] = useState([]);

    const [whatsappBulkFile, setWhatsappBulkFile] = useState('');

    const [selectedWeekdays, setSelectedWeekdays] = useState([]);
    const [selectedWeekday, setSelectedWeekday] = useState('');
    const date = moment();
    const currentTime = date.format("[Today], h:mm A");
    const WhatsappCurrentTime = date.format("h:mm A");
    const [contactForm, setContactForm] = useState({
        // list_name: '',
        // remark: '',
        file: '',
    })
    const [uploadList] = useCreateCampaignMutation();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const [templateContent, setTemplateContent] = useState([])
    const [templateContents, setTemplateContents] = useState('')
    const [whatsappTemplateContents, setWhatsappTemplateContents] = useState('')
    const [whatsAppContents, setWhatsAppContents] = useState([])
    const [category, setCategory] = useState()
    const [updatedContents, setUpdatedContents] = useState([]);
    const [showDefault, setShowDefault] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const [copied, setCopied] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [smsDetails, setSmsDetails] = useState({
        textLength: 0,
        remaining: 0,
        numberOfSMS: 1,
        encoding: 'GSM 7'
    });

    const [inputFields, setInputFields] = useState([]);
    const [headerVars, setHeaderVars] = useState([]);

    const [errors, setErrors] = useState({
        campaignName: '',
        dataType: '',
        messageType: '',
        contactOrApi: '',
        template: '',
        conditions: [],
        scheduling: "",
        startDate: "",
        endDate: "",
        startTime: "",
        activePeriod: "",
    });

    const [campaignFileData] = useCampaignFileMutation();
    const [tr, setTR] = useState(0);
    const [vr, setVR] = useState(0);
    const [ir, setIR] = useState(0);



    // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    // const emojiPickerRef = useRef(null);

    /* TOGGLE PAGE START */

    // const [step, setStep] = useState(1);
    // const totalSteps = 4;

    // const handleNext = () => {
    //     if (step < totalSteps) {
    //         setStep((prev) => prev + 1);
    //     }
    // };

    // const handlePrev = () => {
    //     if (step > 1) {
    //         setStep((prev) => prev - 1);
    //     }
    // };

    // const progressWidth = ((step - 1) / (totalSteps - 1)) * 100;

    /* TOGGLE PAGE END */


    const handlePeriodChange = (event) => {
        setActivePeriod(event.target.value); // Update the selected active period
        // Reset selections when changing period
        setSelectedDays([]);
        setSelectedWeekdays([]);
        setSelectedWeekday('')
    };

    const handleScheduleChange = (event) => {
        setScheduling(event.target.value); // Update the selected active period
        // Reset selections when changing period
        setSelectedDays([]);
        setSelectedWeekdays([]);
        setSelectedWeekday('')
    };

    // const toggleDaySelection = (day) => {
    //     setSelectedDays(prev =>
    //         prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    //     );
    // };

    // const toggleWeekdaySelection = (weekday) => {
    //     setSelectedWeekdays(prev =>
    //         prev.includes(weekday) ? prev.filter(d => d !== weekday) : [...prev, weekday]
    //     );
    // };

    // const renderDaysButtons = () => {
    //     return (
    //         <div className="d-flex flex-wrap">
    //             {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
    //                 <Button
    //                     key={day}
    //                     variant={selectedDays === day ? 'secondary' : 'outline-secondary'}
    //                     onClick={() => toggleDaySelection(day)}
    //                     style={{ margin: '5px' }}
    //                 >
    //                     {day}
    //                 </Button>
    //             ))}
    //         </div>
    //     );
    // };

    const renderDaysButtons = () => {
        const handleInfoIconClick = () => {
            // This can show a tooltip or modal with more information if desired
            alert("The campaign will skip months that do not contain the selected date.");
        };

        return (
            <div>
                <div className="d-flex flex-wrap">
                    {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                        <Button
                            key={day}
                            variant={selectedDays === day ? 'secondary' : 'outline-secondary'}
                            onClick={() => toggleDaySelection(day)}
                            style={{ margin: '5px' }}
                        >
                            {day}
                        </Button>
                    ))}
                </div>

                {(selectedDays === 29 || selectedDays === 30 || selectedDays === 31) && (
                    <div className="info-container mt-2 d-flex align-items-center">
                        <RiInformationLine size={20} style={{ marginRight: '8px', color: 'orange' }} onClick={handleInfoIconClick} />
                        <span style={{ marginRight: '8px', color: '#F1A034' }}>
                            Note: The campaign will skip months that do not contain the selected day (e.g., February for 29th, April for 30th).
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const toggleDaySelection = (day) => {
        setSelectedDays(day); // update the selected day to only one value
    };


    const renderWeekdaysButtons = () => {
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return (
            <div className="d-flex flex-wrap">
                {weekdays.map((weekday, index) => (
                    <Button
                        key={index}
                        variant={selectedWeekdays === index ? 'secondary' : 'outline-secondary'}
                        onClick={() => toggleWeekdaySelection(index)}
                        style={{ margin: '5px' }}
                    >
                        {weekday}
                    </Button>
                ))}
            </div>
        );
    };

    const toggleWeekdaySelection = (index) => {
        setSelectedWeekdays(index); // update the selected weekday to only one index at a time
    };


    const handleStartChange = (date) => {
        if (date && date.isValid()) {
            setStartDate(date); // Set start date to the selected date
            // Ensure end date is not before the new start date
            if (date.isAfter(endDate)) {
                setEndDate(date.clone()); // Set end date to match new start date if necessary
            }
        }
    };

    const handleEndChange = (date) => {
        if (date && date.isValid() && date.isSameOrAfter(startDate)) {
            setEndDate(date); // Only set end date if it's after or equal to start date
        }
    };

    const handleDataType = (e) => {

        setDataType(e.target.id)
        if (e.target.id === "addressbook" || e.target.id === "api") {
            setListType(e.target.id);
        }

    }
    const handleSelection = (event) => {
        setTemplate("")
        setSelected(event.currentTarget.value);
        fetchTemplateList(event.currentTarget.value, search)
    };

    // Trigger fetchCampaignList whenever the search string changes
    useEffect(() => {
        fetchCampaignList(search);
        if (conditions[0]?.message) {
            fetchTemplateListIndex(conditions[0].message);
        }
        if (conditions.length === 0) {
            setConditions([{ id: 1, type: '', message: '', template: '' }]);
        }
    }, [conditions, search, listType, conditions[0]?.message], selected);



    // Handle search input changes
    const handleInputChange = (newSearch) => {
        setSearch(newSearch); // Update the search string
        fetchTemplateList(selected, newSearch)
    };

    const handleInput = (e) => {
        if (selected === "WhatsApp")
            fetchTemplateData(e.label)
    };

    const fetchTemplateData = (data) => {
        const postData = {
            "b_party_details": data,
            "template_name": template?.label || '',
            "campaign_name": campaignName
        }
        campaignFileData(postData)
            .then((success) => {
                if (success?.data.statusCode >= 200 || success?.data.statusCode <= 204) {
                    setTR(success.data.data.total_records)
                    setVR(success.data.data.valid_records)
                    setIR(success.data.data["duplicate records"])
                    setShowPreview(true);
                    setToastMessage(success?.data?.message);
                    setToastVariant('success');
                    setWhatsappBulkFile(success.data.data.campaign_id)
                } else {
                    let message = success?.data?.message;
                    message = message.replace(/(\d+(\.\d+)?)/g, (match) => parseFloat(match).toFixed(3));
                    setToastMessage(message);

                    setToastVariant('danger');
                    setShowDefault(false)
                    setShowPreview(false);

                }
                setShowToast(true);
                setShowDefault(false)
            })
            .catch((error) => {
                setToastMessage(error.message || 'Upload failed');
                setToastVariant('danger');
                setShowToast(true);
                setShowDefault(false)
                setShowPreview(false);
            });
    }
    // Fetch campaign list based on the search string
    const fetchCampaignList = async (searchKey) => {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        getContactList({
            user_id: userId,
            page: "",
            limit: "20",
            search: searchKey, // Pass the search key here
            fromDate: "",
            toDate: "",
            list_type: listType,
        })
            .then((response) => {
                if (response?.data?.data?.response?.statusCode === 200) {
                    const options = response?.data?.data?.response?.data.map(user => ({
                        value: user.id,
                        label: user.list_name
                    }));
                    setContactList(options);
                } else {
                    alert('Failed to fetch user data');
                }
            })
            .catch((error) => {
                alert('Error fetching user data', error);
            });
    };



    const fetchTemplateList = async (value, searchKey) => {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        if (value === 'SMS') {
            getSMSTemplateList({
                user_id: userId,
                page: "",
                limit: "20",
                search: searchKey, // Pass the search key here
                fromDate: "",
                toDate: "",
                list_type: listType,
                status: "Active"
            })
                .then((response) => {
                    if (response?.data?.statusCode === 200) {
                        const options = response?.data?.data?.result?.data.map(user => ({
                            value: user.template_id,
                            label: user.template_name
                        }));
                        setTemplateList(options);
                        setTemplateContent(response?.data?.data?.result?.data);
                    } else {
                        alert('Failed to fetch user data');
                    }
                })
                .catch((error) => {
                    alert('Error fetching user data', error);
                });
        }
        else if (value === 'WhatsApp') {
            getWhatsappTemplateList({
                user_id: userId,
                page: "",
                limit: "20",
                search: searchKey,
                fromDate: "",
                toDate: "",
                list_type: listType,
                status: "APPROVED"
            })
                .then((response) => {

                    if (response?.data?.statusCode === 200) {
                        const options = response.data.data.result.data.map(user => ({
                            value: user.template_id,
                            label: user.template_name
                        }));
                        setTemplateList(options);
                        setTemplateContent(response?.data?.data?.result?.data);
                    } else {
                        alert('Failed to fetch user data');
                    }
                })
                .catch((error) => {
                    alert('Error fetching user data', error);
                });
        }

    };

    const fetchTemplateListIndex = async (value, searchKey, index) => {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        let options = [];

        if (value === 'SMS') {
            try {
                const response = await getSMSTemplateList({ user_id: userId, page: "", limit: "20", search: searchKey, fromDate: "", toDate: "", list_type: listType, status: 'Active' });
                if (response?.data?.statusCode === 200) {
                    options = response.data.data.result.data.map(user => ({ value: user.id, label: user.template_name }));
                }
            } catch (error) {
                alert('Error fetching SMS templates', error);
            }
        } else if (value === 'WhatsApp') {
            try {
                const response = await getWhatsappTemplateList({ user_id: userId, page: "", limit: "20", search: searchKey, fromDate: "", toDate: "", list_type: listType, status: 'Active' });
                if (response?.data?.statusCode === 200) {
                    options = response.data.data.result.data.map(user => ({ value: user.id, label: user.template_name }));
                }
            } catch (error) {
                alert('Error fetching WhatsApp templates', error);
            }
        } else if (value === 'RCS') {
            alert('No DATA FOUND for RCS templates');
        }

        // Update the templateLists at the specified index
        setTemplateLists(prev => {
            const updatedLists = [...prev];
            updatedLists[index] = options; // Update the specific index with fetched options
            return updatedLists;
        });
    };







    const handleConditionChange = (index, field, value) => {
        const updatedConditions = [...conditions];
        updatedConditions[index][field] = value;

        // Clear message if the selected value is empty
        if (field === 'message' && value === '') {
            updatedConditions[index].message = ''; // Clear the message
        }

        // Fetch templates when message type changes
        if (field === 'message') {
            fetchTemplateListIndex(value, '', index); // Call the API with correct parameters
        }

        // If changing the type of the first condition to "Success", force the second condition to be "Failure" if it exists
        if (field === 'type' && value === 'Success' && index === 0 && conditions.length > 1) {
            updatedConditions[1].type = 'Failure';
        }

        // If changing the first condition's type to "Failure", remove the second condition
        if (field === 'type' && value === 'Failure' && index === 0) {
            if (conditions.length > 1) {
                updatedConditions.splice(1, 1); // Remove the second condition
            }
        }

        setConditions(updatedConditions);
    };




    // Handler to add a new condition (only if there are fewer than 2 conditions and if the first condition is not "Failure")
    const addCondition = () => {
        // Prevent adding a new condition if the first condition is "Failure"
        if (conditions.length === 1 && conditions[0].type === 'Failure') {
            return; // Do nothing if the first condition is "Failure"
        }

        if (conditions.length < 2) {
            const newConditions = [...conditions];
            newConditions.push({ id: conditions.length + 1, type: '', message: '', template: '' });
            setConditions(newConditions);
        }
    };

    // Handler to delete a condition and re-index IDs
    const deleteCondition = (index) => {
        const updatedConditions = conditions.filter((_, i) => i !== index);

        // Reindex conditions to maintain sequential IDs starting from 1
        const reindexedConditions = updatedConditions.map((condition, i) => ({
            ...condition,
            id: i + 1,
        }));
        setConditions(reindexedConditions);
    };


    // const handleSubmit = () => {
    //     alert('saved');
    // }

    const handleContactForm = (e) => {
        const { name, value, files } = e.target;
        if (name === 'file') {
            setContactForm({
                ...contactForm,
                file: files[0],
            });
        } else {
            setContactForm({
                ...contactForm,
                [name]: value,
            });
        }
    }

    const handleClose = () => {
        setShowDefault(false);
        setShowPreview(false);
    }

    const handlePreview = (e) => {

        e.preventDefault();

        // Initialize validation errors
        let validationErrors = {
            scheduling: "",
            startDate: "",
            endDate: "",
            startTime: "",
            activePeriod: "",
            selectedWeekdays: "",
            selectedDays: "",
        };

        if (dataType !== 'apiPush') {

            if (!scheduling) {
                validationErrors.scheduling = 'Please select a scheduling option (One-Time or Recurring)';
            }

            if (scheduling === "Recurring") {

                // Validate required dates and time
                if (!startDate || !endDate || !startTime) {
                    if (!startDate) {
                        validationErrors.startDate = 'Start Date is required';
                    }
                    if (!endDate) {
                        validationErrors.endDate = 'End Date is required';
                    }
                    if (!startTime) {
                        validationErrors.startTime = 'Start Time is required';
                    }
                }

                // Validate date order
                if (endDate && startDate && endDate.isBefore(startDate)) {
                    validationErrors.endDate = 'End date cannot be earlier than Start date.';
                }

                // Validate active period
                if (!activePeriod) {
                    validationErrors.activePeriod = 'Please select an active period (Weekly or Monthly)';
                }

                // Validate weekly selection
                if (activePeriod === 'Weekly' && selectedWeekdays.length === 0) {
                    validationErrors.selectedWeekdays = 'Please select a day for the weekly frequency';
                }

                // Validate monthly selection
                if (activePeriod === 'Monthly' && selectedDays.length === 0) {
                    validationErrors.selectedDays = 'Please select a frequency for the monthly schedule';
                }
            } else {
                if (!startDate || !startTime) {
                    if (!startDate) {
                        validationErrors.startDate = 'Start Date is required';
                    }
                    if (!startTime) {
                        validationErrors.startTime = 'Start Time is required';
                    }
                }
            }

        } else {
            setScheduling('')
            setStartDate(null)
            setShowDefault(true);
        }


        // Log validation errors

        // If any validation fails, set errors and stop preview
        if (Object.values(validationErrors).some(error => error !== "")) {
            setErrors(validationErrors);
        } else {

            // If no errors, proceed with preview
            setShowDefault(true);
            setErrors(validationErrors); // Clear errors
        }
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    const handleSubmit = (e) => {
        console.log('campaignName:::::::::', campaignName);
        console.log("dataType:::::::", dataType)
        console.log('contact::::::', contact);
        console.log('api::::::', api);
        console.log('contactForm.file::::::::::', contactForm.file);
        console.log('selected:::::::', selected);
        console.log('template::::::', template);
        console.log('conditions::::::::', JSON.stringify(conditions));
        console.log('scheduling:::::::::::', scheduling);
        console.log('startDate::::::::::', startDate);
        console.log('endDate::::::::::', endDate);
        console.log('activePeriod::::::::::', activePeriod);
        console.log('selectedWeekdays:::::::::::', selectedWeekdays);
        console.log('selectedDays::::::::::', selectedDays);
        console.log('startTime:::::::::::::', startTime);
        console.log('ACCOUNT or USER:::::::::::::', JSON.parse(localStorage.getItem("user"))?.id);
        console.log('updatedContents:::::::::::::', JSON.stringify(updatedContents));
        console.log('whatsAppContents:::::::::::::', JSON.stringify(whatsAppContents));

        e.preventDefault();

        const formData = new FormData();
        formData.append('campaign_name', campaignName);
        formData.append('contact_mode', dataType);
        formData.append('b_party_details', dataType === 'addressbook' ? contact.label : (dataType === 'API' ? api.label : "")); //If contact_mode is addressbook & API
        formData.append('file', contactForm.file);
        // formData.append('listname', contactForm.file);//If contact_mode is File - NOT IN API {Not included}
        // formData.append('remarks', contactForm.file);//If contact_mode is File - NOT IN API {Not included}
        formData.append('campaign_type', selected);
        // formData.append('shortName', contactForm.file);//Not in API {Not included}
        // formData.append('domain', contactForm.file);//If contact_mode is API Push - NOT IN API {Not included}
        formData.append('template_id', template?.value || '');
        formData.append('campaign_details', JSON.stringify(conditions));//Not in API
        formData.append('schedule', scheduling);
        formData.append('campaign_start', moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
        formData.append('recurring_frequency', scheduling === 'OneTime' ? '' : dataType === 'apiPush' ? '' : activePeriod);
        formData.append('recurring_day', scheduling === 'OneTime' ? '' : selectedWeekdays);
        formData.append('recurring_date', scheduling === 'OneTime' ? '' : selectedDays);
        formData.append('whatsapp_bulk_file', whatsappBulkFile);

        formData.append(
            'campaign_end',
            scheduling === 'OneTime'
                ? ''
                : dataType === 'apiPush'
                    ? ''
                    : moment(endDate).isValid()
                        ? moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                        : ''
        );

        formData.append('account', JSON.parse(localStorage.getItem("user"))?.id);
        formData.append(
            'campaign_run_time',
            startTime ? moment(startTime).format('HH:mm:ss') : ''
        );

        formData.append('priority', 'high');
        if (selected === 'SMS') {
            formData.append('template_content', templateContents);
        } else if (selected === 'WhatsApp') {
            formData.append('template_content', whatsappTemplateContents !== '' ? JSON.stringify(updatedContents) : JSON.stringify(whatsAppContents));

        }


        uploadList(formData)
            .then((success) => {
                if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
                    setToastMessage(success?.data?.message);
                    setToastVariant('success');
                    setShowDefault(false)
                    setCampaignName('')
                    setDataType('')
                    setContact()
                    setApi('')
                    setContactForm({
                        file: '',
                    });
                    setSelected('')
                    setTemplate()
                    setConditions([{ id: 1, type: '', message: '', template: '' }]);
                    setScheduling('OneTime')
                    setStartDate(moment());
                    setEndDate(moment());
                    setActivePeriod('Weekly')
                    setSelectedWeekdays([])
                    setSelectedDays([])
                    setStartTime(null)
                    setUpdatedContents([])
                    setWhatsAppContents([])
                    setStep(1)

                } else {
                    setToastMessage(success?.data?.message);
                    setToastVariant('danger');
                    setShowDefault(false)
                }
                setShowToast(true);
                setShowDefault(false)
            })
            .catch((error) => {
                setToastMessage(error.message || 'Upload failed');
                setToastVariant('danger');
                setShowToast(true);
                setShowDefault(false)
            });
    }

    const verifyCampaign = (e) => {
        const campaignname = e.target.value;
        setCampaignName(campaignname);

        const regex = /^[a-z0-9_-]{3,}$/;

        if (campaignname.length >= 3 && campaignname.length <= 50 && regex.test(campaignname)) {
            const postData = { campaign_name: campaignname };
            verify(postData)
                .then((success) => {
                    if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
                        setisVerifiedCampaign(true);
                    } else if (success?.data?.statusCode > 300) {
                        setisVerifiedCampaign(false);
                        console.error("Verification failed");
                    }
                })
                .catch((error) => {
                    setisVerifiedCampaign(false);
                    console.error("Verification failed", error);
                });
        } else {
            setisVerifiedCampaign();
        }
    };

    const [step, setStep] = useState(1);
    const totalSteps = (dataType === "apiPush") ? 2 : 3;

    const handleNext = () => {
        if (step === 1) {
            if (validateStep1()) {
                setStep(step + 1);
            } else {
                alert("Please fill in all required fields for Step 1.");
            }
        } else if (step === 2) {
            if (validateStep2()) {
                setStep(step + 1);
            } else {
                alert("Please fill in all required fields for Step 2.");
            }
        }

    };



    const validateStep1 = () => {
        let valid = true;
        let newErrors = { ...errors };
        // Validate Campaign Name
        if (!campaignName || !/^[a-z0-9_]{3,}$/.test(campaignName)) {
            newErrors.campaignName = 'Campaign name must be alpha (uppercase and lowercase) with at least 3 characters.';
            valid = false;
        } else {
            newErrors.campaignName = '';
        }

        // Validate Data Type
        if (!template) {
            newErrors.template = 'Please select a template.';
            valid = false;
        } else {
            newErrors.template = '';
        }

        // Validate Message Type
        if (!selected) {
            newErrors.messageType = 'Please select a message type.';
            valid = false;
        } else {
            newErrors.messageType = '';
        }

        // Validate Contact or API Selection
        if ((dataType === 'addressbook' && !contact) || (dataType === 'api' && !api)) {
            newErrors.contactOrApi = 'Please select a list.';
            valid = false;
        } else {
            newErrors.contactOrApi = '';
        }

        // // Validate File (if data type is 'File')
        // if (dataType === 'File' && !file) {
        //     newErrors.file = 'Please upload a file.';
        //     valid = false;
        // } else {
        //     newErrors.file = '';
        // }

        setErrors(newErrors);
        return valid;
    };


    const validateStep2 = () => {
        let valid = true;
        let newErrors = { ...errors };


        if (!dataType) {
            newErrors.dataType = 'Please select a data type.';
            valid = false;
        } else {
            newErrors.dataType = '';
        }

        // // Validate Conditions
        // const newConditionsErrors = conditions.map((condition, index) => {
        //     let conditionErrors = {};

        //     // Validate Condition Type
        //     if (!condition.type) {
        //         conditionErrors.type = 'Please select a condition type.';
        //         valid = false;
        //     }

        //     // Validate Message Type
        //     if (!condition.message) {
        //         conditionErrors.message = 'Please select a message type.';
        //         valid = false;
        //     }

        //     // Validate Template
        //     if (!condition.template) {
        //         conditionErrors.template = 'Please select a template.';
        //         valid = false;
        //     }

        //     return conditionErrors;
        // });

        // Assign the conditions errors to the errors state
        // newErrors.conditions = newConditionsErrors;

        setErrors(newErrors);
        return valid;
    };





    // const handleNext = () => {
    //     // Validate based on the current step
    //     if (validateStep(step)) {
    //         if (step < totalSteps) {
    //             setStep(step + 1);
    //         }
    //     } else {
    //     }
    // };

    // // Step-specific validation
    // const validateStep = (currentStep) => {
    //     switch (currentStep) {
    //         case 1:
    //             return validatePageOne();
    //         case 2:
    //             return validatePageTwo();
    //         default:
    //             return true;
    //     }
    // };

    // const validatePageOne = () => {
    //     const field1 = 1
    //     const field2 = 2

    //     return field1.length > 0 && field2.length > 0;
    // };

    // const validatePageTwo = () => {
    //     const field3 = 2
    //     const field4 = 0

    //     return field3.length > 0 && field4.length > 0;
    // };

    const handlePrev = () => {
        if (step > 1) setStep(step - 1);
    };

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const handleStartTimeChange = (time) => {
        setStartTime(time);
    };

    const handleEndTimeChange = (time) => {
        setEndTime(time);
    };

    // Helper function to filter and set template content based on selected id
    const filterTemplateContent = (templateId) => {
        const matchedTemplate = templateContent.find(template => template.template_id === templateId);
        if (selected === 'SMS') {
            if (matchedTemplate) {
                setTemplateContents(matchedTemplate.template_content); // Set content if a match is found
            } else {
                setTemplateContents(''); // Clear content if no match is found
            }
        }
        else if (selected === 'WhatsApp') {
            setWhatsAppContents([])
            setWhatsappTemplateContents('')
            if (matchedTemplate) {
                setCategory(matchedTemplate.template_category)
                setWhatsAppContents(matchedTemplate.template_components); // Set content if a match is found
            } else {
                setWhatsAppContents(''); // Clear content if no match is found
            }
        }
    };


    const handleTextAreaChange = (event) => {
        // Limit the template content to 160 characters
        const inputText = event.target.value;
        // if (inputText.length <= 160) {
        //     setTemplateContents(inputText);
        // }
        setTemplateContents(inputText);

    };




    // const handleSmsChange = (text) => {
    //     setTemplateContents(text); // Sync SmsCalculator message with template contents
    // };


    // const toggleEmojiPicker = () => {
    //     setShowEmojiPicker((prev) => !prev);
    // };
    // const handleEmojiClick = (emojiData) => {
    //     const emoji = emojiData.emoji; // Get the emoji character
    //     setTemplateContents((prevContents) => prevContents + emoji); // Append the emoji to the current content
    // };

    // Effect to handle clicks outside the emoji picker
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
    //             setShowEmojiPicker(false); // Close emoji picker if clicked outside
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    const handleCalculation = ({ textLength, remaining, numberOfSMS, encoding }) => {
        setSmsDetails({ textLength, remaining, numberOfSMS, encoding });
    };

    const handleWhatsappTextAreaChange = (event) => {
        const inputText = event.target.value;
        setWhatsappTemplateContents(inputText);
        const newContents = { ...whatsAppContents };

        if (inputText && inputText !== whatsAppContents?.body?.text) {
            newContents.body = {
                ...whatsAppContents.body,
                text: inputText
            };
        }

        setUpdatedContents(newContents);

    };

    return (
        <div>
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
            {/* Step Indicator */}
            <ProgressSteps step={step} totalSteps={totalSteps} />

            {/* Step content */}
            <Row>
                <Col
                    className={selected === 'SMS' || selected === 'WhatsApp' ? 'col-expanded' : 'col-default'}
                    sm={8}
                    lg={selected === 'SMS' || selected === 'WhatsApp' ? 10 : 12}
                >
                    <Card border="0" className="table-wrapper table-responsive shadow" style={{ height: '32rem' }}>
                        <Card.Body>
                            <Form>
                                <div className="step-content">
                                    {step === 1 && <div>

                                        <Form.Group as={Col} xs={12} md={6} className="mb-4 ">
                                            <Form.Label>Campaign Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Campaign Name"
                                                    value={campaignName}
                                                    onChange={verifyCampaign}
                                                    pattern="^[a-z0-9_-]{3,}$"
                                                    title="Campaign name must be lowercase, alphanumeric, at least 3 words, and only _ or - allowed."
                                                    maxLength={50}
                                                    minLength={3}
                                                    autoComplete="new-campaignname"
                                                />
                                                {isVerifiedCampaign && (
                                                    <InputGroup.Text>
                                                        <CheckIcon width={20} className="text-success" />
                                                    </InputGroup.Text>
                                                )}
                                                {isVerifiedCampaign === false && (
                                                    <InputGroup.Text>
                                                        <XCircleIcon width={20} className="text-danger" />
                                                    </InputGroup.Text>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Enter a valid campaign name: lowercase, alphanumeric, at least 3 words, and only _ or - allowed.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                            {errors.campaignName && (
                                                <Form.Text className="text-danger">{errors.campaignName}</Form.Text>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-4">
                                            <Form.Label>Select Message Type</Form.Label>
                                            <div className="d-flex flex-wrap">
                                                <Button variant={selected === 'SMS' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'SMS'}>
                                                    <AiFillMessage className="mb-2 icon-clg text-gray-400" /> SMS
                                                </Button>
                                                <Button variant={selected === 'WhatsApp' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'WhatsApp'}>
                                                    <IoLogoWhatsapp className="mb-2 icon-clg text-gray-400" />WhatsApp
                                                </Button>
                                                <Button variant={selected === 'RCS' ? "gray-800" : "white"} className="d-grid" onClick={handleSelection} value={'RCS'}>
                                                    <BiSolidMessageSquareDetail className="mb-2 icon-clg text-gray-400" />RCS
                                                </Button>
                                            </div>
                                            {errors.messageType && (
                                                <Form.Text className="text-danger">{errors.messageType}</Form.Text>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={8} className="mb-4">
                                            <Form.Label>Template</Form.Label>
                                            <Select
                                                options={templateList}
                                                placeholder={"--Select " + selected + " Template--"}
                                                className="mb-0"
                                                value={template}
                                                onChange={(selectedOption) => {
                                                    setTemplate(selectedOption);
                                                    filterTemplateContent(selectedOption.value); // Use selected template ID to get content
                                                }}
                                                onInputChange={handleInputChange}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                            />
                                            {errors.template && (
                                                <Form.Text className="text-danger">{errors.template}</Form.Text>
                                            )}
                                        </Form.Group>

                                    </div>}
                                    {step === 2 &&
                                        <div>

                                            <Row className="mb-4">
                                                <Form.Group as={Col} xs={6} lg={6} className={`highlight-col ${dataType ? 'active' : ''}`}>
                                                    <Form.Label>Select Data Type :</Form.Label>

                                                    <Form.Check
                                                        name="dataType"
                                                        type="radio"
                                                        id="apiPush"
                                                        label="API Push"
                                                        value="apiPush"
                                                        checked={dataType === 'apiPush'}
                                                        onChange={handleDataType}
                                                    />

                                                    <Form.Check
                                                        name="dataType"
                                                        type="radio"
                                                        id="addressbook"
                                                        label="Address Book"
                                                        value="addressbook"
                                                        checked={dataType === 'addressbook'}
                                                        onChange={handleDataType}
                                                        className={dataType === 'addressbook' ? 'highlight' : ''}
                                                    />

                                                    <Form.Check
                                                        name="dataType"
                                                        type="radio"
                                                        id="api"
                                                        label="API List"
                                                        value="api"
                                                        checked={dataType === 'api'}
                                                        onChange={handleDataType}
                                                        className={dataType === 'api' ? 'highlight' : ''}
                                                    />

                                                    <Form.Check
                                                        name="dataType"
                                                        type="radio"
                                                        id="File"
                                                        label="File Upload"
                                                        value="File"
                                                        checked={dataType === 'File'}
                                                        onChange={handleDataType}
                                                        className={dataType === 'File' ? 'highlight' : ''}
                                                    />
                                                    {errors.dataType && (
                                                        <Form.Text className="text-danger">{errors.dataType}</Form.Text>
                                                    )}
                                                </Form.Group>


                                                {dataType === 'addressbook' && (
                                                    <Form.Group
                                                        as={Col}
                                                        xs={6}
                                                        lg={6}
                                                        className="highlight-form highlight-col"
                                                    >
                                                        <Form.Label>Select From Contact Lists</Form.Label>
                                                        <Select
                                                            options={contactList}
                                                            placeholder="--Select Contact--"
                                                            className="mb-0"
                                                            value={contact}
                                                            onChange={(selectedOption) => { setContact(selectedOption); handleInput(selectedOption) }}
                                                            onInputChange={handleInputChange} // Capture search input
                                                        />
                                                        {errors.contactOrApi && (
                                                            <Form.Text className="text-danger">{errors.contactOrApi}</Form.Text>
                                                        )}
                                                    </Form.Group>
                                                )}

                                                {dataType === 'api' && (
                                                    <Form.Group
                                                        as={Col}
                                                        xs={6}
                                                        lg={6}
                                                        className="px-4 highlight-form highlight-col"
                                                    >
                                                        <Form.Label>Select From API Lists</Form.Label>
                                                        <Select
                                                            options={contactList}
                                                            placeholder="--Select API--"
                                                            className="mb-0"
                                                            value={api}
                                                            onChange={(selectedOption) => { setApi(selectedOption); handleInput(selectedOption) }}
                                                            onInputChange={handleInputChange} // Capture search input
                                                        />
                                                        {errors.contactOrApi && (
                                                            <Form.Text className="text-danger">{errors.contactOrApi}</Form.Text>
                                                        )}
                                                    </Form.Group>
                                                )}

                                                {dataType === 'File' && (
                                                    <>
                                                        <Col className="mt-3 mr-1 mt-sm-0 highlight-form highlight-col">
                                                            <a href={`${process.env.REACT_APP_API_PORT}/download_contact_list_sample`}>&#42;Click here to download Sample File.</a>
                                                            <DropFilesForm onFilesUploaded={handleContactForm} />
                                                        </Col>
                                                    </>
                                                )}

                                            </Row>

                                            <div className="">
                                                {/* Add Condition Button */}
                                                <Form.Group as={Col} xs={12} md={8} className="mb-2 mt-4">
                                                    <div className="d-flex align-items-end mb-3">
                                                        <Form.Label>Conditions</Form.Label>
                                                        <Button
                                                            onClick={addCondition}
                                                            className="ms-auto rounded-pill"
                                                            style={{
                                                                cursor:
                                                                    conditions.length >= 2 ||
                                                                        (conditions[0]?.type === 'Failure' && conditions.length === 1) ||
                                                                        (conditions[0]?.type !== 'Success' && conditions[0]?.type !== 'Failure')
                                                                        ? 'not-allowed'
                                                                        : 'pointer'
                                                            }}
                                                            variant="secondary"
                                                            disabled={conditions[0]?.type !== 'Success' && conditions[0]?.type !== 'Failure'}
                                                        >
                                                            {conditions[0]?.type === 'Failure' && conditions.length === 1
                                                                ? `Condition 1/1`
                                                                : (conditions[0]?.type !== 'Success' && conditions[0]?.type !== 'Failure')
                                                                    ? `Condition 0/0`
                                                                    : `+ Condition ${conditions.length}/2`}
                                                        </Button>
                                                    </div>
                                                    {conditions.map((condition, index) => (
                                                        <div className="d-flex align-items-center mb-2" key={condition.id}>
                                                            {/* Condition Type */}
                                                            <Form.Select
                                                                onChange={(e) => handleConditionChange(index, 'type', e.target.value)}
                                                                value={condition.type}
                                                            >
                                                                <option value="">--Select Condition--</option>
                                                                {/* Only include "Success" if it's not already chosen in the first condition */}
                                                                {!(index === 1 && conditions[0]?.type === "Success") && (
                                                                    <option value="Success">Success</option>
                                                                )}
                                                                <option value="Failure">Failure</option>
                                                            </Form.Select>
                                                            {/* {errors.conditions[index]?.type && <div className="text-danger">{errors.conditions[index]?.type}</div>} */}

                                                            {/* Message Type */}
                                                            <Form.Select
                                                                className="ms-2"
                                                                onChange={(e) => {
                                                                    handleConditionChange(index, 'message', e.target.value);
                                                                }
                                                                }
                                                                value={condition.message}
                                                            >
                                                                <option value="">--Select Message Type--</option>

                                                                {/* First condition options */}
                                                                {index === 0 && (
                                                                    <>
                                                                        {selected === 'SMS' && (
                                                                            <>
                                                                                <option value="WhatsApp">WhatsApp</option>
                                                                                <option value="RCS">RCS</option>
                                                                            </>
                                                                        )}
                                                                        {selected === 'WhatsApp' && (
                                                                            <>
                                                                                <option value="SMS">SMS</option>
                                                                                <option value="RCS">RCS</option>
                                                                            </>
                                                                        )}
                                                                        {selected === 'RCS' && (
                                                                            <>
                                                                                <option value="WhatsApp">WhatsApp</option>
                                                                                <option value="SMS">SMS</option>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}

                                                                {/* Second condition options */}
                                                                {index === 1 && (
                                                                    <>
                                                                        {conditions[0]?.message === 'SMS' && selected === 'WhatsApp' && (
                                                                            <option value="RCS">RCS</option>
                                                                        )}
                                                                        {conditions[0]?.message === 'SMS' && selected === 'RCS' && (
                                                                            <option value="WhatsApp">WhatsApp</option>
                                                                        )}


                                                                        {conditions[0]?.message === 'WhatsApp' && selected === 'SMS' && (
                                                                            <option value="RCS">RCS</option>
                                                                        )}
                                                                        {conditions[0]?.message === 'WhatsApp' && selected === 'RCS' && (
                                                                            <option value="SMS">SMS</option>
                                                                        )}


                                                                        {conditions[0]?.message === 'RCS' && selected === 'SMS' && (

                                                                            <option value="WhatsApp">WhatsApp</option>

                                                                        )}
                                                                        {conditions[0]?.message === 'RCS' && selected === 'WhatsApp' && (

                                                                            <option value="SMS">SMS</option>

                                                                        )}
                                                                    </>
                                                                )}
                                                            </Form.Select>
                                                            {/* {errors.conditions[index]?.message && <div className="text-danger">{errors.conditions[index]?.message}</div>} */}

                                                            <Form.Select
                                                                className="ms-2"
                                                                onChange={(e) => handleConditionChange(index, 'template', e.target.value)}
                                                                value={condition.template}
                                                            >
                                                                <option value="">--Select {condition.message} Template--</option>
                                                                {templateLists[index]?.map(template => (
                                                                    <option key={template.value} value={template.value}>{template.label}</option>
                                                                ))}
                                                            </Form.Select>
                                                            {/* {errors.conditions[index]?.template && <div className="text-danger">{errors.conditions[index]?.template}</div>} */}





                                                            {/* Delete Button */}
                                                            {conditions.length > 1 && (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                                    <Card.Link className="ms-2" onClick={() => deleteCondition(index)}>
                                                                        <RiDeleteBin6Line size={20} className="mb-1 icon icon-xs text-danger" />
                                                                    </Card.Link>
                                                                </OverlayTrigger>
                                                            )}
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                            </div>

                                        </div>}

                                    {(step === 3 && dataType !== "apiPush") &&
                                        <div>
                                            <Row>
                                                <Form.Group style={{ marginBottom: "5px" }}>
                                                    <Form.Label>Schedule :</Form.Label>
                                                    <div className="d-flex justify-content-start" style={{ gap: '1rem' }}> {/* Use flexbox for horizontal alignment */}
                                                        <Form.Check
                                                            type="radio"
                                                            id="oneTime"
                                                            label="One-Time"
                                                            name="activeSchedule"
                                                            value="OneTime"
                                                            checked={scheduling === 'OneTime'}
                                                            onChange={handleScheduleChange}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            id="recurring"
                                                            label="Recurring"
                                                            name="activeSchedule"
                                                            value="Recurring"
                                                            checked={scheduling === 'Recurring'}
                                                            onChange={handleScheduleChange}
                                                        />
                                                    </div>
                                                    {errors.scheduling && <div className="text-danger">{errors.scheduling}</div>}
                                                </Form.Group>

                                            </Row>
                                            {scheduling !== "OneTime" ?
                                                (<>
                                                    <Row>
                                                        <Col style={{ paddingRight: "0" }}>
                                                            <Form.Group style={{ marginBottom: "20px" }}>
                                                                <Form.Label>Start Date :</Form.Label>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    dateFormat="YYYY-MM-DD"  // Only date format
                                                                    timeFormat={false} // Disable time selection
                                                                    onChange={handleStartChange}
                                                                    className="custom-datetime-picker"
                                                                    renderInput={(props, openCalendar) => (
                                                                        <InputGroup style={{ width: '250px' }}>
                                                                            <InputGroup.Text>
                                                                                <CalendarIcon width={20} />
                                                                            </InputGroup.Text>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Start Date"
                                                                                value={startDate.format('YYYY-MM-DD')}
                                                                                onFocus={openCalendar}
                                                                                style={{ width: "170px", display: "inline-block" }}
                                                                                onChange={() => { }} // Leave empty
                                                                            />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col style={{ paddingLeft: "0" }}>
                                                            <Form.Group style={{ marginBottom: "0" }}>
                                                                <Form.Label>End Date :</Form.Label>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    dateFormat="YYYY-MM-DD" // Only date format
                                                                    timeFormat={false} // Disable time selection
                                                                    onChange={handleEndChange}
                                                                    isValidDate={(currDate) => currDate.isSameOrAfter(startDate)} // Only allow dates after startDate
                                                                    className="custom-datetime-picker"
                                                                    renderInput={(props, openCalendar) => (
                                                                        <InputGroup style={{ width: '250px' }}>
                                                                            <InputGroup.Text>
                                                                                <CalendarIcon width={20} />
                                                                            </InputGroup.Text>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="End Date"
                                                                                value={endDate ? `${endDate.format('YYYY-MM-DD')}` : ''}
                                                                                onFocus={openCalendar}
                                                                                style={{ width: "170px", display: "inline-block" }}
                                                                                onChange={() => { }} // Leave empty
                                                                            />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
                                                            </Form.Group>
                                                        </Col>



                                                        <Col style={{ paddingRight: "0" }}>
                                                            <Form.Group style={{ marginBottom: "0" }}>
                                                                <div className="d-flex justify-content-start" style={{ gap: '1rem' }}>
                                                                    <div>
                                                                        <Form.Label>Campaign Execution Time</Form.Label>
                                                                        <Datetime
                                                                            closeOnSelect={true}
                                                                            timeFormat="HH:mm"
                                                                            dateFormat={false} // Disable the date format to hide the date
                                                                            onChange={handleStartTimeChange}
                                                                            className="custom-datetime-picker"
                                                                            renderInput={(props, openCalendar) => (
                                                                                <InputGroup style={{ width: '250px' }}> {/* Set a fixed width */}
                                                                                    <InputGroup.Text>
                                                                                        <ClockIcon width={20} /> {/* Keep only the clock icon */}
                                                                                    </InputGroup.Text>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="text"
                                                                                        placeholder="Start Time"
                                                                                        value={startTime ? startTime.format('HH:mm') : ''}
                                                                                        onFocus={openCalendar}
                                                                                        style={{ width: "170px" }}
                                                                                        onChange={() => { }} // Leave empty
                                                                                    />
                                                                                </InputGroup>
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                    </div>
                                                                </div>
                                                                {errors.startTime && <div className="text-danger">{errors.startTime}</div>}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>



                                                    <Row>
                                                        <Form.Group style={{ marginBottom: "0" }}>
                                                            <Form.Label>Active Period :</Form.Label>
                                                            <div className="d-flex justify-content-start" style={{ gap: '1rem' }}> {/* Use flexbox for horizontal alignment */}
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="weekly"
                                                                    label="Weekly"
                                                                    name="activePeriod"
                                                                    value="Weekly"
                                                                    checked={activePeriod === 'Weekly'}
                                                                    onChange={handlePeriodChange}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="monthly"
                                                                    label="Monthly"
                                                                    name="activePeriod"
                                                                    value="Monthly"
                                                                    checked={activePeriod === 'Monthly'}
                                                                    onChange={handlePeriodChange}
                                                                />
                                                            </div>
                                                            <div className="mt-2 mb-2">
                                                                {activePeriod === 'Weekly' && renderWeekdaysButtons()}
                                                                {activePeriod === 'Monthly' && renderDaysButtons()}
                                                            </div>
                                                            {errors.activePeriod && <div className="text-danger">{errors.activePeriod}</div>}
                                                            {errors.selectedWeekdays && <div className="text-danger">{errors.selectedWeekdays}</div>}
                                                            {errors.selectedDays && <div className="text-danger">{errors.selectedDays}</div>}
                                                        </Form.Group>

                                                    </Row>

                                                </>
                                                ) : (
                                                    <Row>
                                                        <Col style={{ paddingRight: "0" }}>
                                                            <Form.Group style={{ marginBottom: "20px" }}>
                                                                <Form.Label>Start Date & Time</Form.Label>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false} // Disable time selection
                                                                    dateFormat="YYYY-MM-DD"
                                                                    onChange={handleStartChange}
                                                                    className="custom-datetime-picker"
                                                                    renderInput={(props, openCalendar) => (
                                                                        <InputGroup style={{ width: '250px' }}> {/* Set a fixed width */}
                                                                            <InputGroup.Text>
                                                                                <CalendarIcon width={20} />/
                                                                                <ClockIcon width={20} />

                                                                            </InputGroup.Text>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Start Date & Time"
                                                                                value={`${startDate.format('YYYY-MM-DD')} / ${startDate.format('HH:mm')}`}
                                                                                onFocus={openCalendar}
                                                                                style={{ width: "170px" }}
                                                                                onChange={() => { }} // Leave empty
                                                                            />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ paddingRight: "0" }}>
                                                            <Form.Group style={{ marginBottom: "0" }}>
                                                                <div className="d-flex justify-content-start" style={{ gap: '1rem' }}>
                                                                    <div>
                                                                        <Form.Label>Campaign Execution Time</Form.Label>
                                                                        <Datetime
                                                                            closeOnSelect={true}
                                                                            timeFormat="HH:mm" // Time format for the picker
                                                                            dateFormat={false}  // Disable date format to hide the date
                                                                            onChange={handleStartTimeChange}
                                                                            className="custom-datetime-picker"
                                                                            renderInput={(props, openCalendar) => (
                                                                                <InputGroup style={{ width: '250px' }}>
                                                                                    <InputGroup.Text>
                                                                                        <ClockIcon width={20} /> {/* Keep only the clock icon */}
                                                                                    </InputGroup.Text>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="text"
                                                                                        placeholder="Start Time"
                                                                                        value={startTime ? startTime.format('HH:mm') : ''}
                                                                                        onFocus={openCalendar}
                                                                                        onChange={(e) => handleStartTimeChange(moment(e.target.value, 'HH:mm', true))} // Handle keyboard input
                                                                                        style={{ width: "170px" }}
                                                                                    />
                                                                                </InputGroup>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {errors.startTime && <div className="text-danger">{errors.startTime}</div>}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )

                                            }


                                            {/* <Button as={Link} to={Routes.Campaign.path} variant="gray-800" type="submit" className="mt-2 animate-up-2 mb-2" onClick={handleSubmit}>
                                            Preview
                                        </Button> */}
                                        </div>}

                                </div>
                            </Form>
                        </Card.Body>
                    </Card >
                </Col>
                {(selected === 'SMS') &&
                    <Col sm={4} lg={2} style={{ position: 'relative' }}>
                        <SmsCalculator text={templateContents} onCalculation={handleCalculation} />
                        <div className="img-cont">
                            <img src={smsPic} alt="sms preview" />
                            <div className="preview-cont">
                                <div className="time_box">
                                    <span>SMS</span>
                                    <span>{currentTime}</span>
                                </div>
                                <div className="d-flex flex-column position-relative">
                                    <>
                                        <textarea
                                            rows={10}
                                            id="smsBody"
                                            name="template_content"
                                            value={templateContents}
                                            onChange={handleTextAreaChange}
                                            style={{ fontSize: '11px', height: '250px', padding: '8px', resize: 'none', position: 'relative' }}
                                        />
                                        <p
                                            className="chars_count"
                                            style={{
                                                position: 'absolute',
                                                bottom: '21%',
                                                left: '51%',
                                                fontSize: '10px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {templateContents.length || 0}
                                        </p>
                                    </>
                                    <div style={{
                                        margin: '6px auto',
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "space",
                                        flexWrap: "wrap",
                                        position: "relative",
                                        width: "74%",
                                        left: "-3%"
                                    }}>
                                        <div style={capsuleContainerStyle}>
                                            <div style={capsuleStyle}>
                                                <strong>{smsDetails.encoding}</strong>
                                            </div>
                                            <div style={capsuleStyle}>
                                                <strong>Char Used: {smsDetails.textLength}</strong>
                                            </div>
                                            <div style={capsuleStyle}>
                                                <strong>SMS Char: {smsDetails.remaining}</strong>
                                            </div>
                                            <div style={capsuleStyle}>
                                                <strong>SMS Parts: {smsDetails.numberOfSMS}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <button
                                type="button"
                                onClick={toggleEmojiPicker} // Toggle emoji picker on button click
                                style={{
                                    marginTop: '10px',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: '#888',
                                }}
                            >
                                <FaSmile />
                            </button>
                            {showEmojiPicker && (
                                <div
                                    ref={emojiPickerRef} // Reference to detect outside clicks
                                    style={{
                                        position: 'absolute',
                                        bottom: '-250px',
                                        left: '-200px', // Adjust left to move the picker to the left side of the button
                                        zIndex: 1000,
                                        border: '1px solid #ccc',
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                                </div>
                            )} */}
                                </div>
                            </div>
                        </div>
                    </Col>
                }
                {selected === 'WhatsApp' &&
                    <Col xs={4} lg={2}>
                        <div className="img-cont">
                            <img src={whatsappPic} alt="whatsapp preview" />

                            <div className="preview-cont">
                                {(whatsAppContents?.header || whatsAppContents?.body || whatsAppContents?.footer) && (category !== 'AUTHENTICATION') &&
                                    <div className="whatsBody">
                                        {whatsAppContents?.header?.type === "text" && <span className="w-header">{whatsAppContents?.header?.example}</span>}
                                        {whatsAppContents?.header?.type === "image" && <Image src={whatsAppContents?.header?.example} className="w-image" />}
                                        {whatsAppContents?.header?.type === "video" &&
                                            <video controls width="100%" className="w-image">
                                                <source src={whatsAppContents?.header?.example} type="video/mp4" />
                                                Unsupported video tag by browser.
                                            </video>}
                                        {whatsAppContents?.header?.type === "doc" &&
                                            <span className="w-doc">
                                                {whatsAppContents?.header?.example === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? <RiFileWord2Fill style={{ color: '#2A5293' }} />
                                                    : whatsAppContents?.header?.example === 'application/pdf' ? <AiFillFilePdf style={{ color: '#CB3724' }} />
                                                        : whatsAppContents?.header?.example === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? <SiMicrosoftexcel style={{ color: '#4AA66A' }} />
                                                            : <GrDocumentText />}

                                            </span>
                                        }
                                        <br />
                                        <>
                                            <textarea
                                                id="smsBody"
                                                name="template_content"
                                                maxLength={1032}
                                                value={whatsappTemplateContents || whatsAppContents?.body?.text}
                                                onChange={handleWhatsappTextAreaChange}
                                                style={{ fontSize: '12px', height: 'auto', padding: '8px', position: 'relative', margin: '10px 0px 0px 0px', width: '100%' }}
                                            />
                                            <p
                                                className="chars_count"
                                                style={{
                                                    position: 'relative',
                                                    left: '35%',
                                                    fontSize: '10px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {whatsappTemplateContents?.length || whatsAppContents?.body?.text?.length || 0}/1032
                                            </p>
                                        </>
                                        {whatsAppContents.footer && <span className="w-footer">{whatsAppContents?.footer?.text}</span>}
                                        {whatsAppContents.buttons && Object.keys(whatsAppContents.buttons).length > 0 &&
                                            <div className="previewWhatsTemp">
                                                <div className="wp-btns">
                                                    {whatsAppContents.buttons.type === "combined_buttons" &&
                                                        whatsAppContents.buttons.elements.map((row, index) => (
                                                            <p key={index}>
                                                                {row.type === "dynamic" || row.type === "static" ? (
                                                                    <>
                                                                        <PiArrowSquareUpRight />
                                                                        {row.label}
                                                                    </>
                                                                ) : row.type === "phone" ? (
                                                                    <>
                                                                        <PiPhone />
                                                                        {row.label}
                                                                    </>
                                                                ) : (
                                                                    row.text
                                                                )}
                                                            </p>
                                                        ))}
                                                    {whatsAppContents.buttons.type === "quick_reply" &&
                                                        whatsAppContents.buttons.elements.map((row, index) =>
                                                        (
                                                            <p key={index}>{row.text}</p>
                                                        )
                                                        )}
                                                    {whatsAppContents.buttons.type === "call_to_action" &&
                                                        whatsAppContents.buttons.elements.map((row, index) =>
                                                            <p key={index}>
                                                                {row.type === "static" || row.type === "dynamic" ? (
                                                                    <>
                                                                        <PiArrowSquareUpRight />
                                                                        {row.label}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <PiPhone />
                                                                        {row.label}
                                                                    </>
                                                                )}
                                                            </p>
                                                        )}
                                                </div>
                                            </div>
                                        }
                                        <div className="time_box">
                                            <span className="ms-auto">{WhatsappCurrentTime}</span>
                                        </div>
                                    </div>
                                }
                                {
                                    (category == 'AUTHENTICATION') &&
                                    <div className="whatsBody">
                                        <span className="w-body"><b>{whatsAppContents?.buttons?.text}</b> is your verification code. For Your Security, do not share this code.</span>
                                        <br />
                                        {whatsAppContents.footer && <span className="w-footer">This code expires in {whatsAppContents?.footer?.code_expiration_minutes} minutes.</span>}
                                        {whatsAppContents.buttons &&
                                            <>
                                                <div className="wdivider" />
                                                <div className="whatsButton">
                                                    <MdOutlineContentCopy /> Copy Code
                                                </div>
                                            </>
                                        }
                                        <div className="time_box">
                                            <span className="ms-auto">{WhatsappCurrentTime}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                }


            </Row>
            {/* <div>
                <EmojiPicker />
            </div> */}
            {/* Navigation buttons */}
            <div className="mt-2 ">
                <Button
                    className={`btn btn-prev me-2 ${step === 1 ? 'disabled' : ''}`}
                    onClick={handlePrev}
                    disabled={step === 1}
                    variant="tertiary"
                >
                    Prev
                </Button>
                {(step === totalSteps) ? (<Button
                    className="btn btn-next"
                    onClick={handlePreview}
                    variant="tertiary"
                >
                    Preview
                </Button>
                ) : (
                    <Button
                        className={`btn btn-next ${step === totalSteps ? 'disabled' : ''}`}
                        onClick={handleNext}
                        disabled={step === totalSteps}
                        variant="tertiary"
                    >
                        Next
                    </Button>
                )
                }

            </div>

            <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h6">Campaign Preview</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Card className="mb-2 mt-2">
                        <Row>
                            <Col md={11}>
                                <Table responsive className="table-centered table-nowrap rounded mb-0">
                                    <tbody>
                                        {/* Campaign Details */}
                                        <tr>
                                            <td className="border-0">Campaign Name</td>
                                            <td className="border-0">{campaignName || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Data Type</td>
                                            <td className="border-0">
                                                {{
                                                    addressbook: 'Address Book',
                                                    API: 'API List',
                                                    FILE: 'File',
                                                    apiPush: 'API Push',
                                                }[dataType] || 'Unknown'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                {{
                                                    addressbook: 'Address Book Detail',
                                                    API: 'API List Detail',
                                                    FILE: 'Uploaded File',
                                                    apiPush: 'API Push Detail',
                                                }[dataType] || 'Details'}
                                            </td>
                                            <td className="border-0">
                                                {dataType === 'addressbook' ? contact?.label : dataType === 'API' ? api?.label : 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Message Type</td>
                                            <td className="border-0">{selected || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Template</td>
                                            <td className="border-0">{template?.value || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Template Content</td>
                                            <td className="border-0">
                                                <div className="truncated-text">{whatsappTemplateContents !== '' ? JSON.stringify(updatedContents?.body?.text) : (selected === "WhatsApp" ? whatsAppContents?.body?.text : templateContents)}</div>
                                            </td>

                                        </tr>

                                        {/* Conditions */}
                                        <tr>
                                            <td className="border-0">Condition</td>
                                            <td className="border-0">
                                                {conditions.length > 0 ? (
                                                    conditions.map((condition, index) => (
                                                        <div key={condition.id}>
                                                            {index + 1}. {condition.type || 'N/A'} {'=>'} {condition.message || 'N/A'} {'=>'}
                                                            Template ID: {condition.template || 'N/A'}
                                                        </div>
                                                    ))
                                                ) : (
                                                    'No Conditions'
                                                )}
                                            </td>
                                        </tr>

                                        {dataType !== "apiPush" &&
                                            <>
                                                {/* Scheduling Details */}
                                                <tr>
                                                    <td className="border-0">Schedule</td>
                                                    <td className="border-0">{scheduling || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Start Date</td>
                                                    <td className="border-0">{startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A'}</td>
                                                </tr>

                                                {/* Recurring Details */}
                                                {scheduling !== 'OneTime' && (
                                                    <>
                                                        <tr>
                                                            <td className="border-0">End Date</td>
                                                            <td className="border-0">{endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Active Period</td>
                                                            <td className="border-0">{activePeriod || 'N/A'}</td>
                                                        </tr>
                                                        {activePeriod === 'Weekly' && (
                                                            <tr>
                                                                <td className="border-0">Weekday</td>
                                                                <td className="border-0">
                                                                    {selectedWeekdays === 0 ? "Sunday" :
                                                                        selectedWeekdays === 1 ? "Monday" :
                                                                            selectedWeekdays === 2 ? "Tuesday" :
                                                                                selectedWeekdays === 3 ? "Wednesday" :
                                                                                    selectedWeekdays === 4 ? "Thursday" :
                                                                                        selectedWeekdays === 5 ? "Friday" :
                                                                                            "Saturday"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {activePeriod === 'Monthly' && (
                                                            <tr>
                                                                <td className="border-0">Month Date</td>
                                                                <td className="border-0">{selectedDays}</td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}

                                                {/* Start Time */}
                                                <tr>
                                                    <td className="border-0">Start Time</td>
                                                    <td className="border-0">{startTime ? moment(startTime).format('HH:mm:ss') : 'N/A'}</td>
                                                </tr>
                                            </>}
                                    </tbody>

                                </Table>
                            </Col>
                            <Col md={1} className="d-flex align-items-start">
                                <Card.Body className="d-flex flex-column">
                                    <div>
                                        {copied ? (
                                            <Button size="sm" variant="success" className="copy-code-button">
                                                Copied
                                            </Button>
                                        ) : (
                                            <CopyToClipboard
                                                text={`Campaign Name:${campaignName || 'N/A'}\nData Type:${{ addressbook: 'Address Book', API: 'API List', FILE: 'File', apiPush: 'API Push' }[dataType] || 'Unknown'}\nDetails:${{ addressbook: 'Address Book Detail', API: 'API List Detail', FILE: 'Uploaded File', apiPush: 'API Push Detail', }[dataType] || 'Details'}\nMessage Type:${selected || 'N/A'}\nTemplate:${template?.value || 'N/A'}\nTemplate Content:${templateContents || 'N/A'}\nConditions:${conditions.length > 0 ? conditions.map((condition, index) => `${index + 1}. ${condition.type || 'N/A'}=>${condition.message || 'N/A'}=>Template ID:${condition.template || 'N/A'}`).join('\n') : 'No Conditions'}${dataType !== 'apiPush' ? ` \nScheduling:${scheduling || 'N/A'}\nStart Date:${startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A'}\nEnd Date:${endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A'}\nActive Period:${activePeriod || 'N/A'}\nWeekday:${selectedWeekdays === 0 ? 'Sunday' : selectedWeekdays === 1 ? 'Monday' : selectedWeekdays === 2 ? 'Tuesday' : selectedWeekdays === 3 ? 'Wednesday' : selectedWeekdays === 4 ? 'Thursday' : selectedWeekdays === 5 ? 'Friday' : 'Saturday'}\nMonth Date:${selectedDays || 'N/A'}\nStart Time:${startTime ? moment(startTime).format('HH:mm:ss') : 'N/A'}` : ''}`}
                                                onCopy={handleCopy}
                                            >
                                                <Button size="sm" variant="primary" className="copy-code-button">
                                                    Copy
                                                </Button>
                                            </CopyToClipboard>
                                        )}
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>

                    {/* <Card className="mb-2 mt-3">
                        <Row>
                            <Col md={12}>
                                <Card.Body>
                                    <b>Share Details:</b>
                                    <FormCheck type="switch" className="mt-2">
                                        <FormCheck.Input
                                            type="checkbox"
                                            id="emailSwitch"
                                            checked={selectedOption === 'email'}
                                            onChange={() => setSelectedOption('email')}
                                        />
                                        <FormCheck.Label htmlFor="emailSwitch" style={{ marginLeft: '8px' }}>
                                            Email ID
                                        </FormCheck.Label>
                                    </FormCheck>
                                    <FormCheck type="switch" className="mt-2">
                                        <FormCheck.Input
                                            type="checkbox"
                                            id="phoneSwitch"
                                            checked={selectedOption === 'phone'}
                                            onChange={() => setSelectedOption('phone')}
                                        />
                                        <FormCheck.Label htmlFor="phoneSwitch" style={{ marginLeft: '8px' }}>
                                            Phone Number
                                        </FormCheck.Label>
                                    </FormCheck>
                                    <FormCheck type="switch" className="mt-2">
                                        <FormCheck.Input
                                            type="checkbox"
                                            id="bothSwitch"
                                            checked={selectedOption === 'both'}
                                            onChange={() => setSelectedOption('both')}
                                        />
                                        <FormCheck.Label htmlFor="bothSwitch" style={{ marginLeft: '8px' }}>
                                            Both
                                        </FormCheck.Label>
                                    </FormCheck>
                                    <FormCheck type="switch" className="mt-2">
                                        <FormCheck.Input
                                            type="checkbox"
                                            id="noneSwitch"
                                            checked={selectedOption === ''}
                                            onChange={() => setSelectedOption('')}
                                        />
                                        <FormCheck.Label htmlFor="noneSwitch" style={{ marginLeft: '8px' }}>
                                            None
                                        </FormCheck.Label>
                                    </FormCheck>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showPreview} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h6">{{
                        addressbook: 'Address Book',
                        API: 'API List',
                        FILE: 'File',
                        apiPush: 'API Push',
                    }[dataType] || 'Unknown'} Preview</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Card className="mb-2 mt-2">
                        <Row>
                            <Col md={12}>
                                <Table responsive className="table-centered table-nowrap rounded mb-0">
                                    <tbody>
                                        {/* Privew popup */}
                                        <tr>
                                            <td className="border-0">Channel Name</td>
                                            <td className="border-0">{selected || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Campaign Name</td>
                                            <td className="border-0">{campaignName || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">File Name</td>
                                            <td className="border-0">{template?.label || 'N/A'}</td>
                                        </tr>
                                    </tbody>

                                </Table>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="mb-2 mt-3">
                        <Row>
                            <Col md={12}>
                                <Table responsive className="table-centered table-nowrap rounded mb-0">
                                    <tbody>
                                        {/* Privew popup */}
                                        <tr>
                                            <td className="border-0">Total Contacts</td>
                                            <td className="border-0">{tr}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Valid Contacts</td>
                                            <td className="border-0">{vr}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Invalid Contacts</td>
                                            <td className="border-0">{ir}</td>
                                        </tr>
                                    </tbody>

                                </Table>
                            </Col>
                        </Row>
                    </Card>

                    {/* <Card>
                        <Row>
                            <Col md={12}>
                                <Table responsive className="table-centered table-nowrap rounded mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="border-0 text-danger" colSpan="2" style={{ textAlign: 'center' }}>
                                                Your amount is low
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}
const capsuleContainerStyle = {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    flexWrap: 'wrap', // Allows wrapping on smaller screens
};

const capsuleStyle = {
    padding: '2px',
    backgroundColor: '#e0f7fa', // Light blue background
    borderRadius: '20px',       // Rounded capsule effect
    border: '1px solid #00acc1', // Border color
    fontSize: '10px',
    color: '#006064',            // Text color
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 150px',           // Sets minimum width and allows flexibility
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '84px',
};