
import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row, Tooltip, OverlayTrigger, Breadcrumb, Modal, ToastContainer } from 'react-bootstrap';
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, HomeIcon, SearchIcon, UserIcon, XCircleIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { useDownloadCenterMutation, useUserDropDownMutation, useGetCdrDetailListMutation } from "Services/services";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/outline";
import Select from 'react-select';
import { Toast } from 'react-bootstrap';
import pages from "data/pages";


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {

  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;
  const [tableData, setTableData] = useState([]);

  const [showToast, setShowToast] = useState(false);

  const [peOptions, setPeOptions] = useState([]);
  // const [user, setUser] = useState(null);
  const [userList] = useUserDropDownMutation();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [users, setUsers] = useState();
  
  const [toastMessage, setToastMessage] = useState('Data Fetched');
  const [toastVariant, setToastVariant] = useState('success');

  const [userDropDown] = useUserDropDownMutation();
  const [viewCDR] = useDownloadCenterMutation();
  const [user, setUser] = useState();
  const [error, setError] = useState(""); // State for validation error
  const [list] = useGetCdrDetailListMutation();

  // Debounce search value
  useEffect(() => {
    fetchUserDropdown();
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const fetchUserDropdown = async () => {
    userDropDown({ user_type: "4" })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const userOptions = response.data.data.user_details.map(user => ({
            value: user.id, // Assuming `id` is the identifier you want to use
            label: `${user.user_type.toUpperCase() + " : " + user.user_name}` // Assuming `username` is the label you want to display
          }));
          setUsers(userOptions);
        } else {
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data', error);
      });
  };

  // entity options set
  useEffect(() => {
    if (userType !== "enterprise") {
      const fetchEntity = async () => {
        try {
          const response = await userList({ user_type: 4 });
          if (response?.data?.statusCode === 200) {
            const userOptions = response?.data?.data?.user_details.map(option => ({
              value: option.id,
              label: option.user_name
            }));
            setPeOptions(userOptions);
          } else {
            setPeOptions([]);
          }
        } catch (error) {
          setPeOptions([]);
        }
      };
      fetchEntity();
    } else {
      setUser();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType])

  useEffect(() => {

    fetchlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, pagelimit, currentPage, searchValue]);


  const fetchlist = async () => {
    try {
      const response = await list({
        startDate: startDate,
        endDate: endDate,
        page: currentPage,
        limit: pagelimit,
        user_name: searchValue
      });
      if (response?.data?.statusCode === 200) {
        setTableData(response?.data?.data.result.data)
        setTotalPosts(response?.data?.data.result.totalRecords)
        setTotalPages(response?.data?.data.result.totalPages)
        // setShowToast(true);
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
    }
  }

  const fetchTableData = async () => {
    // console.log('user.value',user?.value);
    if (!user) {
      setError("Please select a user.");
    } else {
      setError(""); // Clear error if user is selected
      // Proceed with form submission
      console.log("Form submitted with user:", user);
    }
    try {
      const response = await viewCDR({
        user_id: userType !== 'enterprise' ? user.value : userID,
        startDate: startDates,
        endDate: endDates,
        login_user: userID,
      });
      console.log('response', response.error.data.message);
      if (response?.data?.statusCode === 200) {
        const confirmMessage = `The report retreival successfully.`;
        SwalWithBootstrapButtons.fire('Approved', confirmMessage, 'success');
      } else if (response?.data?.statusCode >= 204) {
        setToastMessage(response.error.data.message);
        setToastVariant('danger');
      }
    } catch (error) {
      alert(error.message);

      setTableData([]);
    }
    setShowToast(true);
    fetchlist();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids;
    const textMessage = "Are you sure you want to delete this List?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "success",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });
    return;
    // if (result.isConfirmed) {
    //   const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

    // deleteListApi({ id: ids })
    //   .then((response) => {
    //     if (response?.data?.statusCode === 200) {
    //       setMessage('The List has been deleted.')
    //     } else {
    //       setError('Failed to Delete.');
    //     }
    //   })
    //   .catch((error) => {
    //     setError('Error deleting this list.' || error);
    //   });

    // { error === '' ? await SwalWithBootstrapButtons.fire('Deleted', message, 'success') : await SwalWithBootstrapButtons.fire('Failed', error, 'error') }

    // setTableData(newUsers);
    // }
  }

  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const TableRow = (props) => {
    return (
      <tr className="border-bottom">
        {userType !== 'enterprise' && <td><span className="fw-normal">{props.user_name}</span></td>}
        <td><span className="fw-normal">{moment(props.fromDate).format('DD/MM/YYYY')}</span></td>
        <td><span className="fw-normal">{moment(props.toDate).format('DD/MM/YYYY')}</span></td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Download</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => downloadUsers(props.link.split('/').pop())}>
              <DownloadIcon className="icon icon-xs text-info" />
            </Card.Link>
          </OverlayTrigger>
        </td>
        <td>
          <span
            className={`fw-normal ${props.status === 'Completed' ? 'text-success' :
              props.status === 'InProcessing' ? 'text-warning' : ''
              }`}
          >
            {props.status}
          </span>
        </td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  const downloadUsers = async (fileName) => {
    try {
      const url = `https://mat.blockcube.co/api/download_cdr_file/${fileName}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a URL for the Blob and trigger the download
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName; // Set the file name for the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      setToastMessage(error);
      setToastVariant('danger');
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    fetchTableData()
  };

  const handleStartDateChange = (date) => {
    setStartDates(moment(date).format("YYYY-MM-DD"));
  };

  const handleEndDateChange = (date) => {
    setEndDates(moment(date).format("YYYY-MM-DD"));
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <Fragment>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="d-flex align-items-center my-3">
        <div className="d-block">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item ><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item >Reports</Breadcrumb.Item>
            <Breadcrumb.Item active>Download Center</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Download Centers</h4>
          {/* <p className="mb-0">Your CDR analytics dashboard.</p> */}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Card border="0" className="shadow mb-2 w-75">
          <Card.Header className="text-center bg-primary text-white">
            <h5>Report Download</h5>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit} autoComplete="off">
              <input type="text" name="preventAutoFill" style={{ display: 'none' }} />
              <Row className="align-items-center">
                {/* User selection for non-enterprise */}
                {userType !== 'enterprise' && (
                  <Col md={4} className="mb-3">
                    <Form.Group id="cardNameLabel" className="position-relative">
                      <RequiredLabel label="User" />
                      <Select
                        options={users}
                        placeholder="--Select Enterprise--"
                        className={`mb-0 ${error ? "is-invalid" : ""}`}
                        isSearchable={true}
                        value={user}
                        onChange={(selectedOption) => {
                          setUser(selectedOption);
                          setError(""); // Clear error when user selects a valid option
                        }}
                      />
                      {error && (
                        <div
                          className="text-danger"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            marginTop: "0.25rem",
                          }}
                        >
                          {error}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                )}
                {/* Start Date */}
                <Col md={userType !== 'enterprise' ? 4 : 6} className="mb-3">
                  <Form.Group id="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Datetime
                      timeFormat={false} // Remove time picker
                      onChange={handleStartDateChange}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={startDates || ""}
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => { }}
                            autoComplete="new-startDate"
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                {/* End Date */}
                <Col md={userType !== 'enterprise' ? 4 : 6} className="mb-3">
                  <Form.Group id="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Datetime
                      timeFormat={false} // Remove time picker
                      onChange={handleEndDateChange}
                      isValidDate={(currentDate) =>
                        moment(currentDate).isAfter(moment(startDates, "YYYY-MM-DD"), 'day')
                      }
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={endDates || ""}
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => { }}
                            autoComplete="new-endDate"
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center">
                <Button variant="primary" className="my-3" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>

      </div>




      <Row className="mt-3">
        <Col xs={12} xl={12}>
          <div className="table-settings">
            <Row className="justify-content-between align-items-center">
              <div className="d-flex flex-wrap">
                {userType !== 'enterprise' &&
                  <InputGroup className="me-2 me-lg-3 mb-3 fmxw-300">
                    <InputGroup.Text>
                      <SearchIcon className="icon icon-xs" />
                    </InputGroup.Text>
                    <Form.Control
                      type="search"
                      placeholder="Search Enterprise Name..."
                      value={searchValue}
                      onChange={changeSearchValue}
                    />
                  </InputGroup>
                }
                <Form.Group id="DateRange">
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text>
                      <CalendarIcon className="icon icon-xs" />
                    </InputGroup.Text>
                    <Datetime
                      timeFormat={false}
                      onChange={setStart}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="Start Date"
                          value={startDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderRadius: "0" }}
                          onChange={(e) => setStart(e.target.value)} />
                      )} />
                    <Datetime
                      timeFormat={false}
                      onChange={setEnd}
                      isValidDate={currDate => currDate.isAfter(start)}
                      initialViewDate={end}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="End Date"
                          value={endDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                          onChange={(e) => setEnd(e.target.value)} />
                      )} />
                  </InputGroup>
                </Form.Group>

                <Dropdown className="ms-auto mb-3">
                  <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                    <CogIcon className="icon icon-sm" />
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                    <span className="small ps-3 fw-bold text-dark">Show</span>
                    {[10, 20, 30].map((limit) => (
                      <Dropdown.Item
                        key={limit}
                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                        onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                      >
                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>
          </div>

          <Card border="0" className="table-wrapper table-responsive shadow">

            <Card.Body>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    {userType !== 'enterprise' && <th className="border-bottom">Enterprise Name</th>}
                    <th className="border-bottom">Start Date</th>
                    <th className="border-bottom">End Date</th>
                    <th className="border-bottom">Download</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {tableData.length > 0 ? tableData.map((u, index) => (
                    <TableRow key={index + 1} index={index + 1} {...u} />
                  )) :
                    <tr>
                      <td colSpan="6" className="text-center text-danger">No Data Found</td>
                    </tr>}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                <Nav>
                  <Pagination className="mb-0">
                    <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                      Previous
                    </Pagination.Prev>
                    {currentPage >= 3 && (
                      <>
                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                        <Pagination.Ellipsis />
                      </>
                    )}
                    {currentPage > 1 && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                        {currentPage - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    {currentPage < totalPages && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                        {currentPage + 1}
                      </Pagination.Item>
                    )}
                    {currentPage < totalPages - 1 && (
                      <>
                        <Pagination.Ellipsis />
                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                          {totalPages}
                        </Pagination.Item>
                      </>
                    )}
                    <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                      Next
                    </Pagination.Next>
                  </Pagination>

                </Nav>
                <small className="fw-normal small mt-2 mt-md-0">
                  Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                </small>
              </Card.Footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};