export const uploadMediaGetId = (builder) => ({
    uploadMediaGetId: builder.mutation({
      query: (credentials) => {
        return {
          url: '/media_update',
          method: 'POST',
          body: credentials,
        };
      },
    }),
  });