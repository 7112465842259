export const CDRDetails = (builder) => ({
    CDRDetails: builder.mutation({
        query: ({ startDate, endDate, mode, campaign_id, page, limit }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/cdrDetails',
                method: 'GET',
                params: { startDate, endDate, mode, campaign_id, page, limit },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            };
        },
    }),
});