import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userLoginMutation } from '../api/Common/Login/login';
import { userOnboardingMutation } from 'api/SuperAdmin/UserOnboarding/userOnboarding';
import { userTypeMutation } from 'api/SuperAdmin/UserOnboarding/userType';
import { userDataMutation } from 'api/SuperAdmin/UserOnboarding/userData';
import { contactUploadListMutation } from 'api/Enterprise/ContactList/uploadList';
import { contactGetUploadListMutation } from 'api/Enterprise/ContactList/getUploadList';
import { userCheckMutation } from 'api/Common/CheckUser/checkUserDetails';
import { userDropDownMutation } from 'api/Common/UserDropDown/userDropDown';
import { verifyOtpMutation } from 'api/Common/Login/verifyOtp';
import { insertTransactionMutation } from 'api/SuperAdmin/Wallet/insertTransaction';
import { transactionalListMutation } from 'api/SuperAdmin/Wallet/getTransactionalList';
import { userUpdateFieldMutation } from 'api/SuperAdmin/UserOnboarding/userEditField';
import { approveUserMutation } from 'api/SuperAdmin/UserOnboarding/approveuser';
import { getUserBalanceMutation } from 'api/SuperAdmin/Wallet/getUserBalance';
import { deleteContactListMutation } from 'api/Enterprise/ContactList/deleteList';
import { getSMSTemplatesMutation } from 'api/Enterprise/Template/getSMSTemp';
import { createSMSTemplateMutation } from 'api/Enterprise/Template/createSMSTemp';
import { SMSTestingMutation } from 'api/SuperAdmin/ChannelTesting/smsTesting';
import { getCampaignListMutation } from 'api/Enterprise/Campaign/getCampaigns';
import { verifyCampaignNameMutation } from 'api/Enterprise/Campaign/verifyCampaignName';
import { deleteCampaignListMutation } from 'api/Enterprise/Campaign/deleteCampaignList';
import { getWhatsappTemplatesMutation } from 'api/Enterprise/Template/WhatsApp/getWhatsappTemp';
import { createWhatsAppMarketingMutation } from 'api/Enterprise/Template/WhatsApp/postMarketing';
import { WhatsAppTestingMutation } from 'api/SuperAdmin/ChannelTesting/whatsappTesting';
import { uploadMedia } from 'api/Common/mediaToUrl/mediaToUrl';
import { createCampaignMutation } from 'api/Enterprise/Campaign/createCampaign';
import { blacklistSMSTemplatesMutation } from 'api/Enterprise/Template/blacklistTemplate';
import { getRoutesList } from 'api/SuperAdmin/routeManage/routesList';
import { getMsgRatesList } from 'api/SuperAdmin/MessageRate/getRateList';
import { postRate } from 'api/SuperAdmin/MessageRate/postRate';
import { yearlySalesDetails } from 'api/Common/Dashboard/yearlySales';
import { yearlySalesDetailsCRM } from 'api/Common/Dashboard/yearlySalesCRM';
import { totalContactsOnly } from 'api/Common/Dashboard/totalContactsonly';
import { CampaignCountDetails } from 'api/Common/Dashboard/campaignCounts';
import { overviewCards } from 'api/Common/Dashboard/overviewCards';
import { GetTemplateCount } from 'api/Common/Dashboard/getTemplateCount';
import { GetAllUserSA } from 'api/Common/Dashboard/getAllUserSA';
import { GetCRMEnterpriseCount } from 'api/Common/Dashboard/getCRMEnterpriseCount';
import { CampaignFile } from 'api/Enterprise/Campaign/campaignFile';
import { CampaignSummary } from 'api/Common/Reports/Campaign';
import { deactivateWhatsTemplates } from 'api/Enterprise/Template/WhatsApp/deactivateTemp';
import { ChannelOverview } from 'api/Common/Dashboard/channel-piecharts';
import { CDRDetails } from 'api/Common/Reports/CDRDetails';
import { ViewBills } from 'api/Enterprise/Campaign/viewBills';
import { DownloadCenter } from 'api/Common/Reports/DownloadCenter';
import { verifyListNameMutation } from 'api/Enterprise/ContactList/verifyListName';
import { uploadMediaGetId } from 'api/Enterprise/Template/WhatsApp/mediaID';
import { GetCdrDetailList } from 'api/Common/Reports/CDRDetailList';
import { successRate } from 'api/Common/Dashboard/successRate';


export const postApi = createApi({
  reducerPath: 'antiphishingServices',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PORT,
  }),

  endpoints: (builder) => ({
    // Dashboard
    ...yearlySalesDetails(builder),
    ...yearlySalesDetailsCRM(builder),
    ...totalContactsOnly(builder),
    ...CampaignCountDetails(builder),
    ...overviewCards(builder),
    ...GetTemplateCount(builder),
    ...GetAllUserSA(builder),
    ...GetCRMEnterpriseCount(builder),
    ...ChannelOverview(builder),
    ...CampaignSummary(builder),
    ...CDRDetails(builder),
    ...DownloadCenter(builder),
    ...successRate(builder),

    // Super-Admin
    ...userLoginMutation(builder),
    ...userOnboardingMutation(builder),
    ...userTypeMutation(builder),
    ...userDataMutation(builder),
    ...userCheckMutation(builder),
    ...userDropDownMutation(builder),
    ...verifyOtpMutation(builder),
    ...insertTransactionMutation(builder),
    ...transactionalListMutation(builder),
    ...userUpdateFieldMutation(builder),
    ...approveUserMutation(builder),
    ...getUserBalanceMutation(builder),
    ...SMSTestingMutation(builder),
    ...WhatsAppTestingMutation(builder),
    ...getRoutesList(builder),
    ...getMsgRatesList(builder),
    ...postRate(builder),

    // Enterprise
    ...contactUploadListMutation(builder),
    ...contactGetUploadListMutation(builder),
    ...deleteContactListMutation(builder),
    ...createWhatsAppMarketingMutation(builder),
    ...getWhatsappTemplatesMutation(builder),
    ...deactivateWhatsTemplates(builder),
    ...getSMSTemplatesMutation(builder),
    ...createSMSTemplateMutation(builder),
    ...blacklistSMSTemplatesMutation(builder),
    ...getCampaignListMutation(builder),
    ...verifyCampaignNameMutation(builder),
    ...deleteCampaignListMutation(builder),
    ...uploadMedia(builder),
    ...createCampaignMutation(builder),
    ...CampaignFile(builder),
    ...ViewBills(builder),
    ...verifyListNameMutation(builder),
    ...uploadMediaGetId(builder),
    ...GetCdrDetailList(builder),




  }),
});

export const {
  useUserLoginMutation,
  useUserOnboardingMutation,
  useUserTypeMutation,
  useUserDataMutation,
  useContactUploadListMutation,
  useContactGetUploadListMutation,
  useDeleteListMutation,
  useCheckUserDetailsMutation,
  useUserDropDownMutation,
  useVerifyOtpMutation,
  useInsertTransactionMutation,
  useTransactionalListMutation,
  useGetWhatsappTemplatesMutation,
  useCreateWhatsappMarketingMutation,
  useUserUpdateFieldMutation,
  useApproveUserMutation,
  useGetUserBalanceMutation,
  useGetSMSTemplatesMutation,
  useCreateSMSTemplateMutation,
  useSmsTestingMutation,
  useGetCampaignListMutation,
  useVerifyCampaignNameMutation,
  useDeleteCampListMutation,
  useWhatsAppTestingMutation,
  useUploadMediaMutation,
  useCreateCampaignMutation,
  useBlacklistSMSTemplatesMutation,
  useGetRoutesListMutation,
  useGetMsgRatesListMutation,
  usePostRateMutation,
  useYearlySalesDetailsMutation,
  useYearlySalesDetailsCRMMutation,
  useTotalContactsOnlyMutation,
  useCampaignCountDetailsMutation,
  useOverviewCardsMutation,
  useGetTemplateCountMutation,
  useGetAllUserSAMutation,
  useGetCRMEnterpriseCountMutation,
  useCampaignFileMutation,
  useDeactivateWhatsTemplatesMutation,
  useCampaignSummaryMutation,
  useChannelOverviewMutation,
  useCDRDetailsMutation,
  useViewBillsMutation,
  useDownloadCenterMutation,
  useVerifyListNameMutation,
  useUploadMediaGetIdMutation,
  useGetCdrDetailListMutation,
  useSuccessRateMutation
  
  
} = postApi;
