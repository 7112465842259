
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ArrowNarrowDownIcon, ArrowNarrowUpIcon, CalendarIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, CloudDownloadIcon, CogIcon, DotsHorizontalIcon, DotsVerticalIcon, DownloadIcon, ExternalLinkIcon, EyeIcon, InformationCircleIcon, PencilAltIcon, PencilIcon, PlusIcon, SearchIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { BsImageFill } from "react-icons/bs";

import { Routes } from "routes";
import { pageVisits, pageTraffic, pageRanking } from "data/tables";
import commands from "data/commands";
import Datetime from "react-datetime";
import { useContactGetUploadListMutation, useGetWhatsappTemplatesMutation, useUserDataMutation, useApproveUserMutation, useTransactionalListMutation, useDeleteListMutation, useGetSMSTemplatesMutation, useBlacklistSMSTemplatesMutation, useGetRoutesListMutation, useGetMsgRatesListMutation, useDeactivateWhatsTemplatesMutation } from "Services/services";
import moment from "moment-timezone";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TbEdit } from "react-icons/tb";
import Select from 'react-select';
import CopiedAlert from "pages/components/CopyAlert";
import { GrDocumentText } from "react-icons/gr";
import { FaCircleUser } from "react-icons/fa6";
import { AiTwotoneStop } from "react-icons/ai";
import { deactivateWhatsTemplates } from "api/Enterprise/Template/WhatsApp/deactivateTemp";
import { MdAppBlocking, MdOutlineContentCopy } from "react-icons/md";
import { PiArrowSquareUpRight, PiPhone } from "react-icons/pi";




const capitalizeFirstLetter = (string) => (
  string[0].toUpperCase() + string.slice(1).toLowerCase()
);

const getFirstLetterOfEachWord = (text) => (
  text.match(/\b\w/g).join('')
);

const ValueChange = ({ value, suffix }) => {
  const ValueIcon = value < 0 ? ChevronDownIcon : ChevronUpIcon;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={`d-flex align-items-center ${valueTxtColor}`}>
      <ValueIcon className="icon icon-xxs me-1" />
      <span className="fw-bold">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));




// on Contact List
export const ContactListTable = (props) => {
  const listTab = props.listType;

  const [tableData, setTableData] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState('');

  const [contactListApi] = useContactGetUploadListMutation();
  const [deleteListApi] = useDeleteListMutation();

  const [showAlert, setShowAlert] = useState(false);
  const [text, setText] = useState(false);

  const [debouncedValue, setDebouncedValue] = useState('');

  // Get the id from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userId = userObject.id;

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    const fetchTableData = async () => {
      contactListApi({ user_id: userId, page: currentPage, limit: pagelimit, search: debouncedValue, fromDate: startDate, toDate: endDate, list_type: listTab })
        .then((response) => {
          if (response?.data?.data?.response?.statusCode === 200) {
            setTableData(response?.data?.data?.response?.data);
            setTotalPages(response?.data?.data?.response?.totalPages);
            setTotalPosts(response?.data?.data?.response?.totalRecords);
          } else {
            setTableData({});
            alert('Failed to fetch user data');
          }
        })
        .catch((error) => {
          setTableData({});
          alert('Error fetching user data', error);
        });
    };
    fetchTableData();
  }, [debouncedValue, pagelimit, currentPage, startDate, endDate, listTab])

  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids;
    const textMessage = "Are you sure you want to delete this List?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "success",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

      deleteListApi({ id: ids })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setMessage('The List has been deleted.')
          } else {
            setError('Failed to Delete.');
          }
        })
        .catch((error) => {
          setError('Error deleting this list.' || error);
        });

      { error === '' ? await SwalWithBootstrapButtons.fire('Deleted', message, 'success') : await SwalWithBootstrapButtons.fire('Failed', error, 'error') }

      setTableData(newUsers);
    }
  }

  const downloadList = async (listname) => {
    console.log('listname', listname);
    try {
      const encodedListName = encodeURIComponent(listname);
      const response = await fetch(
        `${process.env.REACT_APP_API_PORT}/get_listed_contact?user_id=${userId}&list_name=${encodedListName}&export=true`
      );

      console.log('response status', response.status);

      // Check if the response is successful
      if (!response.ok) {
        const errorText = await response.text(); // Capture error message as text if provided
        setShowAlert(true);
        setText(errorText || "An error occurred while downloading the file.");
        return;
      }

      // Process the binary data
      const blob = await response.blob();

      // Sanitize the filename
      const sanitizedFileName = `${listname.replace(/[<>:"/\\|?*#]/g, '_')}.xlsx`;

      // Create the download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = sanitizedFileName; // Use the sanitized listname as the file name
      downloadLink.click();

      // Show success alert
      setShowAlert(true);
      setText(`File "${sanitizedFileName}" downloaded successfully`);
    } catch (error) {
      // Handle unexpected errors
      console.error('Unexpected error:', error);
      setShowAlert(true);
      setText("An unexpected error occurred. Please try again.");
    }
  };




  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TableRow = (props) => {
    const { index } = props;

    return (
      <tr className="border-bottom" key={index}>
        <td>
          <span className="fw-normal d-flex align-items-center">
            {index}
          </span>
        </td>
        <td><span className="fw-normal text-info">{props.list_name}</span></td>
        <td><span className="fw-normal">{props.remark}</span></td>
        <td><span className="fw-normal">{props.count}</span></td>
        <td><span className="fw-normal">{moment(props.updatedAt).format('YYYY/MM/DD')}</span></td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Download</Tooltip>}>
            <Card.Link onClick={() => downloadList(props.list_name)}>
              <CloudDownloadIcon className="icon icon-xs text-success" />
            </Card.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow">

      <CopiedAlert showAlert={showAlert} setShowAlert={setShowAlert} value={text} />

      <Card.Body>
        <div className="d-flex flex-wrap">
          <InputGroup className="me-2 me-lg-3 mb-3 fmxw-300">
            <InputGroup.Text>
              <SearchIcon className="icon icon-xs" />
            </InputGroup.Text>
            <Form.Control
              type="search"
              placeholder="List Name/Remarks"
              value={searchValue}
              onChange={changeSearchValue}
            />
          </InputGroup>

          <Form.Group id="DateRange" className="d-flex flex-nowrap mb-3">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Clear Dates</Tooltip>}>
                <InputGroup.Text onClick={() => { setStart(); setEnd() }}>
                  <CalendarIcon className="icon icon-xs" />
                </InputGroup.Text>
              </OverlayTrigger>
              <Datetime
                timeFormat={false}
                onChange={setStart}
                renderInput={(props, openCalendar) => (
                  <Form.Control
                    required
                    type="search"
                    placeholder="Start Date"
                    value={startDate}
                    onFocus={openCalendar}
                    style={{ width: "150px", borderRadius: "0" }}
                    onChange={(e) => { setStart(e.target.value) }}
                  />
                )} />
              <Datetime
                timeFormat={false}
                onChange={setEnd}
                isValidDate={currDate => currDate.isSameOrAfter(start)}
                initialViewDate={end}
                renderInput={(props, openCalendar) => (
                  <Form.Control
                    required
                    type="search"
                    placeholder="End Date"
                    value={endDate}
                    onFocus={openCalendar}
                    style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                    onChange={(e) => { setEnd(e.target.value) }} />
                )} />
            </InputGroup>
          </Form.Group>

          <ButtonGroup className="ms-auto mb-3">
            <Dropdown>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <span className="small ps-3 fw-bold text-dark">Show</span>
                {[10, 20, 30].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                     onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                  >
                    {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>

        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">S.No.</th>
              <th className="border-bottom">List Name</th>
              <th className="border-bottom">Remarks</th>
              <th className="border-bottom">Total Count</th>
              <th className="border-bottom">Upload Date</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {tableData.length > 0 ? tableData.map((u, index) => (
              <TableRow key={index + 1} index={index + 1} {...u} />
            )) :
              <tr>
                <td colSpan="7" className="text-center"><span className="text-danger">No Data Found</span></td>
              </tr>}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-0">
              <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                Previous
              </Pagination.Prev>
              {currentPage >= 3 && (
                <>
                  <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                  <Pagination.Ellipsis />
                </>
              )}
              {currentPage > 1 && (
                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                  {currentPage - 1}
                </Pagination.Item>
              )}
              <Pagination.Item active>{currentPage}</Pagination.Item>
              {currentPage < totalPages && (
                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                  {currentPage + 1}
                </Pagination.Item>
              )}
              {currentPage < totalPages - 1 && (
                <>
                  <Pagination.Ellipsis />
                  <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </Pagination.Item>
                </>
              )}
              <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-normal small mt-2 mt-md-0">
            Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
//on UserOnboardingList
export const UserOnboardingListTable = ({ handleEdit }) => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [crmValue, setCrmValue] = useState();
  const [statusValue, setStatusValue] = useState();

  const [totalPosts, setTotalPosts] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');

  const [userType, setUserType] = useState('');
  const [status, setStatus] = useState('');

  // Get the usertype from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const user = userObject.user_type;

  const disabledBulkMenu = users.filter(u => u.isSelected).length === 0;
  const selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);
  const totalUsers = users.length;
  const allSelected = selectedUsersIds.length === totalUsers;
  const selectUserTypeOptions = [
    { value: '2', label: 'Admin' },
    { value: '3', label: 'Customer Relationship Manager' },
    { value: '4', label: 'Enterprise' },
    { value: '5', label: 'Biiling Ops' },
    { value: '6', label: 'Reporting User' },
  ];
  const selectStatusTypeOptions = [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
  ];


  const [userOnboardingApi] = useUserDataMutation();
  const [approve] = useApproveUserMutation();

  const fetchTableData = async () => {
    userOnboardingApi({ pageNumber: currentPage, limit: pagelimit, user_type: crmValue, search: searchValue, status: statusValue })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.data.result.data);
          setAllTableData(response?.data?.data.result)
          setTotalPosts(response?.data?.data.result.totalPosts)
          setTotalPages(response?.data?.data.result.totalPages)
        } else {
          setTableData();
          setTotalPosts();
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        setTableData();
        console.error('Error fetching user data', error);
      });
  };
  const [debouncedValue, setDebouncedValue] = useState('');

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchTableData();
  }, [debouncedValue, pagelimit, currentPage, crmValue, userType, statusValue, userType]);

  useEffect(() => {
    const updatedUsers = Array.isArray(tableData) && tableData.length > 0
      ? tableData.map(u => ({ ...u, isSelected: false, show: true }))
      : [];

    setUsers(updatedUsers);
    // setTotalPage(tableData/pagelimit)
  }, [tableData]);


  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    // if (currentPage < totalPages) {
    setCurrentPage(currentPage + 1);
    // }
  };
  const userTypeMap = {
    "1": "Super Admin",
    "2": "Admin",
    "4": "Enterprise",
    "5": "Billing Ops",
    "3": "Customer Relationship Manager",
    "6": "Reporting User",
  };

  const crmMap = {
    "1": "CRM1",
    "2": "CRM2",
    "3": "CRM3",
    "4": "CRM4",
    "5": "CRM5",
  };

  const selectUserType = (selectedOption) => {
    if (selectedOption != null) {
      setCrmValue(selectedOption.value)
    }
    else return setCrmValue('')

  };
  const selectStatusType = (selectedOption) => {
    if (selectedOption != null) {
      setStatusValue(selectedOption.value)
    }
    else return setStatusValue('')
  };

  const handleReject = async (row) => {
    // Implement your remove logic here

    const textMessage = "Are you sure you want to reject this user?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm Rejection",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      try {
        const response = await approve({ user_id: row.id, approved: false });
        if (response?.data?.statusCode === 200) {
          const confirmMessage = "The user has been rejected successfully.";
          SwalWithBootstrapButtons.fire('Rejected', confirmMessage, 'error');
          // Refetch data here
          fetchTableData();
        } else {
          const confirmMessage = 'Failed to approve user';
          SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
        }
      } catch (error) {
        console.error('Error approving user', error);
        const confirmMessage = "An error occurred while approving the user.";
        SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
      }
    }

  };

  const handleApprove = async (row) => {
    const textMessage = "Are you sure you want to approve this user?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "warning",
      title: "Confirm Approval",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      try {
        const response = await approve({ user_id: row.id, approved: true });
        if (response?.data?.statusCode === 200) {
          const confirmMessage = "The user has been approved successfully.";
          SwalWithBootstrapButtons.fire('Approved', confirmMessage, 'success');
          // Refetch data here
          fetchTableData();
        } else {
          const confirmMessage = 'Failed to approve user';
          SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
        }
      } catch (error) {
        console.error('Error approving user', error);
        const confirmMessage = "An error occurred while approving the user.";
        SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
      }
    }
  };
  const deleteUsers = async (ids) => {
    // const usersToBeDeleted = ids;
    // const textMessage = "Are you sure you want to delete this List?";

    // const result = await SwalWithBootstrapButtons.fire({
    //   icon: "success",
    //   title: "Confirm deletion",
    //   text: textMessage,
    //   showCancelButton: true,
    //   confirmButtonText: "Yes",
    //   cancelButtonText: "Cancel"
    // });

    // if (result.isConfirmed) {
    //   const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

    //   deleteListApi({ id: ids })
    //     .then((response) => {
    //       if (response?.data?.statusCode === 200) {
    //         setMessage('The List has been deleted.')
    //       } else {
    //         setError('Failed to Delete.');
    //       }
    //     })
    //     .catch((error) => {
    //       setError('Error deleting this list.' || error);
    //     });

    //   { error === '' ? await SwalWithBootstrapButtons.fire('Deleted', message, 'success') : await SwalWithBootstrapButtons.fire('Failed', error, 'error') }

    //   setTableData(newUsers);
    // }
  }

  const TableRow = (props) => {
    const { index, isSelected } = props;
    const statusVariant = props.status === "approved" ? "success"
      : props.status === "pending" ? "warning" : "danger";

    return (
      <tr className="border-bottom" key={index}>
        <td>
          <span className="fw-normal d-flex align-items-center">
            {props.id}
          </span>
        </td>
        <td><span className="fw-normal text-info">{props.user_name}</span></td>
        <td><span className="fw-normal fw-bold">{userTypeMap[props.user_type_id] || "Unknown"}</span></td>
        <td><span className="fw-normal text-info">{props.name}</span></td>
        <td><span className="fw-normal">{props.email}</span></td>
        <td><span className="fw-normal">{props.phone}</span></td>
        <td><span className="fw-normal">{props.totalAmount || '-'}</span></td>
        <td><span className="fw-normal">{moment(props.createdAt).format('YYYY/MM/DD')}</span></td>
        <td><span className="fw-normal">{userTypeMap[props.created_by] || '-'}</span></td>
        <td>
          <span className="fw-normal">
            {props.created_by_user_name || "-"}
          </span>
        </td>
        <td><span className={`fw-normal text-${statusVariant}`}>{capitalizeFirstLetter(props.status)}</span></td>
        <td className="d-flex">
          {(user === 'super_admin' || user === 'admin') && props.status === 'pending' && <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => handleApprove(props)}>
                <CheckIcon className="dropdown-icon text-success me-2" />
                Approve
              </Dropdown.Item>
              {(userType === 'super_admin' || 'admin') &&
                <Dropdown.Item className="d-flex align-items-center" onClick={() => handleReject(props)}>
                  <XIcon className="dropdown-icon text-danger me-2" />
                  Reject
                </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>}
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Edit</Tooltip>}>
            <Card.Link className="ms-2 ms-auto" onClick={() => handleEdit(props)}>
              <TbEdit className="icon icon-wide text-info" />
            </Card.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Fragment>
      <div className="d-flex mb-3 align-items-center">
        {/* Search Input */}
        <InputGroup className="me-2 me-lg-3 fmxw-300">
          <InputGroup.Text>
            <SearchIcon className="icon icon-xs" />
          </InputGroup.Text>
          <Form.Control
            type="search"
            placeholder="Search Username / E-Mail"
            value={searchValue}
            onChange={changeSearchValue}
          />
        </InputGroup>

        <div className="me-2 me-lg-3 fmxw-300 dataTable-filter">
          <Row className="align-items-center">
            {/* <Col xs="auto" className="d-flex align-items-center pe-0">
                <Form.Label className="me-2 mb-0">Filter by User Type:</Form.Label>
              </Col> */}
            <Col xs="auto" className="ps-0 mb-0">
              <Select
                isClearable
                options={selectUserTypeOptions}
                placeholder="--Select User Type--"
                value={userType}
                onChange={(selectedOption) => {
                  setUserType(selectedOption);
                  selectUserType(selectedOption);
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: '200px', // Set minimum width to handle longer labels
                    maxWidth: '250px', // Set maximum width to prevent it from being too wide
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: 'auto', // Adjust width based on content
                    minWidth: '100%', // Ensure the dropdown is at least as wide as the select box
                  }),
                }}
              />
            </Col>
          </Row>
        </div>



        {/* Filter by Status */}
        <div className="me-2 me-lg-3 fmxw-300 dataTable-filter">
          <Row className="align-items-center">
            {/* <Col xs="auto" className="d-flex align-items-center pe-0">
                <Form.Label className="me-2 mb-0">Filter by Status:</Form.Label>
              </Col> */}
            <Col xs="auto" className="ps-0 mb-0">
              <Select
                isClearable
                options={selectStatusTypeOptions}
                placeholder="--Select Status--"
                value={status}
                onChange={(selectedOption) => { setStatus(selectedOption); selectStatusType(selectedOption); }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: '200px', // Set minimum width to handle longer labels
                    maxWidth: '250px', // Set maximum width to prevent it from being too wide
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: 'auto', // Adjust width based on content
                    minWidth: '100%', // Ensure the dropdown is at least as wide as the select box
                  }),
                }}
              />
            </Col>
          </Row>
        </div>

        <ButtonGroup className="ms-auto">
          <Dropdown>
            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
              <CogIcon className="icon icon-sm" />
              <span className="visually-hidden">Toggle Dropdown</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
              <span className="small ps-3 fw-bold text-dark">Show</span>
              {[10, 20, 30].map((limit) => (
                <Dropdown.Item
                  key={limit}
                  className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                   onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                >
                  {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
      </div>
      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Table hover responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom">ID</th>
                <th className="border-bottom">Username</th>
                <th className="border-bottom">User Type</th>
                <th className="border-bottom">Name</th>
                <th className="border-bottom">Email Id</th>
                <th className="border-bottom">Mobile No</th>
                <th className="border-bottom">Balance</th>
                <th className="border-bottom">Account Creation Date</th>
                <th className="border-bottom">Created By</th>
                <th className="border-bottom">CR Manager</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Action</th>
                {/* <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th> */}
              </tr>
            </thead>
            <tbody className="border-0">
              {users.length > 0 ? users.map((u, index) => (
                <TableRow key={index + 1} index={index + 1} {...u} />
              )) :
                <tr>
                  <td colSpan="11" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal small mt-2 mt-md-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Fragment>
  );
};
// Wallet
export const WalletTable = ({ handleEdit }) => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [crmValue, setCrmValue] = useState();

  const [searchValue, setSearchValue] = useState('');
  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPosts, setTotalPosts] = useState(1);

  const [userType, setUserType] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const disabledBulkMenu = users.filter(u => u.isSelected).length === 0;
  const selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);
  const totalUsers = users.length;
  const allSelected = selectedUsersIds.length === totalUsers;
  const selectUserTypeOptions = [
    { value: '', label: 'All' },
    { value: '1', label: 'Admin' },
    { value: '2', label: 'Enterprise' },
    { value: '3', label: 'Reseller' },
    { value: '4', label: 'Biiling Ops' },
    { value: '5', label: 'Customer Relationship Manager' },
    { value: '7', label: 'Reporting User' },

  ];
  const selectStatusTypeOptions = [
    { value: '', label: 'All' },
    { value: '1', label: 'Approved' },
    { value: '2', label: 'Pending' },

  ];

  const [status, setStatus] = useState(selectStatusTypeOptions[0])

  const [transactions] = useTransactionalListMutation();
  const [approve] = useApproveUserMutation();

  const [debouncedValue, setDebouncedValue] = useState('');

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    fetchTableData();
  }, [currentPage, pagelimit, debouncedValue, endDate]);

  const fetchTableData = async () => {

    transactions({ page: currentPage, limit: pagelimit, user_id: crmValue, search: searchValue, startDate: startDate, endDate: endDate }

    )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.data.transactions);
          setAllTableData(response?.data?.data);
          setTotalPages(response?.data?.data?.totalPages);
          setTotalPosts(response?.data?.data?.totalRecords);
        } else {
          setTableData([]);
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        setTableData([]);
        console.error('Error fetching user data', error);
      });
  };

  useEffect(() => {
    const updatedUsers = tableData.map(u => ({ ...u, isSelected: false, show: true }));

    setUsers(updatedUsers);
    // setTotalPage(tableData/pagelimit)
  }, [tableData]);


  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    // if (currentPage < totalPages) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const download = async () => {
    try {
      const url = `${process.env.REACT_APP_API_PORT}/getTransactionList?page=1&search=&download=true`; // Correctly construct the URL
      window.location.href = url; // This will navigate to the URL, triggering the download
    } catch (error) {
      console.error('There was an error initiating the download:', error);
    }
  };



  const selectUserType = (selectedOption) => {
    setCrmValue(selectedOption.value)
  };
  const TableRow = (props) => {

    const { index, isSelected } = props;

    const handleApprove = async (row) => {
      const textMessage = "Are you sure you want to approve this user?";

      const result = await SwalWithBootstrapButtons.fire({
        icon: "warning",
        title: "Confirm Approval",
        text: textMessage,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel"
      });

      if (result.isConfirmed) {
        try {
          const response = await approve({ user_id: row.id });
          if (response?.data?.statusCode === 200) {
            const confirmMessage = "The user has been approved successfully.";
            SwalWithBootstrapButtons.fire('Approved', confirmMessage, 'success');
            // Refetch data here
            fetchTableData();
          } else {
            const confirmMessage = 'Failed to approve user';
            SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
          }
        } catch (error) {
          console.error('Error approving user', error);
          const confirmMessage = "An error occurred while approving the user.";
          SwalWithBootstrapButtons.fire('Error', confirmMessage, 'error');
        }
      }
    };





    const handleReject = (row) => {
      // Implement your remove logic here
    };

    return (
      <tr className="border-bottom" key={index}>
        <td>
          <span className="fw-normal d-flex align-items-center">
            {props.id}
          </span>
        </td>
        <td><span className="fw-normal">{props?.user_name}</span></td>
        <td><span className="fw-normal">{moment(props.createdAt).format('YYYY/MM/DD')}</span></td>
        <td>
          <span className={`fw-normal ${props.credit ? 'text-success' : 'text-danger'}`}>
            {props.credit ? `+${props.amount}` : props.amount}
          </span>
        </td>
        <td><span className="fw-normal">{props.transaction_by ? props.transaction_by : "-"}</span></td>
        <td><span className="fw-normal">{props.remarks ? props.remarks : "-"}</span></td>
        <td>
          <span
            className={`fw-normal ${props.status === 'completed'
              ? 'text-success'
              : props.status === 'pending'
                ? 'text-warning'
                : props.status === 'rejected'
                  ? 'text-danger'
                  : 'text-muted'
              }`}
          >
            {props.status === 'completed'
              ? 'Completed'
              : props.status === 'pending'
                ? 'Pending'
                : props.status === 'rejected'
                  ? 'Rejected'
                  : 'Unknown'}
          </span>
        </td>
        {
          localStorage.getItem('userType') !== 'customer relationship manager' || props.status !== 'completed' &&
          <td className="text-center align-middle">
            <Dropdown>
              <Dropdown.Toggle variant="link" className="text-dark m-0 p-0">
                <DotsVerticalIcon className="icon icon-xs icon-dark" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-0">
                {/* <Dropdown.Item onClick={() => handleDownload(props)}>
                  <DownloadIcon className="icon icon-xs me-2 text-info" /> Download
                </Dropdown.Item> */}
                {props.status !== 'completed' && (
                  <Dropdown.Item onClick={() => handleApprove(props)}>
                    <CheckIcon className="icon icon-xs me-2 text-success" /> Approve
                  </Dropdown.Item>
                )}
                {/* {props.status === 'completed' && (
                  <Dropdown.Item onClick={() => handleReject(props)}>
                    <XIcon className="icon icon-xs me-2 text-danger" /> Reject
                  </Dropdown.Item>
                )} */}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        }
        {
          localStorage.getItem('userType') !== 'customer relationship manager' && props.status === 'completed' && <td>-</td>
        }
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center">
        {/* Search Input */}
        <InputGroup className="me-2 me-lg-3 mb-3 fmxw-300">
          <InputGroup.Text>
            <SearchIcon className="icon icon-xs" />
          </InputGroup.Text>
          <Form.Control
            type="search"
            placeholder="Search Username"
            value={searchValue}
            onChange={changeSearchValue}
          />
        </InputGroup>

        <Form.Group id="DateRange" className="d-flex flex-nowrap mb-3">
          <InputGroup>
            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Clear Dates</Tooltip>}>
              <InputGroup.Text onClick={() => { setStart(); setEnd() }}>
                <CalendarIcon className="icon icon-xs" />
              </InputGroup.Text>
            </OverlayTrigger>
            <Datetime
              timeFormat={false}
              onChange={setStart}
              renderInput={(props, openCalendar) => (
                <Form.Control
                  required
                  type="search"
                  placeholder="Start Date"
                  value={startDate}
                  onFocus={openCalendar}
                  style={{ width: "150px", borderRadius: "0" }}
                  onChange={(e) => { setStart(e.target.value) }}
                />
              )} />
            <Datetime
              timeFormat={false}
              onChange={setEnd}
              isValidDate={currDate => currDate.isSameOrAfter(start)}
              initialViewDate={end}
              renderInput={(props, openCalendar) => (
                <Form.Control
                  required
                  type="search"
                  placeholder="End Date"
                  value={endDate}
                  onFocus={openCalendar}
                  style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                  onChange={(e) => { setEnd(e.target.value) }} />
              )} />
          </InputGroup>
        </Form.Group>


        <Button variant="gray-300" className="ms-auto d-inline-flex  mb-3 me-2" onClick={() => download()}>
          <DownloadIcon className="icon icon-xs" />
        </Button>
        <Dropdown>
          <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1  mb-3">
            <CogIcon className="icon icon-sm" />
            <span className="visually-hidden">Toggle Dropdown</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
            <span className="small ps-3 fw-bold text-dark">
              Show
            </span>
            <Dropdown.Item
              className="d-flex align-items-center fw-bold"
              value="10"
              onClick={() => setPagelimit(10)}
            >
              10 {pagelimit == 10 && <CheckIcon className="icon icon-xxs ms-auto" />}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center fw-bold"
              value="20"
              onClick={() => setPagelimit("20")}
            >
              20 {pagelimit == 20 && <CheckIcon className="icon icon-xxs ms-auto" />}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center fw-bold rounded-bottom"
              value="30"
              onClick={() => setPagelimit("30")}
            >
              30 {pagelimit == 30 && <CheckIcon className="icon icon-xxs ms-auto" />}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Table hover responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">UserName</th>
                <th className="border-bottom">Transaction Date</th>
                <th className="border-bottom">Amount</th>
                <th className="border-bottom">Transaction By</th>
                <th className="border-bottom">Remarks</th>
                <th className="border-bottom">Status</th>
                {localStorage.getItem('userType') !== 'customer relationship manager' &&
                  <th className="border-bottom">Action</th>
                }
              </tr>
            </thead>
            <tbody className="border-0">
              {users.length > 0 ? users.map((u, index) => (
                <TableRow key={index + 1} index={index + 1} {...u} />
              )) :
                <tr>
                  <td colSpan="7" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal small mt-4 mt-lg-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};
// SMS Lists
export const SMSTemplate = () => {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [category, setCategory] = useState('');
  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [templateList] = useGetSMSTemplatesMutation();
  const [blacklist] = useBlacklistSMSTemplatesMutation();

  // Get the id from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userType = userObject.user_type;
  const userId = userObject.id;

  useEffect(() => {
    const fetchTableData = async () => {
      templateList({ user_id: (userType === 'super_admin' || userType === 'admin') ? "" : userId, page: currentPage, limit: pagelimit, search: searchValue, category: category, status: status })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setTableData(response?.data?.data?.result?.data);
            setTotalPages(response?.data?.data?.result?.totalPages);
            setTotalPosts(response?.data?.data?.result?.totalPosts);
          } else {
            setTableData([]);
          }
        })
        .catch((error) => {
          alert('Error fetching user data in SMS', error);
          setTableData([]);
        });
    };
    fetchTableData();
  }, [debouncedValue, currentPage, pagelimit, status, category])

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  }
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // const deleteUsers = async (ids) => {
  //   const usersToBeDeleted = ids;
  //   const textMessage = "Are you sure do you want to delete this template?";

  //   const result = await SwalWithBootstrapButtons.fire({
  //     icon: "error",
  //     title: "Confirm deletion",
  //     text: textMessage,
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Cancel"
  //   });

  //   if (result.isConfirmed) {
  //     const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));
  //     const confirmMessage = "This template has been deleted.";

  //     setTableData(newUsers);
  //     await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
  //   }
  // }

  const [confirmMessage, setConfirmMessage] = useState("");
  const blacklistUsers = async (ids) => {
    const textMessage = "Are you sure you want to De-Activate this template?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirmation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      blacklist(ids)
        .then((response) => {
          if (response?.data?.statusCode >= 200 && response?.data?.statusCode <= 204) {
            setConfirmMessage(response.data.message);
            SwalWithBootstrapButtons.fire('De-Activated', confirmMessage, 'success');
          } else {
            setConfirmMessage(response.message || 'Failed to deactivate user SMS template');
            SwalWithBootstrapButtons.fire('Deactivation Failed', confirmMessage, 'error');
          }
        })
        .catch((error) => {
          setConfirmMessage(error.message || 'Error deactivating user temp in SMS');
          SwalWithBootstrapButtons.fire('Deactivation Failed', confirmMessage, 'error');
        });
    };
    setStatus('');
  }

  const editList = (ids) => {
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TooltipContent = ({ header, body }) => {
    if (!header || !body) return <p className="m-0">No Content</p>;

    return (
      <div className="previeSmsTemp">
        <span>
          <FaCircleUser />
          <p className="m-0">{header}</p>
        </span>
        <p>{body}</p>
      </div>
    );
  };

  const TableRow = (props) => {
    const statusVariant = props.status === "Active" ? "success"
      : props.status === "Blacklist" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <div className="d-block">
            <span className="fw-bold text-info">{props.template_name}</span>
            <div className="small text-gray">Language: {props.language}</div>
          </div>
        </td>
        <td><span className="fw-normal">{props.category}</span></td>
        <td><span className={`fw-normal text-${statusVariant}`}>{props.status}</span></td>
        <td>
          <OverlayTrigger placement="right" overlay={<Tooltip className="m-0">
            <TooltipContent header={props.header} body={props.template_content} />
          </Tooltip>}>
            <Card.Link className="ms-2">
              <BsImageFill className="icon icon-wide" />
            </Card.Link>
          </OverlayTrigger>
        </td>
        <td><span className="fw-normal">{props.template_id}</span></td>
        <td><span className="fw-normal">{props.pe_id}</span></td>
        <td><span className="fw-normal">{props.registered_by_user_name}</span></td>
        <td><span className="fw-normal">{moment(props.updatedAt).format('YYYY/MM/DD')}</span></td>
        <td><span className="fw-normal">{moment(props.createdAt).format('YYYY/MM/DD')}</span></td>
        <td>
          {/* <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => deleteUsers(props.sno)}>
                <XCircleIcon className="dropdown-icon text-danger me-2" />
                Delete
              </Dropdown.Item>
              {(userType === 'super_admin' || userType === 'admin') &&
                <Dropdown.Item className="d-flex align-items-center" onClick={() => blacklistUsers(props.template_id)}>
                  <UserRemoveIcon className="dropdown-icon text-danger me-2" />
                  Blacklist
                </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown> */}
          {(userType === 'super_admin' || userType === 'admin') &&
            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Deactivate</Tooltip>}>
              <Card.Link className="ms-2" onClick={() => blacklistUsers(props.template_id)}>
                <MdAppBlocking className="icon icon-wide text-danger me-2" />
              </Card.Link>
            </OverlayTrigger>}
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Edit</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => editList(props.id)}>
              <TbEdit className="icon icon-wide text-info" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Fragment>
      <div className="table-settings">
        <Row>
          <div className="d-flex flex-wrap">
            <InputGroup className="me-2 me-lg-3 mb-3 fmxw-300 d-flex flex-nowrap">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Template Name/Header"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select onChange={handleCategory} value={category} className="me-2 me-lg-3 mb-3 fmxw-300">
              <option value="" defaultValue>All Category</option>
              <option value="PROMOTIONAL">Promotional</option>
              <option value="TRANSACTIONAL">Transactional</option>
              <option >OTP</option>
            </Form.Select>
            <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300 custom-dropdown">
              <option value="" defaultValue>All Status</option>
              <option>Active</option>
              <option>Blacklisted</option>
            </Form.Select>

            <Dropdown className="mb-3 ms-auto">
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <span className="small ps-3 fw-bold text-dark">Show</span>
                {[10, 20, 30].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                     onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                  >
                    {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>
      </div>

      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom">Template Name</th>
                <th className="border-bottom">Category</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Preview</th>
                <th className="border-bottom">Template ID</th>
                <th className="border-bottom">PE ID</th>
                <th className="border-bottom">Registered By</th>
                <th className="border-bottom">Update Date</th>
                <th className="border-bottom">Upload Date</th>
                <th className="border-bottom">Action</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {tableData.length > 0 ? tableData.map((u, index) => (
                <TableRow key={index} {...u} />
              )) :
                <tr>
                  <td colSpan="11" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal small mt-3 mt-md-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Fragment>

  );
};
// WhatsAPP lists
export const TemplateTable = () => {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [category, setCategory] = useState('');
  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('0');
  const [totalPosts, setTotalPosts] = useState('0');
  const [debouncedValue, setDebouncedValue] = useState('');
  // const [preview, setPreview] = useState(false);

  // Get the id from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userType = userObject.user_type;
  const userId = userObject.id;


  const [templateList] = useGetWhatsappTemplatesMutation();

  useEffect(() => {
    if (!userId || !currentPage || !pagelimit) return;

    const fetchTableData = async () => {
      try {
        const response = await templateList({
          // user_id: (userType === 'super_admin' || userType === 'admin') ? "" : userId,
          page: currentPage,
          limit: pagelimit,
          templateName: searchValue,
          category: category,
          status: status,
          from: '',
          to: ''
        });

        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.data?.result?.data || []);
          setTotalPages(response?.data?.data?.result?.totalPages || 0);
          setTotalPosts(response?.data?.data?.result?.totalPosts || 0);
        } else {
          setTableData([]);
        }
      } catch (error) {
        setTableData([]);
        alert(`Error fetching user data in WhatsApp: ${error.message}`);
      }
    };

    fetchTableData();
  }, [debouncedValue, currentPage, userType, userId, status, category, pagelimit]);



  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  }
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // const deleteUsers = async (ids) => {
  //   const usersToBeDeleted = ids;
  //   // const usersNr = usersToBeDeleted.length;
  //   const textMessage = "Are you sure do you want to delete this template?";

  //   const result = await SwalWithBootstrapButtons.fire({
  //     icon: "error",
  //     title: "Confirm deletion",
  //     text: textMessage,
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Cancel"
  //   });

  //   if (result.isConfirmed) {
  //     const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.ids));
  //     const confirmMessage = "This template has been deleted.";

  //     setTableData(newUsers);
  //     await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
  //   }
  // }

  const [confirmMessage, setConfirmMessage] = useState("");
  const [deactivate] = useDeactivateWhatsTemplatesMutation();

  const blacklistUsers = async (ids) => {
    const textMessage = "Are you sure you want to De-Activate this template?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirmation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      deactivate({ template: ids })
        .then((response) => {
          if (response?.data?.statusCode >= 200 && response?.data?.statusCode <= 204) {
            setConfirmMessage(response?.data?.message);
            SwalWithBootstrapButtons.fire('Template deactivated successfully!', confirmMessage, 'success');
          } else {
            setConfirmMessage(response?.data?.message || 'Failed to Deactivate user SMS template');
            SwalWithBootstrapButtons.fire('Deactivation Failed', confirmMessage, 'error');
          }
        })
        .catch((error) => {
          setConfirmMessage(error?.message || 'Error Deactivating user temp in SMS');
          SwalWithBootstrapButtons.fire('Deactivation Failed', confirmMessage, 'error');
        });
    };
    setDebouncedValue();
  }

  const editList = (ids) => {
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  // template preview on table
  const TooltipContent = ({ template_components }) => {
    if (!template_components) return <p className="m-0">No Content</p>;

    const { header, body, footer, buttons } = template_components;

    return (
      <div className="previewWhatsTemp">
        {(header && Object.keys(header).length > 0) && (
          <>
            {header?.type === "text" && <b>{header.text}</b>}
            {header?.type === "image" && <img src={header.example} alt="Header Example" />}
            {header?.type === "video" && (
              <video controls width="100%" className="w-image">
                <source src={header.example} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {header?.type === "doc" && <GrDocumentText />}
          </>
        )}
        {body?.add_security_recommendation ? <span className="wp-body"><b>{buttons?.text || '{{1}}'}</b> is your verification code. For Your Security, do not share this code.</span> : <p className="wp-body">{body?.text}</p>}

        {footer?.code_expiration_minutes ? <span className="wp-footer">{`This code expires in ${footer?.code_expiration_minutes} minutes.`}</span> : <span className="wp-footer">{footer?.text}</span>}

        {buttons && Object.keys(buttons).length > 0 &&
          <div className="wp-btns">
            {buttons.type === "combined_buttons" &&
              buttons.elements.map((row, index) => (
                <p key={index}>
                  {row.type === "dynamic" || row.type === "static" ? (
                    <>
                      <PiArrowSquareUpRight />
                      {row.label}
                    </>
                  ) : row.type === "phone" ? (
                    <>
                      <PiPhone />
                      {row.label}
                    </>
                  ) : (
                    row.text
                  )}
                </p>
              ))}
            {buttons.type === "quick_reply" &&
              buttons.elements.map((row, index) =>
              (
                <p key={index}>{row.text}</p>
              )
              )}
            {buttons.type === "call_to_action" &&
              buttons.elements.map((row, index) =>
                <p key={index}>
                  {row.type === "static" || row.type === "dynamic" ? (
                    <>
                      <PiArrowSquareUpRight />
                      {row.label}
                    </>
                  ) : (
                    <>
                      <PiPhone />
                      {row.label}
                    </>
                  )}
                </p>
              )}
            {buttons.type === "OTP" && (body?.add_security_recommendation) &&
              <p> <MdOutlineContentCopy /> Copy Code</p>
            }
          </div>
        }
      </div>
    );
  };
  const statusOptions = [
    { label: "All Status", value: "" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Pending", value: "PENDING" },
    { label: "Deactivate", value: "DEACTIVATE" },
  ];

  const TableRow = (props) => {
    const { index } = props;
    const statusVariant = props.status === "APPROVED" ? "success"
      : props.status === "PENDING" ? "warning"
        : props.status === "deactivate" ? "danger"
          : props.status === "REJECTED" ? "info"
            : "primary";

    return (
      <tr className="border-bottom" key={index}>
        {/* <td><span className="fw-normal d-flex align-items-center">{index}</span></td> */}
        <td>
          <div className="d-block">
            <span className="fw-bold text-info">{props.template_name}</span>
            <div className="small text-gray">Language: {props.language}</div>
          </div>
        </td>
        <td><span className="fw-normal">{props.template_category}</span></td>
        <td><span className={`fw-normal text-${statusVariant}`}>{capitalizeFirstLetter(props.status)}</span></td>
        {/* <td><span className="fw-normal">{props.template_id}</span></td> */}
        <td>
          <OverlayTrigger placement="right" overlay={<Tooltip className="m-0">
            <TooltipContent template_components={props.template_components} />
          </Tooltip>}>
            <Card.Link className="ms-2">
              <BsImageFill className="icon icon-wide" />
            </Card.Link>
          </OverlayTrigger>
        </td>
        {/* <td><span className="fw-normal">{props.template_status_reason}</span></td> */}
        <td><span className="fw-normal">{moment(props.updatedAt).format('YYYY/MM/DD')}</span></td>
        <td><span className="fw-normal">{moment(props.created_at).format('YYYY/MM/DD')}</span></td>
        <td>
          {/* <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => deleteUsers(props.sno)}>
                <XCircleIcon className="dropdown-icon text-danger me-2" />
                Delete
              </Dropdown.Item>
              {(userType === 'super_admin' || userType === 'admin') &&
                <Dropdown.Item className="d-flex align-items-center">
                  <UserRemoveIcon className="dropdown-icon text-danger me-2" />
                  Blacklist
                </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown> */}
          {(userType === 'super_admin' || userType === 'admin') &&
            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Deactivate</Tooltip>}>
              <Card.Link className="ms-2" onClick={() => blacklistUsers(props.template_id)}>
                <MdAppBlocking className="icon icon-wide text-danger me-2" />
              </Card.Link>
            </OverlayTrigger>}
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Edit</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => editList(props.id)}>
              <TbEdit className="icon icon-wide text-info" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Fragment>
      <div className="table-settings">
        <Row>
          <div className="d-flex flex-wrap">

            <InputGroup className="me-2 me-lg-3 mb-3 d-flex flex-nowrap fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search users"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>

            <Form.Select onChange={handleCategory} value={category} className="me-2 me-lg-3 mb-3 fmxw-300">
              <option value="" defaultValue>All Category</option>
              <option value="MARKETING">Marketing</option>
              <option value="UTILITY">Utility</option>
              <option value="AUTHENTICATION">Authentication</option>
            </Form.Select>

            <Form.Select
              onChange={handleStatus}
              value={status}
              className="me-2 me-lg-3 mb-3 fmxw-300 custom-dropdown"
            >
              {statusOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <Dropdown className="ms-auto mb-3">
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <span className="small ps-3 fw-bold text-dark">Show</span>
                {[10, 20, 30].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                     onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                  >
                    {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>
      </div>

      <Card border="0" className="table-wrapper table-responsive shadow">

        <Card.Body>

          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                {/* <th className="border-bottom">S.No.</th> */}
                <th className="border-bottom">Template Name</th>
                <th className="border-bottom">Category</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Preview</th>
                {/* <th className="border-bottom">Status Reason</th> */}
                <th className="border-bottom">Modification Date</th>
                <th className="border-bottom">Upload Date</th>
                <th className="border-bottom">Action</th>

              </tr>
            </thead>
            <tbody className="border-0">
              {tableData.length > 0 ? tableData.map((u, index) => (
                <TableRow key={index + 1} index={index + 1} {...u} />
              )) :
                <tr>
                  <td colSpan="9" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal small mt-4 mt-lg-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Fragment>

  );
};
// Route lists
export const RoutesTable = () => {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('0');
  const [totalPosts, setTotalPosts] = useState('0');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Get the id from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userType = userObject.user_type;
  // const userId = userObject.id;

  const [RoutesList] = useGetRoutesListMutation();

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await RoutesList({
          comm_type: status,
          page: currentPage,
          limit: pagelimit,
          // search: searchValue,
          // startDate: startDate,
          // endDate: endDate 
        });

        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.data?.rows);
          setTotalPages(response?.data?.data?.totalPages);
          setTotalPosts(response?.data?.data?.totalItems);
        } else {
          setTableData({});
          // alert('Failed to fetch user data');
        }
      }
      catch (error) {
        setTableData({});
        // alert('Error fetching user data', error);
      }
    };
    fetchTableData();
  }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);



  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  }

  const reject = async (ids) => {
    const usersToBeDeleted = ids;
    // const usersNr = usersToBeDeleted.length;
    const textMessage = "Are you sure do you want to Reject this Enterprise?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirmation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      // const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

      // deleteListApi({ id: ids })
      //     .then((response) => {
      //         if (response?.data?.statusCode === 200) {
      //             setMessage('The List has been deleted.')
      //         } else {
      //             setError('Failed to Delete.');
      //         }
      //     })
      //     .catch((error) => {
      //         setError('Error deleting this list.' || error);
      //     });

      const handleSwal = async () => {
        if (message !== '') {
          await SwalWithBootstrapButtons.fire('Rejected', message, 'success');
        } else {
          await SwalWithBootstrapButtons.fire('Failed', error, 'error');
        }
      };
      handleSwal();
    }
  }

  const approve = (ids) => {
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TableRow = (props) => {
    const variant = props.comm_type === "whatsapp" ? "success"
      : props.comm_type === "sms" ? "purple"
        : props.comm_type === "rcs" ? "warning" : "primary";
    return (
      <tr className="border-bottom">
        <td><span className={`fw-bold text-${variant} text-uppercase`}>{props.comm_type}</span></td>
        <td><span className="fw-normal text-info">{props.route_name}</span></td>
        <td><span className="fw-normal">{props.details}</span></td>
        <td><span className="fw-normal">{props.user_name}</span></td>
        <td><span className="fw-normal">{props.password}</span></td>
        <td><span className="fw-normal">{props.method}</span></td>
        <td><span className="fw-normal">{props.key_type}</span></td>
        <td><span className="fw-normal">{props.key_value}</span></td>
        <td><span className="fw-bold">{props.cost}</span></td>
      </tr>
    );
  };

  return (
    <Fragment>
      <div className="table-settings">
        <Row className="justify-content-between align-items-center">
          <div className="d-flex flex-wrap">
            {/* <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                            <InputGroup.Text>
                                <SearchIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                type="search"
                                placeholder="Campaign Name/Template ID/Header"
                                value={searchValue}
                                onChange={changeSearchValue}
                            />
                        </InputGroup> */}
            <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
              <option value="" defaultValue>All Channel</option>
              <option value="sms">SMS</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="rcs">RCS</option>
            </Form.Select>
            {/* <Form.Group id="DateRange">
                            <InputGroup className="d-flex flex-nowrap">
                                <InputGroup.Text>
                                    <CalendarIcon className="icon icon-xs" />
                                </InputGroup.Text>
                                <Datetime
                                    timeFormat={false}
                                    onChange={setStart}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="search"
                                            placeholder="Start Date"
                                            value={startDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderRadius: "0" }}
                                            onChange={(e) => setStart(e.target.value)} />
                                    )} />
                                <Datetime
                                    timeFormat={false}
                                    onChange={setEnd}
                                    isValidDate={currDate => currDate.isAfter(start)}
                                    initialViewDate={end}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="search"
                                            placeholder="End Date"
                                            value={endDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                            onChange={(e) => setEnd(e.target.value)} />
                                    )} />
                            </InputGroup>
                        </Form.Group> */}

            <Button variant="gray-300" className="ms-auto d-inline-flex mb-3 me-2" >
              <DownloadIcon className="icon icon-xs" />
            </Button>

            <Dropdown className="mb-3">
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <span className="small ps-3 fw-bold text-dark">Show</span>
                {[10, 20, 30].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                     onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                  >
                    {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>
      </div>

      <Card border="0" className="table-wrapper table-responsive shadow">

        <Card.Body>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom">Message Type</th>
                <th className="border-bottom">Route Name</th>
                <th className="border-bottom">Route Detail</th>
                <th className="border-bottom">Username</th>
                <th className="border-bottom">Password</th>
                <th className="border-bottom">API Method</th>
                <th className="border-bottom">Key Type</th>
                <th className="border-bottom">Key Value</th>
                <th className="border-bottom">Cost Rate</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {tableData.length > 0 ? tableData.map((u, index) => (
                <TableRow key={index + 1} index={index + 1} {...u} />
              )) :
                <tr>
                  <td colSpan="7" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>

            </Nav>
            <small className="fw-normal small mt-2 mt-md-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Fragment>

  );
};
// Message Rate lists
export const MessageRateTable = () => {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('0');
  const [totalPosts, setTotalPosts] = useState('0');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Get the id from localStorage
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userType = userObject.user_type;
  // const userId = userObject.id;

  const [RatesList] = useGetMsgRatesListMutation();

  useEffect(() => {
    const fetchTableData = async () => {
      RatesList({
        comm_type: status,
        page: currentPage,
        limit: pagelimit,
        // search: searchValue,
        // startDate: startDate,
        // endDate: endDate 
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            setTableData(response?.data?.rows);
            setTotalPages(response?.data?.data?.totalPages);
            setTotalPosts(response?.data?.data?.totalItems);
          } else {
            setTableData({});
            // alert('Failed to fetch user data');
          }
        })
        .catch((error) => {
          setTableData({});
          // alert('Error fetching user data', error);
        });
    };
    fetchTableData();
  }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);



  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  }

  const reject = async (ids) => {
    const usersToBeDeleted = ids;
    // const usersNr = usersToBeDeleted.length;
    const textMessage = "Are you sure do you want to Reject this Enterprise?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirmation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      // const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

      // deleteListApi({ id: ids })
      //     .then((response) => {
      //         if (response?.data?.statusCode === 200) {
      //             setMessage('The List has been deleted.')
      //         } else {
      //             setError('Failed to Delete.');
      //         }
      //     })
      //     .catch((error) => {
      //         setError('Error deleting this list.' || error);
      //     });

      const handleSwal = async () => {
        if (message !== '') {
          await SwalWithBootstrapButtons.fire('Rejected', message, 'success');
        } else {
          await SwalWithBootstrapButtons.fire('Failed', error, 'error');
        }
      };
      handleSwal();
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TableRow = (props) => {
    const statusVariant = props.status === "active" ? "success"
      : props.status === "pending" ? "purple"
        : props.status === "rejected" ? "danger" : "primary";
    const channelVariant = props.channel === "whatsapp" ? "success"
      : props.comm_type === "sms" ? "purple"
        : props.comm_type === "rcs" ? "warning" : "primary";
    return (
      <tr className="border-bottom">
        <td><span className="fw-bold">{props.assigned_to}</span></td>
        <td><span className="fw-bold text-info">{props.route_name}</span></td>
        <td><span className={`fw-normal text-${channelVariant}`}>{props.channel}</span></td>
        <td><span className="fw-normal">{moment(props.date).format('DD/MM/YYYY')}</span></td>
        <td><span className="fw-normal">{props.created_by_user}</span></td>
        <td><span className="fw-normal">{props.sell_price}</span></td>
        <td><span className={`fw-normal text-${statusVariant} text-capitalize`}>{props.status}</span></td>
        {/* {userType === 'super_admin' && <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Approve</Tooltip>}>
            <Card.Link onClick={() => approve(props.id)}>
              <CheckIcon className="icon icon-xs text-success" />
            </Card.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Reject</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => reject(props.id)}>
              <XIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>} */}
      </tr>
    );
  };
  return (
    <Fragment>
      <div className="table-settings">
        <Row className="justify-content-between align-items-center">
          <div className="d-flex flex-wrap">
            <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Enterprise Name"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
              <option value="" defaultValue>All Channel</option>
              <option>SMS</option>
              <option>WhatsApp</option>
              <option>RCS</option>
            </Form.Select>
            <Form.Group id="DateRange">
              <InputGroup className="d-flex flex-nowrap mb-3">
                <InputGroup.Text>
                  <CalendarIcon className="icon icon-xs" />
                </InputGroup.Text>
                <Datetime
                  timeFormat={false}
                  onChange={setStart}
                  renderInput={(props, openCalendar) => (
                    <Form.Control
                      required
                      type="search"
                      placeholder="Start Date"
                      value={startDate}
                      onFocus={openCalendar}
                      style={{ width: "150px", borderRadius: "0" }}
                      onChange={(e) => setStart(e.target.value)} />
                  )} />
                <Datetime
                  timeFormat={false}
                  onChange={setEnd}
                  isValidDate={currDate => currDate.isAfter(start)}
                  initialViewDate={end}
                  renderInput={(props, openCalendar) => (
                    <Form.Control
                      required
                      type="search"
                      placeholder="End Date"
                      value={endDate}
                      onFocus={openCalendar}
                      style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                      onChange={(e) => setEnd(e.target.value)} />
                  )} />
              </InputGroup>
            </Form.Group>

            <Button variant="gray-300" className="ms-auto d-inline-flex mb-3 me-2" >
              <DownloadIcon className="icon icon-xs" />
            </Button>

            <Dropdown className="mb-3">
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <span className="small ps-3 fw-bold text-dark">Show</span>
                {[10, 20, 30].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                     onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                  >
                    {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>
      </div>

      <Card border="0" className="table-wrapper table-responsive shadow">

        <Card.Body>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom">Enterprise Name</th>
                <th className="border-bottom">Route Name</th>
                <th className="border-bottom">Message Type</th>
                <th className="border-bottom">Date</th>
                <th className="border-bottom">Submitted By</th>
                <th className="border-bottom">Rate</th>
                <th className="border-bottom">Status</th>
                {/* {userType === 'super_admin' && <th className="border-bottom">Action</th>} */}
              </tr>
            </thead>
            <tbody className="border-0">
              {tableData.length > 0 ? tableData.map((u, index) => (
                <TableRow key={index + 1} index={index + 1} {...u} />
              )) :
                <tr>
                  <td colSpan="7" className="text-center text-danger">No Data Found</td>
                </tr>}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {currentPage >= 3 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                  </>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  </>
                )}
                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>

            </Nav>
            <small className="fw-normal small mt-2 mt-md-0">
              Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Fragment>

  );
};



export const TransactionsTable = (props) => {
  const { transactions } = props;
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } = props;
    const statusVariant = status === "paid" ? "success"
      : status === "due" ? "warning"
        : status === "cancelled" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-bold">
            {invoiceNumber}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {subscription}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {issueDate}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {dueDate}
          </span>
        </td>
        <td>
          <span className="fw-bold">
            ${parseFloat(price).toFixed(2)}
          </span>
        </td>
        <td>
          <span className={`fw-bold text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td className="text-center">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs icon-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-0">
              <Dropdown.Item as={Link} to={Routes.Invoice.path}>
                <EyeIcon className="icon icon-xs me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Routes.Invoice.path}>
                <PencilAltIcon className="icon icon-xs me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item>
                <TrashIcon className="icon icon-xs text-danger me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow">
      <Card.Body>
        <Table hover>
          <thead>
            <tr>
              <th className="border-gray-200">#</th>
              <th className="border-gray-200">Bill For</th>
              <th className="border-gray-200">Issue Date</th>
              <th className="border-gray-200">Due Date</th>
              <th className="border-gray-200">Total</th>
              <th className="border-gray-200">Status</th>
              <th className="border-gray-200">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {transactions.map(t => <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-normal mt-4 mt-lg-0">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map(u => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More <ExternalLinkIcon className="icon icon-xs ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>Name</th>
              <th className="border-0" style={{ width: '5%' }}>Usage</th>
              <th className="border-0" style={{ width: '50%' }}>Description</th>
              <th className="border-0" style={{ width: '40%' }}>Extra</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {commands.map(c => <TableRow key={`command-${c.id}`} {...c} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
export const PageVisitsTable = () => {
  const history = useHistory();

  const goToTrafficSources = () => {
    history.push(Routes.DashboardTraffic.path);
  }

  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const BounceIcon = bounceRate < 0 ? ArrowNarrowDownIcon : ArrowNarrowUpIcon;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr className="border-bottom">
        <th className="text-gray-900" scope="row">
          {pageName}
        </th>
        <td className="fw-bolder text-gray-500">
          {views}
        </td>
        <td className="fw-bolder text-gray-500">
          ${returnValue}
        </td>
        <td className="fw-bolder text-gray-500">
          <div className="d-flex align-items-center">
            <BounceIcon className={`icon icon-xs ${bounceTxtColor} me-2`} />
            {Math.abs(bounceRate)}%
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="shadow">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h2 className="fs-5 fw-bold mb-0">
              Page visits
            </h2>
          </Col>
          <Col className="text-end">
            <Button variant="primary" size="sm" onClick={goToTrafficSources}>
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th className="border-bottom" scope="col">Page name</th>
            <th className="border-bottom" scope="col">Page Views</th>
            <th className="border-bottom" scope="col">Page Value</th>
            <th className="border-bottom" scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody className="border-0">
          {pageVisits.map(pv => <TableRow key={`page-visit-${pv.id}`} {...pv} />)}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { index, source, sourceIcon, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr className="border-bottom">
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {index}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <div className="d-flex align-items-center">
            {sourceIcon}
            {source}
          </div>
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? `#${rank}` : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg bg-dark mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="shadow mb-4">
      <Card.Body>
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0 rounded-start">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0 rounded-end">Change</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {pageTraffic.map((pt, ind) => <TableRow key={`page-traffic-${pt.id}`} index={ind + 1} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div><span className="h6">{country}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {overallRank ? overallRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">
          {travelRank ? travelRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">
          {widgetsRank ? widgetsRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="shadow">
      <Card.Body>
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0 rounded-start">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0 rounded-end">Widgets Change</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {pageRanking.map(r => <TableRow key={`ranking-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const UsersTable = (props) => {
  const { users = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = users.filter(u => u.isSelected).length === 0;


  const selectUser = (id) => {
    props.selectUser && props.selectUser(id);
  };

  const selectAllUsers = () => {
    props.selectAllUsers && props.selectAllUsers();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete_user") deleteUsers();
  }

  const deleteUsers = (ids) => {
    props.deleteUsers && props.deleteUsers(ids)
  }

  const TableRow = (props) => {
    const { id, verified, status, image, name, email, dateCreated, isSelected } = props;
    const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
    const statusVariant = status === "active" ? "success"
      : status === "inactive" ? "warning"
        : status === "pending" ? "purple"
          : status === "suspended" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`user-${id}`} checked={isSelected} onChange={() => selectUser(id)} />
            <FormCheck.Label htmlFor={`user-${id}`} />
          </FormCheck>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center">
            {image
              ? (
                <Image
                  src={image}
                  className="avatar rounded-circle me-3"
                />
              ) : (
                <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
                  <span>{getFirstLetterOfEachWord(name)}</span>
                </div>
              )}
            <div className="d-block">
              <span className="fw-bold">{name}</span>
              <div className="small text-gray">{email}</div>
            </div>
          </Card.Link>
        </td>
        <td><span className="fw-normal">{dateCreated}</span></td>
        <td>
          <span className="fw-normal d-flex align-items-center">
            <VerifiedIcon className={`icon icon-xxs text-${statusVariant} me-1`} />
            Email
          </span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center">
                <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
                Reset Pass
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <EyeIcon className="dropdown-icon text-gray-400 me-2" />
                View Details
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <UserRemoveIcon className="dropdown-icon text-danger me-2" />
                Suspend
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteUsers([id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow">
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="send_email">Send Email</option>
            <option value="change_group">Change Group</option>
            <option value="delete_user">Delete User</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Date Created</th>
              <th className="border-bottom">Verified</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Actions</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {users.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-normal small mt-4 mt-lg-0">
            Showing <b>{users.length}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};